import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { atom, useAtomValue } from 'jotai';

import { getPlaylistTracks } from '@/services/tracks';

import { useMatchMutate } from '@/hooks/useMatchMutate';

import { createEventEmitter } from '@/utils/createEventEmitter';

import type { ITrack, ITracks } from '@/types/trackListView';

import { setTrack, updateTrack as updatePlayerTrack } from '@/store/player/player.actions';
import { selectActiveTrack, selectTrackList } from '@/store/player/player.selectors';

import { isTrackList } from './utils';

const replaceTrackDataMutator = (track: ITrack) => (prevData?: ITracks) => {
  // mutate list data only
  if (isTrackList(prevData)) {
    const tracks = prevData.data || [];
    const updatedTracks = tracks.map((cachedTrack) =>
      cachedTrack.id === track.id ? track : cachedTrack,
    );

    return { pagination: prevData.pagination, data: updatedTracks };
  }

  return prevData;
};

export const updateTrackEventAtom = atom(createEventEmitter<{ track: ITrack }>());

export const useUpdateTrack = () => {
  const dispatch = useDispatch();
  const activeTrack = useSelector(selectActiveTrack);
  const trackList = useSelector(selectTrackList);
  const updateTrackEvent = useAtomValue(updateTrackEventAtom);

  const matchMutate = useMatchMutate();

  const updateTrack = useCallback(
    (track: ITrack) => {
      dispatch(updatePlayerTrack({ track })); // update player and playlist tracks
      matchMutate(/tracks/, replaceTrackDataMutator(track), false); // update cached tracks data (global and user)
      updateTrackEvent.raise({ track });
    },
    [dispatch, matchMutate, updateTrackEvent],
  );

  const updateAllTracks = useCallback(async () => {
    const updatedPlaylist = await getPlaylistTracks(trackList);
    const track = updatedPlaylist.find(({ id }) => id === activeTrack.id) || activeTrack;
    dispatch(setTrack({ track, trackList: updatedPlaylist })); // update player and playlist tracks
    matchMutate(/tracks/); // invalidate cached tracks data (global and user)
  }, [activeTrack, dispatch, matchMutate, trackList]);

  return { updateTrack, updateAllTracks };
};

export const useOnTrackUpdated = () => {
  const updateTrackEvent = useAtomValue(updateTrackEventAtom);

  return updateTrackEvent.subscribe;
};
