import { createContext, useState, useEffect, useContext, type JSX } from 'react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material';

import type { TToggleTheme } from '@/contexts/types';

import { noop } from '@/utils/functionalUtils';

import { getThemeByName } from '@/themesConfig';
import { ActionEvents, trackEvent } from '@/tracking';

const initialTheme = localStorage?.getItem('theme') || 'dark';
const ThemeContext = createContext({
  isDarkMode: initialTheme === 'dark',
  toggleTheme: noop as TToggleTheme,
});

export const ThemeProvider = ({ children }: { children: JSX.Element }) => {
  const [themeType, setThemeType] = useState(initialTheme);
  const isDarkMode = themeType === 'dark';
  const theme = getThemeByName(themeType);

  const toggleTheme: TToggleTheme = (event) => {
    event.stopPropagation();
    const nextThemeType = isDarkMode ? 'light' : 'dark';
    trackEvent(ActionEvents.changeUITheme, {
      originalState: themeType,
      newState: nextThemeType,
    });
    setThemeType(nextThemeType);
  };

  useEffect(() => {
    localStorage.setItem('theme', themeType);
  }, [themeType]);

  const value = { isDarkMode, toggleTheme };

  return (
    <ThemeContext.Provider value={value}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
