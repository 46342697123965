import { combineReducers } from 'redux';

import { reducer as search } from '@/store/search/search.reducers';

import { reducer as notifications } from './notistack/notistack.reducer';
import { reducer as payment } from './payment/payment.reducers';
import { reducer as player } from './player/player.reducers';
import { reducer as profile } from './profile/profile.reducers';
import { reducer as subscription } from './subscription/subscription.reducers';

export default combineReducers({
  notifications,
  profile,
  payment,
  subscription,
  search,
  player,
});
