import axios from 'axios';

import { identity } from '@/utils/functionalUtils';

import { correlationIdInterceptor, handleErrorInterceptor } from '../common/interceptors';
import { publicNotificationTemplateUrl, publicStudioURL, editorUrl, CDN_URL } from '../urls';

const apiConfig = {
  publicStudioService: {
    baseURL: publicStudioURL,
    headers: { 'Content-Type': 'application/json' },
    request: [correlationIdInterceptor],
    response: [handleErrorInterceptor],
  },
  audioService: {
    baseURL: CDN_URL || '',
    headers: { 'Content-Type': 'audio/mp3' },
    request: [],
    response: [handleErrorInterceptor],
  },
  notificationTemplateService: {
    baseURL: publicNotificationTemplateUrl,
    headers: { 'Content-Type': 'application/json' },
    request: [correlationIdInterceptor],
    response: [handleErrorInterceptor],
  },
  editorService: {
    baseURL: editorUrl,
    headers: { 'Content-Type': 'application/json' },
    request: [correlationIdInterceptor],
    response: [handleErrorInterceptor],
  },
};

const createPublicService = (service: keyof typeof apiConfig) => {
  const { baseURL, headers, request, response } = apiConfig[service];
  const instance = axios.create({ baseURL, headers });

  instance.interceptors.request.use(...request);
  response.forEach((interceptor) => instance.interceptors.response.use(identity, interceptor));

  return instance;
};

export const publicStudioService = createPublicService('publicStudioService');
export const audioService = createPublicService('audioService');
export const publicNotificationTemplateService = createPublicService('notificationTemplateService');
export const editorService = createPublicService('editorService');
