import { useDispatch } from 'react-redux';

import { useMatchMutate } from '@/hooks/useMatchMutate';

import type { ITracks } from '@/types/trackListView';

import { removeTrack } from '@/store/player/player.actions';

import { isTrackList } from './utils';

const matchers = [
  /\/tracks\?card=summary/, // my tracks
  /\/tracks\/favorites/, // favorite tracks
  /\/tracks\/recents/, // recent tracks
];

const matcher = new RegExp(matchers.map((m) => m.source).join('|'));

export const deleteTrackMutator = (id: string) => (prevData?: ITracks) => {
  // mutate list data only
  if (isTrackList(prevData)) {
    const tracks = prevData.data || [];
    const updatedTracks = tracks.filter((track) => track.id !== id);

    return { pagination: prevData.pagination, data: updatedTracks };
  }

  return prevData;
};

export const useDeleteTrackMutator = () => {
  const dispatch = useDispatch();
  const matchMutate = useMatchMutate();

  const deleteTrack = (trackId: string) => {
    // remove track from playlist
    dispatch(removeTrack({ id: trackId }));

    // remove from Creations and Favorites
    matchMutate(matcher, deleteTrackMutator(trackId), false);

    // remove stored recent track if it's the same as deleted
    const storedUserId = localStorage.getItem('userId');
    const storedRecentTrack = storedUserId && localStorage.getItem(`recentTrack_${storedUserId}`);

    if (storedUserId && storedRecentTrack && JSON.parse(storedRecentTrack).id === trackId) {
      localStorage.removeItem(`recentTrack_${storedUserId}`);
    }
  };

  return deleteTrack;
};
