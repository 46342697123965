import type { TBrowserFeature } from './types';

const lowerThan = (version: string) => `<${version}`;
const thisAndHigher = (version: string) => `>=${version}`;
const between = (minVersion: string, maxVersion: string) =>
  `${thisAndHigher(minVersion)}, ${lowerThan(maxVersion)}`;

export const deriveBrowserVersion = (features: TBrowserFeature[]) => {
  try {
    for (let index = features.length - 1; index >= 0; index -= 1) {
      const feature = features[index];

      if (feature.test()) {
        const nextFeature = features[index + 1];

        if (nextFeature) {
          return {
            mobile: between(feature.minMobileVersion, nextFeature.minMobileVersion),
            desktop: between(feature.minDesktopVersion, nextFeature.minDesktopVersion),
          };
        }

        return {
          mobile: thisAndHigher(feature.minMobileVersion),
          desktop: thisAndHigher(feature.minDesktopVersion),
        };
      }
    }

    return {
      mobile: lowerThan(features[0].minMobileVersion),
      desktop: lowerThan(features[0].minDesktopVersion),
    };
  } catch (e) {
    console.warn('Failed to derive Safari browser version', e);
  }

  return null;
};
