/**
 * @returns {PromiseConstructor.allSettled}
 */
export const createAllSettledPolyfill = () => {
  return (values) => {
    const promises = [];

    for (const value of values) {
      let resultPromise = null;

      if (value instanceof Promise) {
        resultPromise = value
          .then((result) => {
            return { status: 'fulfilled', value: result };
          })
          .catch((error) => {
            return { status: 'rejected', reason: error };
          });
      } else {
        resultPromise = Promise.resolve({ status: 'fulfilled', value });
      }

      promises.push(resultPromise);
    }

    return Promise.all(promises);
  };
};
