import { useEffect, useState } from 'react';

import { hasFeature } from '@/utils/featureFlag';
import { noop } from '@/utils/functionalUtils';

import { POLLING_INTERVAL_MINUTES } from '@/components/versionUpdater/config';

export const useConfigurablePollingInterval = () => {
  const [duration, setDuration] = useState(POLLING_INTERVAL_MINUTES);

  useEffect(() => {
    if (!hasFeature('configurableNewVersionSentinel')) {
      return noop;
    }

    // eslint-disable-next-line no-underscore-dangle
    window.__setNewVersionIntervalMinutes = (value) => {
      setDuration(value);
    };

    return () => {
      // eslint-disable-next-line no-underscore-dangle
      delete window.__setNewVersionIntervalMinutes;
    };
  }, []);

  return duration;
};

declare global {
  interface Window {
    __setNewVersionIntervalMinutes?(time: number): void;
  }
}
