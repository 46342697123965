import { all } from 'redux-saga/effects';

import { watchSearchFetchData } from '@/store/search/search.sagas';

import { watchPaymentSaga } from './payment/payment.sagas';
import { watchProfileFetchData } from './profile/profile.sagas';

export default function* rootSaga() {
  yield all([watchProfileFetchData(), watchPaymentSaga(), watchSearchFetchData()]);
}
