import { LogglyTracker } from 'loggly-jslogger';

import type { ILogTransport } from '../types';

type TOptions = {
  key: string;
  service: string;
  environment: string;
};

export const createLogglyTransport = ({ key, service, environment }: TOptions): ILogTransport => {
  const logger = new LogglyTracker();

  logger.push({
    logglyKey: key,
    tag: `flow-soundful,originator-${service},env-${environment}-SOUNDFUL`,
    sendConsoleErrors: false,
  });

  return {
    push({ timestampMs, ...event }) {
      logger.push(event);
    },
  };
};
