import { type Appcues, Loader } from 'appcues-loader';
import type { AxiosResponse } from 'axios';
import { getType } from 'deox';
import i18n from 'i18next';
import { call, put, select, takeEvery, takeLatest, all } from 'redux-saga/effects';

import { refreshAccessToken, signInUser } from '@/services/auth';
import {
  changePasswordService,
  changeUserPhotoService,
  finishPasswordReset,
  finishReferralSignUp,
  getRakutenUserInfo,
  getReferralEmails,
  getUserInfo,
  initPasswordReset,
  initReferralSignUp,
  postRakutenUserInfo,
  sendEmailVerificationLink,
  sendReferralEmail,
  signUpUser,
  updateConsent,
  updateUserInfo,
  validateEmailVerificationLink,
  validatePasswordResetLink,
} from '@/services/profile';
import { ProfileSection } from '@/services/types';

import { sync } from '@/hooks/synchronizer';

import { LOAD_MARKETING_SCRIPTS, LOAD_SMARTLOOK_SCRIPTS } from '@/config/marketing';
import type { TCaptchaConfig } from '@/utils/captchaUtils/types';
import { getCookie, setCookie } from '@/utils/cookieUtils';
import { getFriendlyDate } from '@/utils/dateUtils';
import { handleError, isResponseError } from '@/utils/errorUtils';
import type { TErrorResponse } from '@/utils/errorUtils/types';
import { hasFeature } from '@/utils/featureFlag';
import { noop } from '@/utils/functionalUtils';
import { remoteLogger } from '@/utils/logging';
import { toastError, toastSuccess } from '@/utils/notificationUtils';
import { convertObjectValues } from '@/utils/objectUtils';
import { getRecord } from '@/utils/storageUtils';
import { setTokens } from '@/utils/tokenUtils';

import type { Nullable } from '@/types/common';
import type {
  TReferral,
  TUserDetails,
  TUserResponse,
  TUserSubscriptionInfo,
} from '@/types/profile';

import { trackRakutenConversion } from '@/scripts/rakuten';
import { identifySmartlook } from '@/scripts/smartlook';
import { selectUserData } from '@/store/profile/profile.selectors';
import { setSubscriptionInfo } from '@/store/subscription/subscription.actions';
import { ActionEvents, identifyTrackerUser, trackerPeopleSet, trackEvent } from '@/tracking';
import { GTMEvents } from '@/tracking/config';
import { trackGTMError, trackGTMEvent, trackHSForm } from '@/tracking/trackEvents';

import {
  changePassword,
  changeUserPhoto,
  finishReferralSignUp as finishReferralSignUpAction,
  getReferralEmails as getReferralEmailsAction,
  getUserData,
  initPasswordResetAction,
  initReferralSignUp as initReferralSignUpAction,
  passwordResetFinishAction,
  refreshTokenAction,
  sendConfirmVerifyEmail,
  sendReferralEmail as sendReferralEmailAction,
  sendVerifyEmail,
  signin,
  signup,
  updateUserInfo as updateUserInfoAction,
  validatePasswordResetLink as validatePasswordResetLinkAction,
} from './profile.actions';
import {
  type TChangePasswordAction,
  type TChangeUserPhotoAction,
  type TConfirmEmailAction,
  type TEmailVerificationAction,
  type TFinishReferralSignUp,
  type TInitPasswordReset,
  type TInitReferralSignUp,
  type TPasswordResetFinish,
  type TSendReferralEmailAction,
  type TSignInAction,
  type TSignUpAction,
  type TUpdateUserInfoAction,
  type TValidatePasswordResetLink,
} from './types';

const AUTO_SIGN_IN_CAPTCHA: Nullable<TCaptchaConfig> = !hasFeature('reCaptchaDisabled')
  ? {
      action: 'signin',
      version: 'v3',
    }
  : null;

const performSignIn = async (payload: TSignInAction['payload']) => {
  const method = 'tokens' in payload ? `${payload.provider} login` : 'email';
  const tokens =
    'tokens' in payload ? payload.tokens : await signInUser(payload).then(({ data }) => data);

  setTokens(tokens);
  setCookie({ name: 'logged', value: 'true' });

  remoteLogger.identifyUser(String(tokens.id));
  identifyTrackerUser(String(tokens.id));

  return { method, tokens };
};

function* cleanState() {
  yield put(signin.clean());
  yield put(getUserData.clean());
  yield put(getReferralEmailsAction.clean());
}

let isTrackingInitialized = false;

function* userDataDetailsSaga() {
  try {
    const {
      data: { details },
    } = yield call(() => getUserInfo([ProfileSection.details]));
    const userDetails = convertObjectValues(details, null, '') as unknown as TUserDetails;

    const { userId, fullName, email } = userDetails;

    identifyTrackerUser(String(userId));

    if (LOAD_MARKETING_SCRIPTS && !isTrackingInitialized) {
      const appcuesLoader = new Loader(window.env.APPCUES_ID);
      appcuesLoader
        .load()
        .then((appcues: Appcues) => {
          appcues.identify(String(userId), {
            name: String(fullName),
            email: String(email),
          });
        })
        .catch((error) => {
          handleError(error, { toast: false, remoteLog: true }, { category: 'APPCUES' });
        });
    }

    if (LOAD_SMARTLOOK_SCRIPTS && !isTrackingInitialized) {
      identifySmartlook(String(userId), { email: String(email) });
    }

    if (!isTrackingInitialized) {
      remoteLogger.identifyUser(String(userId));
      isTrackingInitialized = true;
    }

    yield put(getUserData.success(userDetails));

    return userDetails;
  } catch (error) {
    yield put(getUserData.failure(error));

    return null;
  }
}

function* userDataSubscriptionSaga() {
  yield put(setSubscriptionInfo.request());

  try {
    const response: AxiosResponse<TUserResponse> = yield call(getUserInfo, [
      ProfileSection.subscriptionInfo,
    ]);

    const { subscriptionInfo } = response.data;

    yield put(setSubscriptionInfo.success(subscriptionInfo));

    return subscriptionInfo;
  } catch (reason) {
    yield put(setSubscriptionInfo.failure(reason));

    throw reason;
  }
}

function* userDataSaga() {
  const [userDetails, subscriptionInfo]: [TUserDetails | null, TUserSubscriptionInfo] = yield all([
    call(userDataDetailsSaga),
    call(userDataSubscriptionSaga),
  ]);

  if (!userDetails) {
    return;
  }

  const { fullName, email, createdOn, isEmailVerified } = userDetails;

  trackerPeopleSet({
    $name: fullName,
    $email: email,
    'Account Created Date': getFriendlyDate(createdOn),
    'Verified Email': isEmailVerified ? 'Verified' : 'Unverified',
    'Subscription Tier': isEmailVerified ? subscriptionInfo.currentPlan?.title : 'Standard',
  });
}

function* updateUserInfoSaga({ payload: { userData, showToast = true } }: TUpdateUserInfoAction) {
  try {
    const { data } = yield call(() => updateUserInfo(userData));
    yield call(sync, 'updateUserDetails', { details: data.details });
    yield call(sync, 'invalidateFavoriteTracks', {});

    if (showToast) {
      yield call(() => toastSuccess(i18n.t('user.updatedUserInfoSuccessful')));
    }
  } catch (reason) {
    const error = reason as TErrorResponse | null;

    yield put(updateUserInfoAction.failure(error?.response));
    yield call(() =>
      toastError(error?.response?.data?.errorMessage || error?.message || i18n.t('errors.unknown')),
    );
  }
}

function* signInUserSaga({ payload }: TSignInAction) {
  try {
    const { method, tokens } = yield call(performSignIn, payload);

    yield put(signin.success({ justSignedUp: false }));

    yield call(() => {
      trackEvent(ActionEvents.loginCompleted, { Method: method });
      trackGTMEvent(GTMEvents.signIn, {
        authenticationMethod: method,
        userId: tokens.id,
      });
    });
  } catch (reason) {
    const error = reason as Error;

    yield call(trackGTMError, GTMEvents.signIn, error);
    yield put(signin.failure(error));
  }
}

function* signUpUserSaga({ payload }: TSignUpAction) {
  const loadUser = () => getUserInfo([ProfileSection.details]).then(({ data }) => data);

  const performSignUp = async () => {
    if ('tokens' in payload) {
      const { method } = await performSignIn(payload);
      const consentInfo = getRecord('profile:consentInfo');

      // Try and save previously stored agreement data
      if (consentInfo) {
        const { data: user } = await updateConsent(consentInfo);
        const { data: tokens } = await refreshAccessToken();

        // Update tokens so they had termsAccepted: true
        setTokens(tokens);

        // No need to load user details again, it's in updateConsent's response
        return { user, method };
      }

      return { method, user: await loadUser() };
    }

    await signUpUser(payload);
    await performSignIn({
      username: payload.email,
      password: payload.password,
      captcha: AUTO_SIGN_IN_CAPTCHA,
    });

    return {
      method: 'email',
      user: await loadUser(),
    };
  };

  const trackRakutenEvents = async () => {
    const rakutenCookie = getCookie('rakuten');

    if (!rakutenCookie) {
      return;
    }

    try {
      await postRakutenUserInfo(rakutenCookie);

      const result = await getRakutenUserInfo('USER_SIGNUP');

      if (result) {
        trackRakutenConversion({
          mid: result.mid,
          ord: result.orderId,
          tr: result.siteId,
          land: result.land,
          skulist: result.sku,
          qlist: '1',
          amtlist: '0',
          cur: 'USD',
          namelist: result.productName,
        });
      }
    } catch (error) {
      //
    }
  };

  try {
    const { user, method } = yield call(performSignUp);

    yield put(signin.success({ justSignedUp: true }));
    yield put(signup.success(user.details));

    if (hasFeature('rakutenTracking')) {
      trackRakutenEvents().then(noop);
    }

    yield userDataSaga();

    // Submit HS form after OAuth sign up
    if ('tokens' in payload) {
      yield call(trackHSForm, user);
    }

    yield call(() => {
      trackEvent(ActionEvents.signUpCompleted);
      trackGTMEvent(GTMEvents.signUp, {
        authenticationMethod: method,
        userId: user.userId,
      });
    });
  } catch (error) {
    yield put(signup.failure(error));
    yield call(trackGTMError, GTMEvents.signUp, error as Error);

    if (isResponseError(error, 409, 'error.email.duplicate')) {
      yield call(handleError, error, { toast: false });
    }
  }
}

function* referralEmailsSaga() {
  try {
    const { data }: { data: TReferral[] } = yield call(() => getReferralEmails());

    yield put(getReferralEmailsAction.success(data));
  } catch (error) {
    yield put(getReferralEmailsAction.failure(error));
  }
}

function* referralEmailSendingSaga({
  payload: { name, email, setShowLoader },
}: TSendReferralEmailAction) {
  try {
    setShowLoader(true);
    const { data } = yield call(() => sendReferralEmail(name, email));
    yield put(sendReferralEmailAction.success());
    yield call(() => sync('addReferralEmail', { referral: data.referral }));
    yield call(() =>
      trackEvent(ActionEvents.addReferral, { invitedEmail: email, invitedUserName: name }),
    );
    yield call(() => toastSuccess(data.message));
  } catch (reason) {
    const error = reason as TErrorResponse | null;

    yield put(sendReferralEmailAction.failure(error));
    yield call(() => toastError(error?.response?.data?.errorMessage || i18n.t('errors.unknown')));
  } finally {
    setShowLoader(false);
  }
}

function* verificationEmailSaga({ payload }: TEmailVerificationAction) {
  try {
    const {
      data: { message },
    } = yield call(() => sendEmailVerificationLink({ email: payload }));
    yield put(sendVerifyEmail.success());
    yield call(() => toastSuccess(message));
  } catch (reason) {
    const error = reason as TErrorResponse | null;

    yield put(sendVerifyEmail.failure(error?.response));
    yield call(() =>
      toastError(error?.response?.data?.errorMessage || error?.message || i18n.t('errors.unknown')),
    );

    if (error?.response?.data.errorKey === 'error.account.emailVerificationLink.alreadyVerified') {
      yield call(sync, 'updateEmailVerification', {
        status: error?.response?.data?.errorMessage ?? '',
      });
      yield call(sync, 'invalidateAfterEmailVerification', { status: 'alreadyVerified' });
    }
  }
}

function* confirmVerificationEmailSaga({ payload }: TConfirmEmailAction) {
  try {
    const { data } = yield call(validateEmailVerificationLink, payload);
    yield call(sync, 'updateEmailVerification', { status: data.message });
    yield call(sync, 'invalidateAfterEmailVerification', { status: 'justVerified' });
  } catch (reason) {
    const error = reason as TErrorResponse | null;

    yield call(() => sync('invalidateAfterEmailVerification', { status: 'alreadyVerified' }));
    yield put(sendConfirmVerifyEmail.failure(error?.response?.data?.errorKey));
  }
}

function* tokenRefreshSaga() {
  try {
    const { data } = yield call(() => refreshAccessToken());

    setTokens(data);
    setCookie({ name: 'logged', value: 'true' });
  } catch {
    yield cleanState();
  }
}

function* initPasswordResetSaga({ payload }: TInitPasswordReset) {
  try {
    const { data } = yield call(() => initPasswordReset(payload));

    yield put(initPasswordResetAction.success(data.message));
  } catch (reason) {
    const error = reason as TErrorResponse | null;

    yield put(initPasswordResetAction.failure(error?.response?.data?.errorMessage));
  }
}

function* passwordResetLinkValiditySaga({ payload: encryptedPayload }: TValidatePasswordResetLink) {
  try {
    const { data } = yield call(() => validatePasswordResetLink(encryptedPayload));

    yield put(validatePasswordResetLinkAction.success(data));
  } catch (reason) {
    const error = reason as TErrorResponse | null;

    if (error?.response?.data) {
      const payload = error.response.data as { email: string };

      yield put(validatePasswordResetLinkAction.failure(payload.email));
    } else {
      yield put(validatePasswordResetLinkAction.failure());
    }
  }
}

function* passwordResetFinishSaga({ payload: { encryptedPayload, body } }: TPasswordResetFinish) {
  try {
    yield passwordResetFinishAction.request();
    const { data } = yield call(() => finishPasswordReset({ encryptedPayload, body }));
    yield put(passwordResetFinishAction.success(data.message));
    yield call(() => toastSuccess(data.message));
  } catch (reason) {
    const error = reason as TErrorResponse | null;

    yield put(passwordResetFinishAction.failure(error?.response.data?.errorMessage));
    yield call(() => toastError(error?.response.data?.errorMessage || i18n.t('errors.unknown')));
  }
}

function* changeUserPhotoSaga({ payload }: TChangeUserPhotoAction) {
  try {
    const userData: TUserDetails = yield select(selectUserData);
    const { data } = yield call(() => changeUserPhotoService({ image: payload }));
    yield call(() => toastSuccess(data.message));
    yield call(() => sync('updateUserPhoto', { userData, imagePath: data.imagePath }));
  } catch (reason) {
    const error = reason as TErrorResponse | null;

    yield put(changeUserPhoto.failure(error?.response));
    yield call(() =>
      toastError(error?.response.data?.errorMessage || error?.message || i18n.t('errors.unknown')),
    );
  }
}

function* changePasswordSaga({ payload }: TChangePasswordAction) {
  try {
    const userData: TUserDetails = yield select(selectUserData);
    const { data } = yield call(() => changePasswordService(payload));
    yield put(changePassword.success(userData));
    yield call(() => toastSuccess(data.message));
  } catch (reason) {
    const error = reason as TErrorResponse | null;
    yield put(changePassword.failure(error?.response));
    yield call(() =>
      toastError(error?.response?.data?.errorMessage || error?.message || i18n.t('errors.unknown')),
    );
  }
}

function* initReferralSignUpSaga({ payload }: TInitReferralSignUp) {
  try {
    const { data } = yield call(() => initReferralSignUp(payload));
    yield put(initReferralSignUpAction.success(data.email));
  } catch (reason) {
    const error = reason as TErrorResponse | null;

    yield put(initReferralSignUpAction.failure(error?.response?.data?.errorMessage));
  }
}

function* finishReferralSignUpSaga({ payload: { encryptedPayload, body } }: TFinishReferralSignUp) {
  try {
    const { data } = yield call(finishReferralSignUp, { encryptedPayload, body });
    const { email: username, password } = body;

    yield put(finishReferralSignUpAction.success(data.message));
    yield put(signin.request({ username, password, captcha: AUTO_SIGN_IN_CAPTCHA }));
  } catch (reason) {
    const error = reason as Error;

    if (reason instanceof Error && isResponseError(error)) {
      const { errorKey, errorMessage } = error.response.data;
      yield put(finishReferralSignUpAction.failure(errorMessage));
      yield put(signup.failure(errorKey));

      if (errorKey !== 'error.email.duplicate') {
        yield call(handleError, error, { toast: false });
      }
    }
  }
}

export function* watchProfileFetchData() {
  yield takeLatest(signin.request, signInUserSaga);
  yield takeLatest(signup.request, signUpUserSaga);
  yield takeLatest(getUserData.request, userDataSaga);
  yield takeLatest(updateUserInfoAction.request, updateUserInfoSaga);
  yield takeLatest(getReferralEmailsAction.request, referralEmailsSaga);
  yield takeLatest(sendReferralEmailAction.request, referralEmailSendingSaga);
  yield takeEvery(getType(refreshTokenAction), tokenRefreshSaga);
  yield takeLatest(initPasswordResetAction.request, initPasswordResetSaga);
  yield takeLatest(validatePasswordResetLinkAction.request, passwordResetLinkValiditySaga);
  yield takeLatest(passwordResetFinishAction.request, passwordResetFinishSaga);
  yield takeLatest(changeUserPhoto.request, changeUserPhotoSaga);
  yield takeLatest(changePassword.request, changePasswordSaga);
  yield takeLatest(sendVerifyEmail.request, verificationEmailSaga);
  yield takeLatest(sendConfirmVerifyEmail.request, confirmVerificationEmailSaga);
  yield takeLatest(initReferralSignUpAction.request, initReferralSignUpSaga);
  yield takeLatest(finishReferralSignUpAction.request, finishReferralSignUpSaga);
}
