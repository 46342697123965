import { createReducer } from 'deox';

import type { TNotification } from '@/utils/notificationUtils';

import {
  createEnqueueSnackbarAction,
  createCloseSnackbarAction,
  createRemoveSnackbarAction,
} from './notistack.actions';

const initialState: TNotification[] = [];

export const reducer = createReducer(initialState, (handleAction) => [
  handleAction(createEnqueueSnackbarAction, (state, { payload }) => [
    ...state,
    { key: new Date().getTime() + Math.random(), ...payload },
  ]),

  handleAction(createCloseSnackbarAction, (state, { payload: { key } }) =>
    state.map((notification: TNotification) =>
      notification.key === key ? { ...notification, dismissed: true } : { ...notification },
    ),
  ),

  handleAction(createRemoveSnackbarAction, (state, { payload: { key } }) =>
    state.filter((notification) => notification.key !== key),
  ),
]);
