import { forwardRef } from 'react';

import { styled, Paper, Typography, IconButton } from '@mui/material';
import { SnackbarContent } from 'notistack';

import { Cross } from '@/shared/icons/Cross';

import { colors } from '@/themesConfig/colors';

import type { TNotificationViewProps } from './types';

const StyledAlert = styled(Paper, { name: 'Alert' })(({ theme }) => ({
  width: '100%',
  maxWidth: '340px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  backgroundColor:
    theme.palette.mode === 'dark' ? colors.lightBlack : theme.palette.background.default,
  color: theme.palette.text.primary,
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.30)',
  borderRadius: '8px',
}));

const Icon = styled('div', { name: 'AlertIcon' })(({ theme }) => ({
  padding: theme.spacing(1),
  lineHeight: 0,
}));

const Title = styled(Typography, { name: 'AlertTitle ' })({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: 1.5,
});

const Clarification = styled(Typography, { name: 'AlertClarification' })({
  fontWeight: 500,
});

const Content = styled('div', { name: 'AlertContent' })(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1, 0),
}));

const CloseButton = styled(IconButton, { name: 'AlertCloseButton' })(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const NotificationView = forwardRef<HTMLDivElement, TNotificationViewProps>((props, ref) => {
  const { style, message, variant, iconVariant, close, id, clarification } = props;

  return (
    <SnackbarContent ref={ref} style={style} role="alert" key={id}>
      <StyledAlert>
        <Icon>{iconVariant[variant]}</Icon>
        <Content>
          <Title>{message}</Title>
          {clarification && (
            <Clarification variant="body2" color="textSecondary">
              {clarification}
            </Clarification>
          )}
        </Content>
        <CloseButton
          onClick={() => {
            close(id);
          }}
        >
          <Cross color={colors.mistGray} />
        </CloseButton>
      </StyledAlert>
    </SnackbarContent>
  );
});

NotificationView.displayName = 'NotificationView';
