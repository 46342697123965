import { ContentType, TrackType } from '@/enums/track';

import { type ITrack } from '@/types/trackListView';

import { type TPlayerStore } from './types';

export const noTrack: ITrack = {
  id: '',
  name: '',
  template: {
    id: 0,
    name: '',
  },
  genre: {
    rootId: 0,
    rootKey: '',
  },
  bpm: 0,
  key: '',
  duration: 0,
  imageUrl: '',
  packPath: {
    mp3Path: '',
  },
  createdOn: '',
  favorite: false,
  trackType: TrackType.global,
  contentType: ContentType.track,
  renderInfo: {
    status: null,
    startTime: null,
    endTime: null,
  },
  lastDownloaded: '',
  purchaseDateTime: '',
  purchased: false,
  creatable: false,
  downloadable: true,
};

const storedShuffleSeed = localStorage.getItem('shuffleSeed');
const isShuffled = Boolean(storedShuffleSeed);

export const initialState: TPlayerStore = {
  activeTrack: noTrack,
  trackList: [],
  activeCard: '',
  trackIndex: null,
  source: '',
  savedTrack: noTrack,
  savedTrackList: [],
  savedSource: '',
  shuffle: isShuffled,
  shuffleSeed: storedShuffleSeed || null,
  savedTrackIndex: null,
};
