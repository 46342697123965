import { type InternalAxiosRequestConfig, AxiosHeaders } from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const correlationIdInterceptor = <P>(
  config: InternalAxiosRequestConfig<P>,
): InternalAxiosRequestConfig<P> => {
  const headers = new AxiosHeaders(config.headers);

  headers.set('correlation_id', uuidv4());

  config.headers = headers;

  return config;
};
