import type { ReactNode } from 'react';

import { composeWithDevTools } from '@redux-devtools/extension';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { PRODUCTION } from '@/constants';
import { initializeLogout } from '@/utils/authUtils';
import { initializeToast, type TNotification } from '@/utils/notificationUtils';

import { createEnqueueSnackbarAction } from '@/store/notistack/notistack.actions';

import { selectUserEmail } from './profile/profile.selectors';
import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const storeEnhancers = PRODUCTION
  ? applyMiddleware(...middlewares)
  : composeWithDevTools(applyMiddleware(...middlewares));

export const store = createStore(rootReducer, {}, storeEnhancers);

initializeToast({
  toastError: (message: string, clarification?: ReactNode) => {
    store.dispatch(
      createEnqueueSnackbarAction({
        message,
        clarification,
        options: { variant: 'error' },
      }),
    );
  },
  toastSuccess: (message: string, clarification?: ReactNode) => {
    store.dispatch(
      createEnqueueSnackbarAction({ message, clarification, options: { variant: 'success' } }),
    );
  },
  toastInfo: (message: string, clarification?: ReactNode) => {
    store.dispatch(
      createEnqueueSnackbarAction({ message, clarification, options: { variant: 'info' } }),
    );
  },
  toast: (options: TNotification) => {
    store.dispatch(createEnqueueSnackbarAction(options));
  },
});

initializeLogout(() => selectUserEmail(store.getState()));

sagaMiddleware.run(rootSaga);
