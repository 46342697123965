import { debounce } from '@/utils/functionalUtils';

export const watchScreenHeight = () => {
  const updateScreenHeightProperty = debounce(() => {
    requestAnimationFrame(() => {
      document.documentElement.style.setProperty('--screenHeight', `${window.innerHeight}px`);
    });
  }, 50);

  window.addEventListener('resize', updateScreenHeightProperty);
  window.addEventListener('orientationchange', updateScreenHeightProperty);

  updateScreenHeightProperty();
};
