import { useAtomValue, useSetAtom, atom } from 'jotai';

import { isEmailProvidedForSoundCloud } from '@/components/soundCloud/utils';

import type { TGetTokenResponse, TSoundCloudTokenPayload } from '@/types/profile';

import { atomWithStorageInit } from '@/jotai-overrides';

export type TSoundCloudSignUpTokens = TGetTokenResponse & TSoundCloudTokenPayload;

export const soundCloudSignUpAtom = atomWithStorageInit<TSoundCloudSignUpTokens | undefined>(
  'soundcloud:signUp:tokens',
  undefined,
);

export const emailIsNotProvidedAtom = atom((get) => {
  const tokens = get(soundCloudSignUpAtom);

  return tokens ? isEmailProvidedForSoundCloud(tokens) : false;
});

export const useSoundCloudSignUpTokensValue = () => useAtomValue(soundCloudSignUpAtom);

export const useSetSoundCloudSignUpTokens = () => useSetAtom(soundCloudSignUpAtom);
