/* eslint-disable react/no-children-prop */
import { Suspense, useEffect, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import { useSyncOpenGraphMetaTags } from '@/hooks/useSyncOpenGraphMetaTags';

import { LOAD_MARKETING_SCRIPTS, LOAD_SMARTLOOK_SCRIPTS } from '@/config/marketing';
import { SYSTEM_ROUTES, PUBLIC_ROUTES, LANDING_ROUTES } from '@/config/routes';
import { hasFeature } from '@/utils/featureFlag';
import { remoteLogger } from '@/utils/logging';

import { bindMediaHandlers } from '@/components/player/renderless/playerHotkeys/mediaButtonHandlers';
import { NewVersionSentinel } from '@/components/versionUpdater';
import { PrivateRoute } from '@/shared/router/PrivateRoute';

import { isBrowserSupported } from '@/isBrowserSupported';
import { loadGA, loadTagManager, loadReCaptcha } from '@/scripts/google';
import { loadHubSpot } from '@/scripts/hubSpot';
import { initRakutenGlobalCode } from '@/scripts/rakuten';
import { loadSmartlook } from '@/scripts/smartlook';
import { watchScreenHeight } from '@/themesConfig/themes/script';
import { trackEvent, initTracker, ActionEvents, getTrackerDistinctId } from '@/tracking';

import { PRODUCTION, IS_AUTH_POPUP } from './constants';
import { useSynchronizer } from './hooks/synchronizer/useSynchronizer';
import { initRemoteLogger } from './initRemoteLogger';
import { storeParametersOnLoad } from './utils/urlUtils';

const SystemRoutes = lazy(() => import('./routes/SystemRoutes'));
const PublicRoutes = lazy(() => import('./routes/PublicRoutes'));
const LandingPage = lazy(() => import('@/pages/Landing/LandingPage'));
const SoundfulPage = lazy(() => import('@/pages/Soundful/SoundfulPage'));
const LANDING_ROUTES_PATHS = Object.values(LANDING_ROUTES);

initRemoteLogger();
bindMediaHandlers();
watchScreenHeight();

export const App = () => {
  useSynchronizer();

  useEffect(() => {
    storeParametersOnLoad();

    initTracker(window.env.MIXPANEL_PROJECT_TOKEN, {
      ignore_dnt: true,
      api_host: window.env.MIXPANEL_PROXY_URL,
    });

    if (!IS_AUTH_POPUP) {
      trackEvent(ActionEvents.openSite, { 'Is Browser Supported': isBrowserSupported() });

      if (hasFeature('rakutenTracking')) {
        initRakutenGlobalCode();
      }
    }

    if (LOAD_MARKETING_SCRIPTS) {
      loadHubSpot();
      loadGA();
    }

    remoteLogger.setDistinctId(getTrackerDistinctId());

    if (!hasFeature('reCaptchaDisabled')) {
      loadReCaptcha();
    }

    if (PRODUCTION) {
      loadTagManager();
    }

    if (LOAD_SMARTLOOK_SCRIPTS) {
      loadSmartlook();
    }
  }, []);

  useSyncOpenGraphMetaTags();

  return (
    <Suspense fallback="">
      <Switch>
        <Route path={SYSTEM_ROUTES.root} children={<SystemRoutes />} />
        <Route path={PUBLIC_ROUTES.root} children={<PublicRoutes />} />
        <Route path={LANDING_ROUTES_PATHS} children={<LandingPage />} />
        <Route
          render={() => (
            <PrivateRoute>
              <SoundfulPage />
            </PrivateRoute>
          )}
        />
      </Switch>
      <NewVersionSentinel />
    </Suspense>
  );
};
