import type { AxiosResponse } from 'axios';

import { ProductType } from '@/enums/profile';
import { ContentType } from '@/enums/track';

import type { TRequest, Dict } from '@/types/common';
import { type TPurchaseWithModel } from '@/types/product';
import type { TPurchaseHistoryResponse } from '@/types/purchase';
import { type ITemplate } from '@/types/template';

import { profileService } from '@/api/private';

export const getPurchaseHistory: TRequest<TPurchaseHistoryResponse, number> = (pageSize = 50) =>
  profileService.get(`/transaction-history?pageSize=${pageSize}&start=0`);

export type TCreatePaymentIntentProps<PHasBody extends boolean> = {
  planId: number;
  category: string;
  couponCode?: string;
  body?: PHasBody extends true ? Dict : never;
};

export type TPaymentIntent = {
  clientSecret: string;
  id: string;
};

export type TCreatePaymentIntentResult =
  | {
      paymentIntent: TPaymentIntent;
    }
  | {
      result: string;
      showCongratsModal?: boolean;
    };

const createUserProfilePaymentIntent = <TBody extends boolean = true>({
  planId,
  category,
  couponCode,
  body,
}: TCreatePaymentIntentProps<TBody>): Promise<AxiosResponse<TCreatePaymentIntentResult>> => {
  const searchParams = new URLSearchParams();

  searchParams.append('planId', String(planId));

  if (couponCode) {
    searchParams.append('coupon', couponCode);
  }

  return profileService.post(`/${category}/purchase?${searchParams.toString()}`, body);
};

const getPurchaseType = (category: ContentType) => {
  return category === ContentType.track ? 'TEMPLATE' : 'LOOP';
};

const createPremiumPlusTemplateAndSubscriptionPaymentIntent = (
  planId: number,
  templateId: number,
  templateCategory: ContentType,
  couponCode?: string,
) => {
  return createUserProfilePaymentIntent({
    planId,
    category: 'subscription',
    couponCode,
    body: {
      entityList: [
        {
          entityId: templateId,
          entityType: getPurchaseType(templateCategory),
        },
      ],
    },
  });
};

const createPremiumPlusTemplatePaymentIntent = (
  templateId: number,
  templateCategory: ContentType,
  couponCode?: string,
) => {
  const searchParams = new URLSearchParams();

  if (couponCode) {
    searchParams.append('coupon', couponCode);
  }

  const search = searchParams.toString();
  const type = getPurchaseType(templateCategory);
  const queryString = search ? `?${search}` : '';

  return profileService.post(`/purchase/${templateId}/${type}${queryString}`);
};

export const getCreatePaymentIntent = (purchases: TPurchaseWithModel[], couponCode?: string) => {
  if (purchases.length === 1) {
    const purchase = purchases[0];

    switch (purchase.category) {
      case ProductType.subscription:
      case ProductType.bundle: {
        return () =>
          createUserProfilePaymentIntent({
            planId: purchase.productId,
            category: purchase.category,
            couponCode,
          });
      }

      case ProductType.collaboration: {
        const template = purchase.model as ITemplate;

        return () =>
          createPremiumPlusTemplatePaymentIntent(
            purchase.productId,
            template.contentType,
            couponCode,
          );
      }
    }
  }

  const subscription = purchases.find((purchase) => purchase.category === ProductType.subscription);
  const collaboration = purchases.find(
    (purchase) => purchase.category === ProductType.collaboration,
  );

  if (subscription && collaboration) {
    const template = collaboration.model as ITemplate;

    return () =>
      createPremiumPlusTemplateAndSubscriptionPaymentIntent(
        subscription.productId,
        collaboration.productId,
        template.contentType,
        couponCode,
      );
  }

  throw new Error('Invalid purchases combination');
};

export const notifyCopyrightBundleRequested = (planId: number) => {
  const searchParams = new URLSearchParams();

  searchParams.append('planId', String(planId));

  return profileService.post(`/bundle/purchase?${searchParams.toString()}`);
};

export const cancelSubscription = () => profileService.post('/subscription/cancel');

export const cancelFutureSubscription = () => profileService.post('/subscription-schedule/cancel');

// GET /profile-management/plans/validate-coupon?couponCode=${couponCode}&planId=${planId}&templateId=${templateId}
export const getValidateCouponCodeUrl = ({
  templateId,
  planId,
  couponCode,
}: {
  templateId?: number;
  planId?: number;
  couponCode: string;
}) => {
  const searchParams = new URLSearchParams();

  searchParams.set('couponCode', couponCode.trim());

  if (planId) {
    searchParams.set('planId', String(planId));
  }

  if (templateId) {
    searchParams.set('templateId', String(templateId));
  }

  return `/validate-coupon?${searchParams.toString()}`;
};
