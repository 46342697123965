import axios, { type AxiosError } from 'axios';

import { LOCAL } from '@/constants';
import { remoteLogger, getDetailsFromAxiosError } from '@/utils/logging';

import type { TCaptchaResponse } from '../captchaUtils/types';
import { CommonError, type TErrorDetails } from '../errors';
import { toastError } from '../notificationUtils';

import type { THandleErrorOptions, TErrorPayload, TErrorResponse } from './types';

export const isResponseError = (
  error: unknown,
  status?: number,
  key?: string,
): error is TErrorResponse =>
  axios.isAxiosError(error) &&
  !!error.response &&
  (!status || error.response?.status === status) &&
  (!key || (error.response?.data as TErrorPayload).errorKey === key);

export const isCaptchaError = ({ response }: AxiosError) =>
  (response?.data as { data?: TCaptchaResponse })?.data?.recaptcha_status === 'FAILED' ||
  (response?.data as TCaptchaResponse)?.recaptcha_status === 'FAILED';

export const normalizeError = (error: unknown): CommonError => {
  if (typeof error === 'string') {
    return new CommonError(error);
  }

  if (error instanceof CommonError) {
    return error;
  }

  if (!(error instanceof Error)) {
    // if something not Error | string is thrown, track it in `cause` prop
    return new CommonError('errors.unknown', { cause: error });
  }

  if (isResponseError(error)) {
    // The request was made and the server responded with errorMessage
    const { errorMessage, error: errorData, errorKey } = error.response.data || {};

    if (typeof errorData === 'object') {
      const { message } = errorData;

      return new CommonError(errorMessage || message || errorKey, null, error);
    }

    return new CommonError(errorMessage || errorData || errorKey, null, error);
  }

  return new CommonError('errors.unknown', null, error);
};

export const handleError = (
  error: unknown,
  { toast = false, remoteLog = true }: THandleErrorOptions = {},
  rootDetails?: TErrorDetails,
  innerDetails?: TErrorDetails,
) => {
  const normError = normalizeError(error);

  const logDetails = {
    ...rootDetails,
    details: {
      ...(typeof rootDetails?.details === 'object' ? rootDetails.details : null),
      ...innerDetails,
    },
  };

  if (remoteLog) {
    remoteLogger.error(normError, logDetails);
  }

  if (toast) {
    toastError(normError.message);
  }

  if (LOCAL) {
    console.error(error, { details: normError.details }, logDetails);
  }
};

export const handleResponseError = (
  error: unknown,
  options: THandleErrorOptions = {},
  rootDetails?: TErrorDetails,
  innerDetails?: TErrorDetails,
) => {
  if (axios.isAxiosError(error) && !axios.isCancel(error) && !isCaptchaError(error)) {
    const executionTimeSeconds =
      rootDetails?.startedAt && typeof rootDetails.startedAt === 'number'
        ? (Date.now() - rootDetails.startedAt) / 1000
        : undefined;

    handleError(
      error,
      options,
      {
        category: 'RESPONSE_INTERCEPTOR',
        ...rootDetails,
        executionTimeSeconds,
      },
      {
        ...getDetailsFromAxiosError(error),
        ...innerDetails,
      },
    );
  }
};
