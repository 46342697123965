import { type Nullable } from '@/types/common';
import { type ITrack } from '@/types/trackListView';

import { type TStore } from '../types';

export const selectActiveTrack = (state: TStore): ITrack => state.player.activeTrack;

export const selectTrackList = (state: TStore): ITrack[] => state.player.trackList;

export const selectTrackIndex = (state: TStore): Nullable<number> => state.player.trackIndex;

export const selectSource = (state: TStore): string => state.player.source;

export const selectActiveCard = (state: TStore): string => state.player.activeCard;

export const selectShuffle = (state: TStore): boolean => state.player.shuffle;

export const selectShuffleSeed = (state: TStore): Nullable<string> => state.player.shuffleSeed;
