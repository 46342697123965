import axios from 'axios';

import { handleResponseError } from '@/utils/errorUtils';

import type { Dict } from '@/types/common';

declare module 'axios' {
  export interface AxiosRequestConfig {
    toastError?: boolean;
    skipRemoteLog?: boolean;
    remoteLogProps?: Dict<unknown> & {
      details?: Dict<unknown>;
      startedAt?: number;
    };
  }
}

export const handleErrorInterceptor = (error: Error) => {
  if (axios.isAxiosError(error)) {
    handleResponseError(
      error,
      {
        toast: !!error.config?.toastError,
        remoteLog: !error.config?.skipRemoteLog,
      },
      error.config?.remoteLogProps,
    );
  }

  return Promise.reject(error);
};
