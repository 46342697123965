export const DEFAULT_SORT_BY = 'createdOn';
export const DEFAULT_GLOBAL_TRACKS_SORT_BY = 'sortOrder';

export const SORT_BY_PURCHASE_DATE = 'purchaseDateTime';
export const DEFAULT_SORT_DIRECTION = 'DESC';

export const MIN_BPM = 1;
export const MAX_BPM = 240;

export const DEFAULT_EXACT_BPM = 120;
export const DEFAULT_BPM_RANGE = [MIN_BPM + 8, MAX_BPM - 8];

export const TABLE_PAGE_SIZE = 30;

export const LOAD_TRACK_DEBOUNCE_TIME = 300;

export const DEFAULT_LOAD_DELAY = 0;

export const SOUNDFUL_CREATOR = 'Soundful';

export const DEFAULT_WAVEFORM_MESSAGE = 'Waveform Coming Soon';
