export const breakpoints = {
  values: {
    xs: 0, // do not use
    sm: 600, // do not use
    md: 960, // do not use
    lg: 1280, // do not use
    xl: 1920, // do not use
    sxAll: 0, // equivalent of `xs`. Can be used inside of `sx` prop: sx={{ padding: { sxAll: 2, mobile: 3, desktop: 4 }}}
    mobile: 600,
    tablet: 960,
    netbook: 1280,
    laptop: 1440,
    desktop: 1920,
  },
};
