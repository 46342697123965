import { createActionCreator } from 'deox';

import type { TReferral, TUserDetails } from '@/types/profile';

import type { TSignInData, TSignInRequest, TSignUpRequest } from '@/store/profile/types';

import { createAsyncActions } from '../async/async.actions';

export const signin = createAsyncActions<TSignInData, TSignInRequest>('signin');

export const signup = createAsyncActions<TUserDetails, TSignUpRequest>('signup');

export const getUserData = createAsyncActions<TUserDetails>('get_user_data');

export const updateUserInfo = createAsyncActions('update_user_info');

export const getReferralEmails = createAsyncActions('get_referral_emails');

export const sendReferralEmail = createAsyncActions('send_referral_email');

export const sendVerifyEmail = createAsyncActions('send_verify_email');

export const sendConfirmVerifyEmail = createAsyncActions('send_confirm_verify_email');

export const addReferralEmail = createActionCreator(
  'ADD_REFERRAL_EMAIL',
  (resolve) => (payload: TReferral) => resolve(payload),
);

export const refreshTokenAction = createActionCreator(
  'REFRESH_TOKEN',
  (resolve) => () => resolve(),
);

export const initPasswordResetAction = createAsyncActions('init_password_reset');

export const validatePasswordResetLink = createAsyncActions('check_password_link_validity');
export const passwordResetFinishAction = createAsyncActions('finish_password_reset');
export const changeUserPhoto = createAsyncActions('change_user_photo');

export const changePassword = createAsyncActions('change_password');

export const initReferralSignUp = createAsyncActions('init_referral_sign_up');

export const finishReferralSignUp = createAsyncActions('finish_referral_sign_up');
