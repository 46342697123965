import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nResourcesToBackend from 'i18next-resources-to-backend';

const importLocale = (language: string, namespace: string) =>
  import(/* webpackChunkName: "locales/" */ `./locales/${language}/${namespace}.json`);

const Backend = i18nResourcesToBackend(async (language, namespace, callback) => {
  try {
    const resource = await importLocale(language, namespace);

    callback(null, resource);
  } catch (error) {
    callback(error as Error, null);
  }
});

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
    },
  });

i18n.services.formatter?.add('possessive', (value, lng) => {
  if (lng === 'en') {
    return possessiveEnFormatter(value);
  }

  return value;
});

function possessiveEnFormatter(value: string) {
  if (!value) {
    return '';
  }

  if (value.endsWith('s') || value.endsWith('S')) {
    return `${value}'`;
  }

  return `${value}'s`;
}
