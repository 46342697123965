import { Component, type ReactNode, type ErrorInfo } from 'react';

import { normalizeError } from '@/utils/errorUtils';
import { remoteLogger } from '@/utils/logging';

import { isBrowserSupported } from '@/isBrowserSupported';

import { FallbackError } from './FallbackError';
import type { ErrorBoundaryProps, ErrorBoundaryState } from './types';

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const normalizedError = normalizeError(error);
    const isSupported = isBrowserSupported();
    const logInfo = {
      errorInfo,
      category: 'ERROR_BOUNDARY',
      details: {
        isBrowserSupported: isSupported,
        isResettable: this.props.isResettable,
        placement: this.props.placement,
      },
    };

    if (isSupported) {
      remoteLogger.error(normalizedError, logInfo);
    } else {
      remoteLogger.warn(normalizedError.message, { ...logInfo, error: normalizedError });
    }
  }

  reset = () => {
    this.setState({ hasError: false });
  };

  render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <FallbackError isResettable={this.props.isResettable} reset={this.reset} />
    ) : (
      children
    );
  }
}
