import { alpha } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { colors } from '../colors';

import { breakpoints } from './breakpoints';
import { commonSettings, toAutofillStyles } from './common';
import { typography } from './typography';

export const darkTheme = createTheme(
  {
    typography,
    breakpoints,
    palette: {
      mode: 'dark',
      primary: {
        main: colors.primaryRed, // highlighted elements
      },
      secondary: {
        main: colors.almostBlack, // sections background
        light: colors.darkestGray, // active elements background
        dark: colors.lightBlack, // searchBar background
      },
      divider: colors.darkestGray, // borders
      text: {
        primary: colors.whiteSmoke, // main or active text
        secondary: colors.mistGray, // inactive elements text
        disabled: colors.darkerGray, // disabled elements text
      },
      action: {
        hover: colors.paynesGray,
      },
      success: {
        main: colors.green,
      },
      error: {
        main: colors.pinkRed,
      },
    },
    custom: {
      background: colors.fallbackBlack,
      backgroundGradient: `linear-gradient(225deg, ${colors.almostBlack} 0%, ${colors.black} 100%)`,
      outline: colors.shuttleGray,
      backgroundSecondary: colors.mistGray,
      backgroundDeep: colors.paleBlack,
      inactive: colors.mistGray,
      paper: colors.lightBlack,
      disabledBackground: colors.black,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: colors.fallbackBlack,
            backgroundImage: `linear-gradient(225deg, ${colors.almostBlack} 0%, ${colors.black} 100%)`,
            backgroundAttachment: 'fixed',
          },
          '*::-webkit-scrollbar': {
            backgroundColor: alpha(colors.paynesGray, 0.8),
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: alpha(colors.mistGray, 0.8),
          },
          '*::-webkit-scrollbar-thumb:hover': {
            background: colors.silverGray,
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: colors.paleBlack,
          },

          notchedOutline: {
            borderColor: colors.shuttleGray,
          },

          input: {
            '&:-webkit-autofill': toAutofillStyles(colors.paleBlack, colors.whiteSmoke),
            '&.Mui-disabled:-webkit-autofill': toAutofillStyles(colors.black, colors.darkerGray),
          },
        },
      },

      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary',
        },
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing('13px', 4),
            borderRadius: theme.spacing(2.5),
            fontWeight: 500,
            lineHeight: '14px',
            textTransform: 'uppercase',
            textDecoration: 'none',
          }),

          outlinedSecondary: {
            background: colors.paleBlack,
            border: `1px solid ${colors.shuttleGray}`,
            color: colors.mistGray,
            '&:hover': {
              background: colors.lightBlack,
              border: `1px solid ${colors.shuttleGray}`,
            },
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          outlined: {
            background: colors.paleBlack,
            border: `1px solid ${colors.shuttleGray}`,
            color: colors.whiteSmoke,
          },
        },
      },

      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.MuiInputLabel-root.Mui-focused': {
              color: colors.whiteSmoke,
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              background: colors.black,
            },
            '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
              borderColor: colors.whiteSmoke,
            },
          },
        },
      },

      MuiInputAdornment: {
        styleOverrides: {
          root: {
            color: colors.shuttleGray,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '.MuiChip-deleteIcon.MuiChip-deleteIconSmall': {
              color: colors.mistGray,
            },
          },
          tag: {
            backgroundColor: colors.darkestGray,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            paddingTop: 11,
          },
          track: {
            width: '36px',
            height: '16px',
            borderRadius: '8px',
          },
        },
      },
    },
  },
  commonSettings,
);
