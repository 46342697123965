import type { Nullable } from '@/types/common';

import type { TCaptchaConfig } from './types';

const ensureCaptchaApi = () => new Promise<void>((resolve) => window.grecaptcha.ready(resolve));

const getCaptchaToken = (config: TCaptchaConfig) => {
  if (config.version === 'v3') {
    return window.grecaptcha.execute(window.env.CAPTCHA_V3_KEY, { action: config.action });
  }

  return window.grecaptcha.getResponse(config.widgetId);
};

export const getCaptchaParams = async (config: Nullable<TCaptchaConfig>) => {
  if (!config) {
    return undefined;
  }

  await ensureCaptchaApi();

  return {
    recaptcha_api_version: config.version,
    recaptcha_response: await getCaptchaToken(config),
  };
};
