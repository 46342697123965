import type { ReactNode } from 'react';

import type { OptionsObject, SnackbarKey } from 'notistack';

import { noop } from '@/utils/functionalUtils';

type TToastMethod = (message: string, clarification?: ReactNode) => void;

export type TNotification = {
  message: string | ReactNode;
  clarification?: ReactNode;
  options: OptionsObject;
  dismissed?: boolean;
  key?: SnackbarKey;
};

type TSource = {
  toastError: TToastMethod;
  toastSuccess: TToastMethod;
  toastInfo: TToastMethod;
  toast: (options: TNotification) => void;
};

let source: TSource = {
  toastError: noop,
  toastSuccess: noop,
  toastInfo: noop,
  toast: noop,
};

export const initializeToast = (newSource: TSource): void => {
  source = newSource;
};

export const toastError = (message: string, clarification?: ReactNode) => {
  source.toastError(message, clarification);
};

export const toastInfo = (message: string, clarification?: ReactNode) => {
  source.toastInfo(message, clarification);
};

export const toastSuccess = (message: string, clarification?: ReactNode) => {
  source.toastSuccess(message, clarification);
};

export const toast = (options: TNotification) => {
  source.toast(options);
};
