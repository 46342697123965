export enum ReferralStatus {
  invite = 'INVITE',
  pending = 'PENDING',
  accepted = 'ACCEPTED',
}

export enum ProductType {
  subscription = 'subscription',
  bundle = 'bundle',
  collaboration = 'collaboration',
}

export enum PlanKeys {
  standard = 'Standard',
  premium = 'Premium',
  pro = 'Pro',
  business1 = 'Business 1',
  business2 = 'Business 2',
  business3 = 'Business 3',
  enterprise = 'Enterprise',
}

export const planLevel = {
  [PlanKeys.standard]: 0,
  [PlanKeys.premium]: 1,
  [PlanKeys.pro]: 2,
  [PlanKeys.business1]: 10,
  [PlanKeys.business2]: 11,
  [PlanKeys.business3]: 12,
  [PlanKeys.enterprise]: 100,
};

export enum StemBundleKeys {
  'plus3Stems' = '+3STEMS',
}

export enum CopyrightBundleKeys {
  'copyright1' = '1Copyright',
  'copyrights5' = '5Copyrights',
  'copyrights15' = '15Copyrights',
}

export enum PaymentForm {
  Monthly = 'monthly',
  Annual = 'annual',
  OneTime = 'one_time',
}

export enum PaymentMethodType {
  Card = 'creditCard',
  ApplePay = 'applePay',
  GooglePay = 'googlePay',
}
