import { createReducer } from 'deox';

import { type Nullable } from '@/types/common';

import { handleAsyncReducers } from '@/store/async/async.reducers';
import { getAsyncActionList } from '@/store/async/helpers';

import {
  getGenresAction,
  getSearchedDataAction,
  getSearchedTagsAction,
  getTemplatesAction,
  getSearchedTracksAction,
  getTagsAction,
  getMoodsAction,
  getArtistsAction,
} from './search.actions';
import { initialState } from './search.initialState';
import type {
  ISearchedTracks,
  TGenre,
  TMoodsOrArtist,
  TSearchedTemplatesAndTracks,
  TTag,
  TTemplateItem,
} from './types';

export const reducer = createReducer(initialState, (handleAction) => [
  handleAction(getAsyncActionList(getMoodsAction), (state, action) => ({
    ...state,
    moods: handleAsyncReducers<Nullable<TMoodsOrArtist[]>>(
      state.moods,
      initialState.moods.data,
      action,
      getMoodsAction,
    ),
  })),

  handleAction(getAsyncActionList(getArtistsAction), (state, action) => ({
    ...state,
    artists: handleAsyncReducers<Nullable<TMoodsOrArtist[]>>(
      state.artists,
      initialState.artists.data,
      action,
      getArtistsAction,
    ),
  })),

  handleAction(getAsyncActionList(getGenresAction), (state, action) => ({
    ...state,
    genres: handleAsyncReducers<Nullable<TGenre[]>>(
      state.genres,
      initialState.genres.data,
      action,
      getGenresAction,
    ),
  })),
  handleAction(getAsyncActionList(getTagsAction), (state, action) => ({
    ...state,
    tags: handleAsyncReducers<Nullable<TTag[]>>(
      state.tags,
      initialState.tags.data,
      action,
      getTagsAction,
    ),
  })),
  handleAction(getAsyncActionList(getSearchedDataAction), (state, action) => ({
    ...state,
    searchedData: handleAsyncReducers<Nullable<TSearchedTemplatesAndTracks>>(
      state.searchedData,
      initialState.searchedData.data,
      action,
      getSearchedDataAction,
    ),
  })),
  handleAction(getAsyncActionList(getTemplatesAction), (state, action) => ({
    ...state,
    templates: handleAsyncReducers<Nullable<TTemplateItem[]>>(
      state.templates,
      initialState.templates.data,
      action,
      getTemplatesAction,
    ),
  })),
  handleAction(getAsyncActionList(getSearchedTracksAction), (state, action) => ({
    ...state,
    searchedTracks: handleAsyncReducers<Nullable<ISearchedTracks>>(
      state.searchedTracks,
      initialState.searchedTracks.data,
      action,
      getSearchedTracksAction,
    ),
  })),
  handleAction(getAsyncActionList(getSearchedTagsAction), (state, action) => ({
    ...state,
    searchedTags: handleAsyncReducers<Nullable<TTag[]>>(
      state.searchedTags,
      initialState.searchedTags.data,
      action,
      getSearchedTagsAction,
    ),
  })),
]);
