import { LOAD_MARKETING_SCRIPTS, LOAD_EVENT_TRACKING_SCRIPTS } from '@/config/marketing';
import { TrackType } from '@/enums/track';
import { generateTimestamp } from '@/utils/dateUtils';
import { isCaptchaError, isResponseError } from '@/utils/errorUtils';
import { hasFeature } from '@/utils/featureFlag';

import type { TDownloadFormat } from '@/components/download/types';

import type { Dict } from '@/types/common';
import type { TUserDetails } from '@/types/profile';
import type { ITrack } from '@/types/trackListView';

import { submitHubSpotForm } from '@/scripts/hubSpot';

import { ActionEvents, GTMEvents, PlaybackEvents } from './config';
import { type Callback, type RequestOptions, trackMixpanelEvent } from './providers/mixpanel';

const verboseEventsTracking = hasFeature('verboseEventsTracking');

export const trackEvent = (
  eventName: string,
  properties?: Dict<unknown>,
  optionsOrCallback?: RequestOptions | Callback | undefined,
  callback?: Callback | undefined,
) => {
  if (LOAD_EVENT_TRACKING_SCRIPTS) {
    trackMixpanelEvent(
      eventName,
      {
        timestamp: generateTimestamp(),
        ...properties,
      },
      optionsOrCallback,
      callback,
    );
  }

  if (verboseEventsTracking) {
    console.info(`[track] ${eventName}`, properties);
  }
};

export const trackGTMEvent = (event: GTMEvents, props?: Dict<unknown>) => {
  if (LOAD_MARKETING_SCRIPTS) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ...props, event });
  }

  if (verboseEventsTracking) {
    console.info(`[track GTM] ${event} ${JSON.stringify(props ?? {}, null, 2)}`);
  }
};

export const trackGTMError = (action: GTMEvents, error: Error, errorKey?: string) => {
  const eventData = {
    errorType: `${action} error`,
    errorMessage: error.message,
    errorKey,
  };

  if (isResponseError(error)) {
    if (isCaptchaError(error)) {
      return;
    }

    if (error.response.data?.errorMessage) {
      eventData.errorKey = error.response.data.errorKey;
      eventData.errorMessage = error.response?.data.errorMessage;
    }
  }

  trackGTMEvent(GTMEvents.error, eventData);
};

export const trackHSForm = (userDetails: TUserDetails) => {
  if (LOAD_MARKETING_SCRIPTS) {
    submitHubSpotForm([
      {
        name: 'email',
        value: userDetails.email,
      },
    ]);
  }

  if (verboseEventsTracking) {
    console.info(`[track HS form] ${userDetails.email}`);
  }
};

const getTrackEventMetadata = (track: ITrack) => ({
  genre: track.genre?.rootKey,
  template: track.template?.name,
  templateParameters: { bpm: track.bpm, key: track.key },
  trackType: track.trackType === TrackType.global ? 'Soundful' : 'Created',
  isArtistCollaborated: track.isArtistCollaborated,
});

export const trackEventWithTrackData = (
  eventName: string,
  track: ITrack,
  properties?: Dict<unknown>,
) => {
  trackEvent(eventName, { ...getTrackEventMetadata(track), ...properties });
};

export const trackRenderEvent = (
  track: ITrack,
  resultAction: string,
  downloadType: string,
  source: string,
  downloadFormats?: TDownloadFormat[],
  soundCloudAutoUpload?: boolean,
) => {
  const event = ActionEvents.getTrack;

  trackEventWithTrackData(event, track, {
    trackName: track.name,
    downloadType,
    resultAction,
    source,
    downloadFormats,
    'Render and upload': soundCloudAutoUpload,
  });
};

export const trackPlaybackStart = (track: ITrack, source: string) => {
  trackEvent(PlaybackEvents.playback, {
    ...getTrackEventMetadata(track),
    trackId: track.id,
    source,
  });
};

export const trackPlaybackProgress = (
  track: ITrack,
  source: string,
  trackPlayedDuration: string,
) => {
  trackEvent(PlaybackEvents.progress, {
    ...getTrackEventMetadata(track),
    trackId: track.id,
    source,
    trackPlayedDuration,
  });
};
