import { SvgIcon, type SvgIconProps } from '@mui/material';
import { useTheme } from '@mui/styles';

export const Alert = (props: SvgIconProps) => {
  const theme = useTheme();

  const contentFill = theme.palette.mode === 'dark' ? '#22272F' : '#fff';

  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
      <circle cx="12" cy="12" r="10.6" fill="#F9444C" />
      <path
        stroke={contentFill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 13V8"
      />
      <circle cx="12" cy="16" r="1" fill={contentFill} />
    </SvgIcon>
  );
};
