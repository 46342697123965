import axios from 'axios';

import { getCookie } from '@/utils/cookieUtils';
import { noop } from '@/utils/functionalUtils';

const HUB_SPOT_ID = '19807026';
const HS_FORM_ID = '67e2d15b-bb21-4169-af31-095d0b383656';
const HS_FORMS_API = 'https://api.hsforms.com/submissions/v3/integration/submit';

export const loadHubSpot = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://js.hs-scripts.com/${HUB_SPOT_ID}.js`;
  document.body.appendChild(script);
};

export const submitHubSpotForm = (fields: Record<'name' | 'value', string>[]) => {
  axios
    .post(`${HS_FORMS_API}/${HUB_SPOT_ID}/${HS_FORM_ID}`, {
      fields,
      context: {
        hutk: getCookie('hubspotutk'),
        pageUri: window.location.href,
        pageName: 'Soundful',
      },
    })
    .catch(noop);
};

let embeddedFormsInitPromise: Promise<void> | null = null;

const initializeEmbeddedForms = () => {
  if (embeddedFormsInitPromise) {
    return embeddedFormsInitPromise;
  }

  const script = document.createElement('script');
  script.src = '//js.hsforms.net/forms/embed/v2.js';
  script.type = 'text/javascript';
  document.body.appendChild(script);

  embeddedFormsInitPromise = new Promise((resolve, reject) => {
    script.onload = () => resolve();
    script.onerror = reject;
  });

  return embeddedFormsInitPromise;
};

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: (options: { region: string; portalId: string; formId: string }) => void;
      };
    };
  }
}
export const openCopyrightBundleRequestForm = async () => {
  await initializeEmbeddedForms();

  window.hbspt?.forms.create({
    region: 'na1',
    portalId: HUB_SPOT_ID,
    formId: '3911f771-3c29-4a5f-b4db-08345d0f864f',
  });
};
