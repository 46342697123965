import { useCallback } from 'react';

import { useSWRConfig } from 'swr';

import { sleep } from '@/utils/functionalUtils';

export const useMutateWithRevalidation = () => {
  const { cache, mutate } = useSWRConfig();

  return useCallback(
    <T>(matcher: RegExp, areEqual: (arg1: T, arg2: T) => boolean, ...args: unknown[]) => {
      if (!(cache instanceof Map)) {
        throw new Error('matchMutate requires the cache provider to be a Map instance');
      }

      const keys = [];

      for (const key of cache.keys()) {
        if (matcher.test(key) && !/^\$(req|err|swr)\$/.test(key)) {
          keys.push(key);
        }
      }

      const key = keys[0];

      if (!key) {
        return Promise.resolve();
      }

      const initialData = cache.get(key);

      let attemptsCount = 10;

      const execute = async (): ReturnType<typeof mutate> => {
        if (attemptsCount === 0) {
          return;
        }

        attemptsCount -= 1;

        const newData = await mutate(key, ...args);

        if (!areEqual(newData, initialData)) {
          return;
        }
        await sleep(500);

        await execute();
      };

      return execute();
    },
    [cache, mutate],
  );
};
