import { createAsyncActions } from '@/store/async/async.actions';

export const getGenresAction = createAsyncActions('get_genres');

export const getMoodsAction = createAsyncActions('get_moods');

export const getArtistsAction = createAsyncActions('get_artists');

export const getTagsAction = createAsyncActions('get_tags');

export const getSearchedDataAction = createAsyncActions('get_searched_data');

export const getTemplatesAction = createAsyncActions('get_templates');

export const getSearchedTemplatesAction = createAsyncActions('get_searched_templates');

export const getSearchedTracksAction = createAsyncActions('get_searched_tracks');

export const getSearchedTagsAction = createAsyncActions('get_searched_tags');
