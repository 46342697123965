import { makeMutator } from '@/utils/hookUtils';

import type { ISearchTemplates, ITemplate } from '@/types/template';

import { useMatchMutate } from '../useMatchMutate';

const CACHE_MATCHERS = {
  useTemplates: /templates/,
  favoritesPage: /templates\/favorites/,
  search: /search\/templates/,
};

export const useToggleFavoriteTemplateMutator = () => {
  const matchMutate = useMatchMutate();

  return async (template: ITemplate, favorite: boolean) => {
    const toggleFav = (cache: ITemplate[]) =>
      cache.map((element) => (element.id === template.id ? { ...element, favorite } : element));

    const insertFav = (cached: ITemplate[]) => {
      const deduplicated = cached.filter((element) => element.id !== template.id);

      return favorite ? [...deduplicated, { ...template, favorite }] : deduplicated;
    };

    const searchMutator = (cache?: ISearchTemplates[]) =>
      cache?.map((element) => ({ ...element, templates: toggleFav(element.templates) }));

    await Promise.all([
      matchMutate(CACHE_MATCHERS.favoritesPage, makeMutator('data', insertFav), false),
      matchMutate(CACHE_MATCHERS.useTemplates, makeMutator('data', toggleFav), false),
      matchMutate(CACHE_MATCHERS.search, searchMutator, false),
    ]);
  };
};
