import { mutate, unstable_serialize as serialize } from 'swr';

import type { TUserData } from '@/types/profile';

import { useFetch } from '../useFetch';

const CACHE_KEY = serialize(['?sections=NOTIFICATION', 'profileService', false, undefined]);

export const useNotificationPreferences = () => {
  const { data, error } = useFetch<TUserData>('?sections=NOTIFICATION', 'profileService');

  return { notifications: data?.notifications, isLoading: !data && !error };
};

export const mutateNotificationPreferences = (key: string, enabled: boolean) => {
  const mutator = (cached?: TUserData) => {
    if (cached?.notifications) {
      const notifications = cached.notifications.map((notification) =>
        notification.key === key ? { key, enabled } : notification,
      );

      return { ...cached, notifications };
    }

    return undefined;
  };

  mutate(CACHE_KEY, mutator, false);
};
