export function createFromEntries() {
  if ('fromEntries' in Object) {
    return (entries) => Object.fromEntries(entries);
  }

  return (entries) => {
    const result = {};

    for (const [key, value] of entries) {
      result[key] = value;
    }

    return result;
  };
}
