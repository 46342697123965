import { CDN_URL, CDN_PROXY_URL } from '@/api/urls';

export const getImagePath = (src: string | undefined) => {
  return !src || src.startsWith('http') ? src : `${CDN_URL}${src}`;
};

export const encodePathComponents = (path: string): string =>
  path.split('/').map(window.encodeURIComponent).join('/');

export const getCdnResourcePath = (src: string) => {
  const path = encodePathComponents(src);

  return !src || src.startsWith('public') ? path : `${CDN_URL}${path}`;
};

export const joinPath = (base: string, path: string) => {
  const basePart = base.endsWith('/') ? base.slice(0, -1) : base;
  const pathPart = path.startsWith('/') ? path.slice(1) : path;

  return `${basePart}/${pathPart}`;
};

export const getCdnProxiedResourcePath = (src: string) => {
  if (!src) {
    return '';
  }

  const path = encodePathComponents(src);

  return src.startsWith('public') ? path : joinPath(CDN_PROXY_URL, path);
};
