import { shuffleDeterministically } from '@/utils/functionalUtils';

import { type ITrack } from '@/types/trackListView';

const getCardId = (cardType: string, track: ITrack) => {
  switch (cardType) {
    case 'track':
      return track.id;
    case 'genre':
      return track.genre?.rootKey;
    case 'template':
      return track.template?.name;
    default:
      return track.id;
  }
};

export const getCardName = (activeCard: string, track: ITrack) => {
  const cardType = activeCard.split('-')[0] || 'track';
  const cardId = getCardId(cardType, track);

  return `${cardType}-${cardId}`;
};

export const getLocalRecentTrack = () => {
  const userId = localStorage.getItem('userId');

  if (!userId) {
    return null;
  }

  const localRecentTrack = localStorage.getItem(`recentTrack_${userId}`);

  if (!localRecentTrack) {
    return null;
  }

  return JSON.parse(localRecentTrack);
};

export const getNextTrack = (trackIndex: number, trackList: ITrack[]) => {
  const maxIndex = trackList.length - 1;

  const loop = localStorage.getItem('loop');

  if (loop === 'track') {
    return null;
  }

  const shuffleSeed = localStorage.getItem('shuffleSeed');
  const shuffled = shuffleSeed ? shuffleDeterministically(shuffleSeed, trackList.length) : [];
  const currentIndex = shuffleSeed ? shuffled.indexOf(trackIndex) : trackIndex;

  if (currentIndex === maxIndex) {
    return null;
  }

  const nextIndex = shuffleSeed ? shuffled[currentIndex + 1] : currentIndex + 1;

  return trackList[nextIndex];
};
