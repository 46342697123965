import dayjs, { type ManipulateType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { DATE_FORMAT, TIMESTAMP_FORMAT } from '@/config/time';
import { PaymentForm } from '@/enums/profile';

dayjs.extend(advancedFormat);

export const getFriendlyDate = (dateISO: string) => {
  return dayjs(dateISO).format(DATE_FORMAT);
};

export const getRenewsStandardPlan = (dateISO: string) => {
  const date = dayjs(dateISO).add(1, 'second');

  return date.format(DATE_FORMAT);
};

export const getRenewsPaymentDate = (paymentFormType: PaymentForm) => {
  const date = dayjs().add(1, paymentFormType === PaymentForm.Annual ? 'year' : 'month');

  return date.format(DATE_FORMAT);
};

export const generateTimestamp = () => dayjs().format(TIMESTAMP_FORMAT);

export const getDiffInHours = (startDate: number, endDate?: number) => {
  return dayjs(startDate).diff(dayjs(endDate), 'hours');
};

export const getFutureDate = (diff: number, type: ManipulateType) => {
  return dayjs().add(diff, type).toString();
};
