export const SYSTEM_ROUTES_SUBFOLDER = 'system' as const;

export const SYSTEM_ROUTES = {
  root: '/system',
  passwordReset: '/system/password-reset/:encryptedPath',
  passwordResetRoutes: {
    linkSent: '/system/password-reset/link-sent',
  },
  emailVerification: '/system/email-verification',
  referralSignUp: '/system/referral-sign-up/:encryptedPath',
  socialSignIn: '/system/oauth2',
  purchase: '/system/purchase',
  soundCloudAuthorizeConnect: '/system/soundcloud/authorize-connect',
  soundCloudConnect: '/system/soundcloud/connect',
};
