import { atomWithStorage, type RESET } from 'jotai/utils';
import type { WritableAtom } from 'jotai/vanilla';

export const atomWithStorageInit = <Value>(
  key: string,
  initialValue: Value,
): WritableAtom<Value, [SetStateActionWithReset<Value>], void> =>
  atomWithStorage(key, initialValue, undefined, { getOnInit: true });

type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);
