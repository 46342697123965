import { type InternalAxiosRequestConfig, AxiosHeaders } from 'axios';

import { getValidAccessToken } from '@/utils/refreshTokenUtils';
import { getLocallyStoredToken } from '@/utils/tokenUtils';

export const gatewayInterceptor = async <P>(
  config: InternalAxiosRequestConfig<P>,
): Promise<InternalAxiosRequestConfig> => {
  const headers = new AxiosHeaders(config.headers);

  const token = getLocallyStoredToken();

  if (token) {
    headers.set('authorizationToken', await getValidAccessToken(token));
  }

  config.headers = headers;

  return config;
};
