import { type TAction } from '@/types/common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyArgs = any[];

type ToArguments<Arg> = Arg extends never ? [] : [Arg];

type ToAction<Args extends AnyArgs> = (...args: Args) => void;

export type TEventEmitterRaise<
  TEventArg,
  TEventArgs extends ToArguments<TEventArg> = ToArguments<TEventArg>,
> = ToAction<TEventArgs>;

export type TEventEmitterListener<
  TEventArg,
  TEventArgs extends ToArguments<TEventArg> = ToArguments<TEventArg>,
> = ToAction<TEventArgs>;

export type TEventEmitterSubscribe<
  TEventArg,
  TEventArgs extends ToArguments<TEventArg> = ToArguments<TEventArg>,
> = (callback: TEventEmitterListener<TEventArg, TEventArgs>) => TAction;

export interface IEventEmitter<
  TEventArg,
  TEventArgs extends ToArguments<TEventArg> = ToArguments<TEventArg>,
> extends IEventEmitterProducer<TEventArg, TEventArgs>,
    IEventEmitterConsumer<TEventArg, TEventArgs> {}

export interface IEventEmitterProducer<
  TEventArg,
  TEventArgs extends ToArguments<TEventArg> = ToArguments<TEventArg>,
> {
  raise: TEventEmitterRaise<TEventArg, TEventArgs>;
}

export interface IEventEmitterConsumer<
  TEventArg,
  TEventArgs extends ToArguments<TEventArg> = ToArguments<TEventArg>,
> {
  subscribe: TEventEmitterSubscribe<TEventArg, TEventArgs>;
}

export const createEventEmitter = <
  TEventArg = void,
  TEventArgs extends ToArguments<TEventArg> = ToArguments<TEventArg>,
>(): IEventEmitter<TEventArg, TEventArgs> => {
  const listeners: ToAction<TEventArgs>[] = [];

  const subscribe = (callback: ToAction<TEventArgs>): TAction => {
    listeners.push(callback);

    // unsubscribe
    return () => {
      const index = listeners.findIndex((value) => value === callback);

      if (index >= 0) {
        listeners.splice(index, 1);
      }
    };
  };

  const raise: ToAction<TEventArgs> = (...args): void => {
    listeners.slice().forEach((listener) => {
      try {
        listener(...args);
      } catch (e) {
        // Make sure that all listeners are called even if any of them threw error;
      }
    });
  };

  return {
    subscribe,
    raise,
  };
};
