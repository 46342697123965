import { useRef, useMemo, useEffect } from 'react';

import { type AxiosRequestConfig } from 'axios';
import { t } from 'i18next';

import { useAuth } from '@/contexts/AuthProvider';
import { getPublicTrackRelativePath } from '@/hooks/sharedTrack/getPublicTrackRelativePath';
import type { IUseSharedTrackData } from '@/hooks/sharedTrack/types';
import { useFetch } from '@/hooks/useFetch';

import { noop } from '@/utils/functionalUtils';
import { toastError } from '@/utils/notificationUtils';

import { type Nullable } from '@/types/common';

export const SHARED_TRACK_MATCHER = /\/tracks\/(?!(favorites|purchases))/;

type TRouteParams = {
  genreKey?: string;
  templateKey?: string;
};

export const createRequestConfig = ({
  genreKey,
  templateKey,
}: TRouteParams): Partial<AxiosRequestConfig> => ({
  transformResponse: [
    (responseData: string) => {
      try {
        return JSON.parse(responseData);
      } catch (e) {
        return responseData;
      }
    },
    (parsedData) => {
      if (!parsedData.track || !genreKey || !templateKey) {
        return parsedData;
      }

      const publicUrl = getPublicTrackRelativePath(parsedData.track);

      if (publicUrl && !window.location.pathname.startsWith(publicUrl)) {
        toastError(t('notFound.trackNotFound'));

        throw new Error('Track is not found');
      }

      return parsedData;
    },
  ],
});

export const useSharedTrack = (trackId: string, genreKey?: string, templateKey?: string) => {
  const { isAuthenticated } = useAuth();
  const lastSaveData = useRef<Nullable<IUseSharedTrackData>>(null);

  const url = isAuthenticated ? `/tracks/${trackId}/sections?card=sharing` : `/tracks/${trackId}`;
  const service = isAuthenticated ? 'studioService' : 'publicStudioService';

  const routeParamsRef = useRef<TRouteParams>({ genreKey, templateKey });

  if (
    routeParamsRef.current.genreKey !== genreKey ||
    routeParamsRef.current.templateKey !== templateKey
  ) {
    routeParamsRef.current = { genreKey, templateKey };
  }

  // Keep the config memoized, otherwise SWR enters infinite loop:
  // It makes a new request on each render which leads to a new render, etc.
  const requestConfig = useMemo(() => createRequestConfig(routeParamsRef.current), []);

  const { data, error, mutate } = useFetch<IUseSharedTrackData>(
    url,
    service,
    { revalidateOnReconnect: false },
    false,
    requestConfig,
  );

  // Sometimes waveform is not available on the first page load. In this case, revalidate data until it appears
  useEffect(() => {
    if (data?.track && !data.track.waveForm) {
      let timeoutId: number | null = window.setTimeout(() => {
        mutate();
        timeoutId = null;
      }, 3000);

      return () => {
        if (timeoutId) {
          window.clearTimeout(timeoutId);
        }
      };
    }

    return noop;
  }, [data, mutate]);

  // Make sure only track is updated, but not recommended tracks
  const sharedTrackData = useMemo((): IUseSharedTrackData | undefined => {
    if (lastSaveData.current && data) {
      return {
        ...lastSaveData.current,
        track: data.track,
        metrics: data.metrics,
      };
    }

    return data;
  }, [data]);

  if (sharedTrackData) {
    lastSaveData.current = sharedTrackData;
  }

  return { sharedTrackData: lastSaveData.current, error };
};
