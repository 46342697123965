import type { TRequest } from '@/types/common';
import {
  type TGenresResponse,
  type TMoodsArtistsResponse,
  type TMoodsOrArtists,
  type TSearchValue,
  type TTagResponse,
  type TTemplatesResponse,
  type TTrackAndTemplatesResponse,
  type TTracksResponse,
} from '@/types/search';

import { studioService } from '@/api/private';

const createQuery = (search?: string) => {
  const query = new URLSearchParams();

  if (search) {
    query.set('q', search.trim());
  }

  return query;
};

export const getMoodsOrArtists: TRequest<TMoodsArtistsResponse, TMoodsOrArtists> = ({
  search,
  category,
}) => {
  const query = createQuery(search);

  query.set('category', category);

  return studioService.get(`/search/tags/lookup?${query.toString()}`);
};

export const getGenres: TRequest<TGenresResponse, TSearchValue> = ({ search }) => {
  const query = createQuery(search);

  return studioService.get(`/search/genres/lookup?${query.toString()}`);
};

export const getTags: TRequest<TTagResponse, TSearchValue> = ({ search }) => {
  const query = createQuery(search);

  return studioService.get(`/search/tags/lookup?${query.toString()}`);
};

export const getSearchedDataByText: TRequest<
  TTrackAndTemplatesResponse,
  {
    keyId: string;
    id: string;
    searchText?: string;
  }
> = ({ keyId, searchText, id }) => {
  const query = createQuery(searchText);

  return studioService.get(`/search/all/filter?${query.toString()}`, {
    params: {
      criteria: JSON.stringify([{ key: keyId, function: 'eq', value: id }]),
    },
  });
};

export const getSearchedAllTemplatesAndTracks: TRequest<
  TTrackAndTemplatesResponse,
  TSearchValue
> = ({ search }) => {
  const query = createQuery(search);

  return studioService.get(`/search/all/filter?${query.toString()}`);
};

export const getSearchedTemplates: TRequest<
  TTemplatesResponse,
  {
    searchText: string;
    pageSize: number;
  }
> = ({ searchText, pageSize }) => {
  const query = createQuery(searchText);

  query.set('pageSize', String(pageSize));

  return studioService.get(`/search/templates/filter?${query.toString()}`);
};

export const getSearchedTracks: TRequest<
  TTracksResponse,
  {
    searchText: string;
    trackType?: string;
  }
> = ({ searchText, trackType }) => {
  const query = createQuery(searchText);

  return studioService.get(`/search/tracks/filter?${query.toString()}`, {
    params: {
      criteria: JSON.stringify([{ key: 'trackType', function: 'eq', value: trackType }]),
    },
  });
};
