import { PlanKeys } from '@/enums/profile';

import type { TStore } from '../types';

export const selectSubscriptionInfo = (state: TStore) => state.subscription.data;

export const selectIsSubscriptionInfoLoading = (state: TStore) => state.subscription.isLoading;

export const selectSubscriptionInfoError = (state: TStore) => state.subscription.error;

export const selectCurrentPlanTitle = (state: TStore) => state.subscription.data.currentPlan?.title;

export const selectIsPremiumPlan = (state: TStore) =>
  state.subscription.data.currentPlan?.title !== PlanKeys.standard;

export const selectIsStandardPlan = (state: TStore) =>
  state.subscription.data.currentPlan?.title === PlanKeys.standard;

export const selectFuturePlanTitle = (state: TStore) => state.subscription.data.nextPlan?.title;
