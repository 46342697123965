import type { AxiosRequestConfig, AxiosError } from 'axios';

import { type TErrorDetails, CommonError } from '@/utils/errors';
import { getStackTrace } from '@/utils/logging/getStackTrace';

import type { TErrorProps } from './types';

export const getPropsFromError = (error: Error): TErrorProps => ({
  message: error.message,
  name: error.name,
  stack: error.stack || getStackTrace(),
  ...(error instanceof CommonError
    ? {
        details: error.details,
        innerException:
          error.innerError && error.innerError !== error
            ? getPropsFromError(error.innerError)
            : undefined,
      }
    : null),
});

export const getDetailsFromAxiosRequestConfig = <P>(
  config: AxiosRequestConfig<P> | undefined,
): TErrorDetails =>
  config
    ? {
        correlation_id: config.headers?.correlation_id,
        url: combinePath(config.baseURL, config.url),
        method: config.method,
      }
    : {};

function combinePath(left = '', right = ''): string {
  const leftPart = left.endsWith('/') ? left.slice(0, -1) : left;
  const rightPart = right.startsWith('/') ? right : `/${right}`;

  return `${leftPart}${rightPart}`;
}

export const getDetailsFromAxiosError = (error: AxiosError): TErrorDetails => ({
  response: {
    status: error?.response?.status,
    statusText: error?.response?.statusText,
    data: error?.response?.data,
    errorStatus: error.status,
    errorCode: error.code,
    // This header may be absent in case of CORS requests (if special header is not set on server)
    requestId: error.response?.headers?.['x-amzn-requestid'],
  },
  request: getDetailsFromAxiosRequestConfig(error.config),
});
