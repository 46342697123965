import type { Nullable, Dict } from '@/types/common';

import type { ILogEvent, ILogTransform } from '../types';

export const syncCorrelationId: ILogTransform = {
  transformBeforeSend(event) {
    const correlationId = getCorrelationIdFromDetails(event);

    if (correlationId) {
      return {
        ...event,
        correlation_id: correlationId,
      };
    }

    return event;
  },
};

function getCorrelationIdFromDetails(event: ILogEvent): Nullable<string> {
  const { details } = event;

  if (!details || typeof details !== 'object') {
    return null;
  }

  const { request } = details as Dict<unknown>;

  if (!request || typeof request !== 'object') {
    return null;
  }

  const correlationId = (request as Dict<unknown>).correlation_id;

  return typeof correlationId === 'string' ? correlationId : null;
}
