import { useCallback } from 'react';

import { useSWRConfig } from 'swr';

export const useMatchMutate = () => {
  const { cache, mutate } = useSWRConfig();

  return useCallback(
    (matcher: RegExp, ...args: unknown[]) => {
      if (!(cache instanceof Map)) {
        throw new Error('matchMutate requires the cache provider to be a Map instance');
      }

      const keys = [];

      for (const key of cache.keys()) {
        if (matcher.test(key) && !/^\$(req|err|swr)\$/.test(key)) {
          keys.push(key);
        }
      }

      const mutations = keys.map((key) => mutate(key, ...args));

      return Promise.all(mutations);
    },
    [cache, mutate],
  );
};
