import i18n from 'i18next';

import type { Nullable } from '@/types/common';
import { type TTranslateKey } from '@/types/i18n';

import type { TErrorDetails } from './types';

export class CommonError extends Error {
  constructor(
    messageKey: TTranslateKey | string = 'errors.unknown',
    public readonly details: Nullable<TErrorDetails> = null,
    public readonly innerError: Nullable<Error> = null,
  ) {
    super(i18n.exists(messageKey) ? i18n.t(messageKey as TTranslateKey) : messageKey);

    this.name = 'CommonError';
  }
}
