class SyntheticStackTraceProvider extends Error {
  constructor() {
    super();

    this.name = 'SyntheticStackTrace';
  }
}

export const getStackTrace = () => {
  const err = new SyntheticStackTraceProvider();

  return err.stack;
};
