import { createReducer } from 'deox';

import type { Nullable } from '@/types/common';
import type { TPaymentMethod } from '@/types/paymentMethod';
import type { TPurchaseHistory } from '@/types/purchase';

import { handleAsyncReducers } from '../async/async.reducers';
import { getAsyncActionList } from '../async/helpers';

import {
  cleanPayment,
  getPaymentMethodsAction,
  createPaymentMethodAction,
  deletePaymentMethodAction,
  fetchPurchaseHistoryAction,
  updateBundleSubscriptionAction,
  cancelSubscriptionAction,
  cancelFutureSubscriptionAction,
} from './payment.actions';
import { initialState } from './payment.initialState';

export const reducer = createReducer(initialState, (handleAction) => [
  handleAction(cleanPayment, () => ({
    ...initialState,
  })),

  handleAction(getAsyncActionList(getPaymentMethodsAction), (state, action) => ({
    ...state,
    paymentMethod: handleAsyncReducers<Nullable<TPaymentMethod[]>>(
      state.paymentMethod,
      initialState.paymentMethod.data,
      action,
      getPaymentMethodsAction,
    ),
  })),

  handleAction(getAsyncActionList(deletePaymentMethodAction), (state, action) => ({
    ...state,
    deletePaymentMethod: handleAsyncReducers<null>(
      state.deletePaymentMethod,
      initialState.deletePaymentMethod.data,
      action,
      deletePaymentMethodAction,
    ),
  })),

  handleAction(getAsyncActionList(fetchPurchaseHistoryAction), (state, action) => ({
    ...state,
    purchaseHistory: handleAsyncReducers<Nullable<TPurchaseHistory[]>>(
      state.purchaseHistory,
      null,
      action,
      fetchPurchaseHistoryAction,
    ),
  })),

  handleAction(getAsyncActionList(updateBundleSubscriptionAction), (state, action) => ({
    ...state,
    updatePurchase: handleAsyncReducers<Nullable<string>>(
      state.updatePurchase,
      initialState.updatePurchase.data,
      action,
      updateBundleSubscriptionAction,
    ),
  })),

  handleAction(getAsyncActionList(cancelSubscriptionAction), (state, action) => ({
    ...state,
    cancelSubscription: handleAsyncReducers<null>(
      state.cancelSubscription,
      initialState.cancelSubscription.data,
      action,
      cancelSubscriptionAction,
    ),
  })),

  handleAction(getAsyncActionList(cancelFutureSubscriptionAction), (state, action) => ({
    ...state,
    cancelFutureSubscription: handleAsyncReducers<null>(
      state.cancelFutureSubscription,
      initialState.cancelFutureSubscription.data,
      action,
      cancelFutureSubscriptionAction,
    ),
  })),

  handleAction(getAsyncActionList(createPaymentMethodAction), (state, action) => ({
    ...state,
    createPaymentMethod: handleAsyncReducers<null>(
      state.createPaymentMethod,
      initialState.createPaymentMethod.data,
      action,
      createPaymentMethodAction,
    ),
  })),
]);
