import { fromEntries } from '@/compatibility/object';

export const getQueryParam = (key: string, search: string) => {
  const params = new URLSearchParams(search);

  return params.get(key);
};

export const decodeBase64url = <PResult>(url: string): PResult => {
  const encodedUrl = window.atob(url);

  return JSON.parse(encodedUrl);
};

export const storeParametersOnLoad = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = fromEntries(urlParams.entries());
  localStorage.setItem('params', JSON.stringify(params));
};

// unused as for now:
// const getStoredParameter = (key: string) => {
//   const params = JSON.parse(localStorage.getItem('params') || '{}');
//   return params[key];
// };
