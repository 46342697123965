import { type ISharedTrackData } from '@/hooks/sharedTrack/types';

export const getPublicTrackRelativePath = (track: ISharedTrackData) => {
  if (!track.publicUrl) {
    return null;
  }

  const publicUrl = new URL(track.publicUrl);

  return `${publicUrl.pathname}${publicUrl.search}`;
};
