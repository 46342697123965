import { type Nullable } from '@/types/common';
import { type TReferral, type TUserDetails } from '@/types/profile';

import { type TStore } from '../types';

import { type TLinkValidityData } from './types';

export const selectUserEmail = (state: TStore): string | undefined =>
  state.profile.user.data?.email;

export const selectReferrals = (state: TStore): TReferral[] => state.profile.referrals.data;

export const selectUserData = (state: TStore): TUserDetails => state.profile.user.data;

export const selectUserDataLoaded = (state: TStore): boolean => state.profile.user.loaded;

export const selectSignUpLoading = (state: TStore): boolean =>
  state.profile.user.isLoading ||
  state.profile.signin.isLoading ||
  state.profile.referralSignUp.finish.isLoading;

export const selectSignUpError = (state: TStore): Nullable<string> => state.profile.user.error;

export const selectSignInError = (state: TStore): Nullable<string> => state.profile.signin.error;

export const selectIsSignInSuccess = (state: TStore): boolean =>
  !state.profile.signin.isLoading && state.profile.signin.loaded && !state.profile.signin.error;

export const selectIsJustSignedUp = (state: TStore): boolean =>
  state.profile.signin.data.justSignedUp || false;

export const selectSendResetLinkStatus = (state: TStore) => state.profile.passwordReset.resetInit;

export const selectSendResetLinkLoading = (state: TStore): boolean =>
  state.profile.passwordReset.resetInit.isLoading;

export const selectSendResetLinkText = (state: TStore): string => {
  const { loaded, data, error } = state.profile.passwordReset.resetInit;

  if (loaded) {
    if (data) {
      return data;
    }

    if (error) {
      return error;
    }
  }

  return '';
};

export const selectIsPasswordLinkValidLoading = (state: TStore): boolean =>
  state.profile.passwordReset.linkValidity.isLoading;

export const selectUserDataFromValidityCheck = (state: TStore): TLinkValidityData =>
  state.profile.passwordReset.linkValidity.data;

export const selectEmailFromValidityError = (state: TStore): Nullable<string> =>
  state.profile.passwordReset?.linkValidity?.error;

export const selectIsPasswordResetSuccess = (state: TStore): boolean =>
  !state.profile.passwordReset.resetFinish.isLoading &&
  state.profile.passwordReset.resetFinish.loaded &&
  !state.profile.passwordReset.resetFinish.error;

export const selectIsUserDataLoading = (state: TStore): boolean => state.profile?.user?.isLoading;

export const selectIsEmailVerified = (state: TStore): boolean =>
  Boolean(state.profile?.user?.data?.isEmailVerified);

export const selectIsHeaderNotificationVisible = (state: TStore): boolean =>
  !state.profile?.user?.data?.isEmailVerified && !!state.profile.user.data?.email;

export const selectIsConfirmEmailLinkLoading = (state: TStore): boolean =>
  state.profile?.emailVerification?.isLoading;

export const selectIsUserInfoLoadedSuccess = (state: TStore): boolean =>
  !state.profile.user.isLoading && state.profile.user.loaded && !state.profile.user.error;

export const selectUserDataError = (state: TStore) => state.profile.user.error;

export const selectErrorMessageConfirmEmail = (state: TStore): Nullable<string> =>
  state.profile?.emailVerification?.error;

export const selectIsPasswordResetInitiated = (state: TStore): boolean => {
  const { isLoading, loaded, data } = state.profile.passwordReset.resetInit;

  return !isLoading && loaded && !!data;
};
export const selectInvitationEmail = (state: TStore): Nullable<string> =>
  state.profile?.referralSignUp?.init?.data;

export const selectCustomerId = (state: TStore): string => state.profile.user.data.customerId;

export const selectShowTemplatesBanner = (state: TStore): boolean =>
  state.profile?.user?.data?.education?.displayPersonalizedTemplates;
