import { deriveBrowserVersion } from '../deriveBrowserVersion';

import { safariFeatures } from './safariFeatures';

export const deriveSafariBrowserVersion = () => deriveBrowserVersion(safariFeatures);

export const isSafari = Boolean(
  navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1,
);
