import type { EmptyObject, TObject } from '@/types/common';

export const convertObjectValues = <T extends EmptyObject>(
  obj: T,
  valueToConvert: keyof T | null,
  convertedValue: keyof T,
) => {
  return Object.keys(obj).reduce(
    (acc, key: string) => {
      acc[key] = obj[key] === valueToConvert ? convertedValue : obj[key];

      return acc;
    },
    {} as { [key: string]: keyof T },
  );
};

export const isEqual = <T>(a: T, b: T) => JSON.stringify(a) === JSON.stringify(b);

export const forKeys = <T extends TObject>(
  model: T,
  callback: (key: keyof T, value: T[keyof T]) => void,
): void => {
  Object.keys(model).forEach((objKey) => {
    const key = objKey as keyof T;

    callback(key, model[key]);
  });
};
