import type { AxiosInstance } from 'axios';

import { isResponseError } from '@/utils/errorUtils';

type ErrorInterceptor = (error: unknown) => void;

export const createRefreshTokenInterceptor = (
  axiosInstance: AxiosInstance,
  refreshToken: () => Promise<void>,
): ErrorInterceptor => {
  return async (error) => {
    if (!isResponseError(error, 401)) {
      return Promise.reject(error);
    }

    try {
      await refreshToken();
    } catch (e) {
      return Promise.reject(e);
    }

    if (!error.config) {
      throw new Error('Axios config is not present');
    }

    return axiosInstance(error.config);
  };
};
