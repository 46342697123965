import { escapeHtml } from '@/utils/security/securityUtils';

export const highlightMatch = (value: string, query: string) => {
  if (!query) {
    return value;
  }

  try {
    const regex = new RegExp(query.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'); // escape special characters

    return escapeHtml(value).replace(regex, (str, ...args) => {
      const index = args[args.length - 2] as number;
      const source = args[args.length - 1] as string;

      let left = index;

      while (left >= 0 && !/[\s&]/.test(source[left])) {
        left -= 1;
      }

      let right = index;

      while (right < source.length && !/[\s;]/.test(source[right])) {
        right += 1;
      }

      if (source[left] === '&' && source[right] === ';') {
        return str;
      }

      return `<strong>${str}</strong>`;
    });
  } catch (error) {
    return value;
  }
};

export const encodeUri = (object: unknown) => {
  const queryJson = JSON.stringify(object);

  return encodeURIComponent(queryJson);
};

export const getInitials = (name = '') => {
  return name
    .split(/[\s_-]/g)
    .slice(0, 2)
    .map((text) => text[0])
    .join('')
    .toUpperCase();
};
