export const AUTH_POPUP_NAME = 'Soundful Auth';

export const LOCAL = window.env.env === 'local';
export const PRODUCTION = window.env.env === 'prod';

// navigator.maxTouchPoints() is not supported in iOS Safari 12.2-12.5
// eslint-disable-next-line compat/compat
export const IS_TOUCH_DEVICE = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

export const IS_AUTH_POPUP = window.opener && window.name === AUTH_POPUP_NAME;
