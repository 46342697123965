import { type ReactNode } from 'react';

import {
  Checkbox as MuiCheckbox,
  type CheckboxProps,
  FormControlLabel,
  type FormControlLabelProps,
  type SwitchProps,
} from '@mui/material';

interface ICheckboxProps {
  name?: string;
  label: ReactNode;
  checked?: SwitchProps['checked'];
  onChange?: SwitchProps['onChange'];
  disabled?: SwitchProps['disabled'];
  // other props for non-standard cases:
  checkboxProps?: Omit<CheckboxProps, 'checked' | 'onChange' | 'disabled'>;
  labelProps?: Omit<FormControlLabelProps, 'label' | 'control'>;
}

export const Checkbox = ({
  name,
  label,
  checked,
  onChange,
  disabled = false,
  checkboxProps,
  labelProps,
}: ICheckboxProps) => (
  <FormControlLabel
    {...labelProps}
    label={label}
    control={
      <MuiCheckbox
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        disableRipple
        {...checkboxProps}
      />
    }
  />
);
