export interface IChangeBpmOptions {
  file: string;
  defaultBpm: number;
  updatedBpm: number;
  library: string;
  pitch: number;
}

export enum ProfileSection {
  notifications = 'NOTIFICATION',
  metrics = 'METRICS',
  details = 'DETAILS',
  subscriptionInfo = 'SUBSCRIPTION',
}
