import type { Nullable } from '@/types/common';
import type { TPaymentMethod } from '@/types/paymentMethod';
import type { TPurchaseHistory } from '@/types/purchase';

import { initialAsyncState } from '../async/initialState';

import type { TPaymentStore } from './types';

// TODO: clean up, split purchases and payment methods
export const initialState: TPaymentStore = {
  paymentMethod: initialAsyncState<Nullable<TPaymentMethod[]>>(null),
  createPaymentMethod: initialAsyncState<null>(null),
  deletePaymentMethod: initialAsyncState<null>(null),
  purchaseHistory: initialAsyncState<Nullable<TPurchaseHistory[]>>(null),
  updatePurchase: initialAsyncState<Nullable<string>>(null),
  cancelSubscription: initialAsyncState<Nullable<null>>(null),
  cancelFutureSubscription: initialAsyncState<Nullable<null>>(null),
};
