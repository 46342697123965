export type TMainScales =
  | 'd_major'
  | 'c_sharp_major'
  | 'c_major'
  | 'b_major'
  | 'b_flat_major'
  | 'a_major'
  | 'a_flat_major'
  | 'g_major'
  | 'f_sharp_major'
  | 'f_major'
  | 'e_major'
  | 'e_flat_major';

export const majorScale = [
  { value: 'b_major', label: 'B Maj', fullName: 'B major', offset: -1 },
  { value: 'b_flat_major', label: 'Bb Maj', fullName: 'B-flat major', offset: -2 },
  { value: 'a_major', label: 'A Maj', fullName: 'A major', offset: -3 },
  { value: 'a_flat_major', label: 'Ab Maj', fullName: 'A-flat major', offset: -4 },
  { value: 'g_major', label: 'G Maj', fullName: 'G major', offset: -5 },
  { value: 'f_sharp_major', label: 'F# Maj', fullName: 'F-sharp major', offset: 6 },
  { value: 'f_major', label: 'F Maj', fullName: 'F major', offset: 5 },
  { value: 'e_major', label: 'E Maj', fullName: 'E major', offset: 4 },
  { value: 'e_flat_major', label: 'Eb Maj', fullName: 'E-flat major', offset: 3 },
  { value: 'd_major', label: 'D Maj', fullName: 'D major', offset: 2 },
  { value: 'c_sharp_major', label: 'C# Maj', fullName: 'C-sharp major', offset: 1 },
  { value: 'c_major', label: 'C Maj', fullName: 'C major', offset: 0 },
];

export const minorScale = [
  { value: 'b_minor', label: 'B Min', fullName: 'B minor', offset: 2 },
  { value: 'b_flat_minor', label: 'Bb Min', fullName: 'B-flat minor', offset: 1 },
  { value: 'a_minor', label: 'A Min', fullName: 'A minor', offset: 0 },
  { value: 'a_flat_minor', label: 'Ab Min', fullName: 'A-flat minor', offset: -1 },
  { value: 'g_minor', label: 'G Min', fullName: 'G minor', offset: -2 },
  { value: 'f_sharp_minor', label: 'F# Min', fullName: 'F-sharp minor', offset: -3 },
  { value: 'f_minor', label: 'F Min', fullName: 'F minor', offset: -4 },
  { value: 'e_minor', label: 'E Min', fullName: 'E minor', offset: -5 },
  { value: 'e_flat_minor', label: 'Eb Min', fullName: 'E-flat minor', offset: 6 },
  { value: 'd_minor', label: 'D Min', fullName: 'D minor', offset: 5 },
  { value: 'c_sharp_minor', label: 'C# Min', fullName: 'C-sharp minor', offset: 4 },
  { value: 'c_minor', label: 'C Min', fullName: 'C minor', offset: 3 },
];

export const majorScaleMap: Record<TMainScales, number> = {
  b_major: 0,
  b_flat_major: 1,
  a_major: 2,
  a_flat_major: 3,
  g_major: 4,
  f_sharp_major: 5,
  f_major: 6,
  e_major: 7,
  e_flat_major: 8,
  d_major: 9,
  c_sharp_major: 10,
  c_major: 11,
};

const tableOfCorrespondence: [mainScale: TMainScales, ...args: string[]][] = [
  ['d_major', 'b_minor'],
  ['c_sharp_major', 'b_flat_minor', 'd_flat_major', 'a_sharp_minor'],
  ['c_major', 'a_minor'],
  ['b_major', 'a_flat_minor', 'g_sharp_minor'],
  ['b_flat_major', 'g_minor', 'a_sharp_major'],
  ['a_major', 'f_sharp_minor', 'g_flat_minor'],
  ['a_flat_major', 'f_minor', 'g_sharp_major'],
  ['g_major', 'e_minor'],
  ['f_sharp_major', 'e_flat_minor', 'd_sharp_minor', 'g_flat_major'],
  ['f_major', 'd_minor'],
  ['e_major', 'c_sharp_minor', 'd_flat_minor'],
  ['e_flat_major', 'c_minor', 'd_sharp_major'],
];

const mapOfKeysCorrespondence = new Map<string, TMainScales>();

tableOfCorrespondence.forEach((row) => {
  const mainScale = row[0];

  row.forEach((scale) => {
    mapOfKeysCorrespondence.set(scale, mainScale);
  });
});

export { mapOfKeysCorrespondence };
