import { alpha } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { colors } from '../colors';

import { breakpoints } from './breakpoints';
import { commonSettings, toAutofillStyles } from './common';
import { typography } from './typography';

export const lightTheme = createTheme(
  {
    typography,
    breakpoints,
    palette: {
      mode: 'light',
      primary: {
        main: colors.primaryRed, // highlighted elements
      },
      secondary: {
        main: colors.white, // sections background
        light: colors.white, // active elements background
        dark: colors.whiteSmoke, // searchBar background
      },
      divider: colors.platinumGray, // borders
      text: {
        primary: colors.almostBlack, // main or active text
        secondary: colors.darkGray, // inactive elements text
        disabled: colors.silverGray, // disabled elements text
      },
      action: {
        hover: colors.solitude,
      },
      success: {
        main: colors.green,
      },
      error: {
        main: colors.pinkRed,
      },
    },
    custom: {
      background: colors.whiteSmoke,
      backgroundGradient: `linear-gradient(225deg, ${colors.whiteSmoke} 0%, ${colors.white} 100%)`,
      outline: colors.silverGray,
      backgroundSecondary: colors.eastBay,
      backgroundDeep: colors.whiteSmoke,
      inactive: colors.echoBlue,
      paper: colors.lightGrey,
      disabledBackground: colors.white,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: colors.whiteSmoke,
            backgroundImage: `linear-gradient(225deg, ${colors.whiteSmoke} 0%, ${colors.white} 100%)`,
            backgroundAttachment: 'fixed',
          },
          '*::-webkit-scrollbar': {
            backgroundColor: alpha(colors.solitude, 0.8),
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: alpha(colors.echoBlue, 0.8),
          },
          '*::-webkit-scrollbar-thumb:hover': {
            background: colors.darkGray,
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: colors.whiteSmoke,
          },

          notchedOutline: {
            borderColor: colors.silverGray,
          },

          input: {
            '&:-webkit-autofill': toAutofillStyles(colors.whiteSmoke, colors.almostBlack),
            '&.Mui-disabled:-webkit-autofill': toAutofillStyles(colors.white, colors.silverGray),
          },
        },
      },

      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary',
        },
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing('13px', 4),
            borderRadius: theme.spacing(2.5),
            fontWeight: 500,
            lineHeight: '14px',
            textTransform: 'uppercase',
            textDecoration: 'none',
          }),

          outlinedSecondary: {
            background: colors.whiteSmoke,
            border: `1px solid ${colors.echoBlue}`,
            color: colors.eastBay,
            '&:hover': {
              background: colors.solitude,
              border: `1px solid ${colors.echoBlue}`,
            },
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          outlined: {
            background: colors.whiteSmoke,
            border: `1px solid ${colors.echoBlue}`,
            color: colors.almostBlack,
          },
        },
      },

      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.MuiInputLabel-root.Mui-focused': {
              color: colors.paleBlack,
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              background: colors.white,
            },
            '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
              borderColor: colors.paleBlack,
            },
          },
        },
      },

      MuiInputAdornment: {
        styleOverrides: {
          root: {
            color: colors.silverGray,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '.MuiChip-deleteIcon.MuiChip-deleteIconSmall': {
              color: colors.mistGray,
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            paddingTop: 11,
          },
          track: {
            width: '36px',
            height: '16px',
            borderRadius: '8px',
          },
        },
      },
    },
  },
  commonSettings,
);
