import { AbortError } from '@/utils/errors';

import { type Dict } from '@/types/common';

export function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  wait: number,
) {
  let timer: number;

  const cancel = () => window.clearTimeout(timer);
  const result = (...args: T) => {
    cancel();

    return new Promise<U>((resolve) => {
      timer = window.setTimeout(() => resolve(callback(...args)), wait);
    });
  };

  result.cancel = cancel;

  return result;
}

export const minmax = (value: number, min: number, max: number) => {
  return Math.max(Math.min(value, max), min);
};

export const shuffle = <T>(array: T[], randomizer = Math.random) => {
  const result = [...array];

  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(randomizer() * (i + 1));

    [result[i], result[j]] = [result[j], result[i]];
  }

  return result;
};

/* eslint-disable */
// jsf pseudo-random number generator
export const prng = (seed: number) => {
  function jsf() {
    let e = s[0] - ((s[1] << 27) | (s[1] >> 5));
    s[0] = s[1] ^ ((s[2] << 17) | (s[2] >> 15));
    s[1] = s[2] + s[3];
    s[2] = s[3] + e;
    s[3] = s[0] + e;
    return (s[3] >>> 0) / 4294967295; // 2^32-1
  }

  seed >>>= 0;
  const s = [0xf1ea5eed, seed, seed, seed];
  for (let i = 0; i < 20; i++) jsf();
  return jsf;
};
/* eslint-enable */

export const shuffleDeterministically = (seed: string, length: number) => {
  const randomizer = prng(Number(seed));
  const ordered = Array.from({ length }, (_, i) => i);

  return shuffle(ordered, randomizer);
};

const getVariatorPattern = (length: number) => {
  if (length === 2) {
    return [0, 1];
  }

  if (length === 3) {
    return [0, 1, 2, 1, 0, 2];
  }

  const variants = Array.from({ length }, (_, i) => i);
  const patternMinLength = 12;
  let pattern = [...variants];

  while (pattern.length < patternMinLength || pattern[0] === pattern[pattern.length - 1]) {
    const last = pattern[pattern.length - 1];
    let shuffled;

    for (let i = 0; !shuffled || shuffled[0] === last; i++) {
      shuffled = shuffle(variants, prng(i));
    }
    pattern = pattern.concat(shuffled);
  }

  return pattern;
};

type Variator = (note: string) => number;

export const createVariator = (length: number): Variator => {
  if (length < 2) {
    return () => 0;
  }

  const pattern = getVariatorPattern(length);
  const iterations: Dict<number> = {};

  return (note: string) => {
    if (iterations[note] === undefined) {
      iterations[note] = 0;
    }

    const iteration = iterations[note];
    iterations[note] += 1;

    return pattern[iteration % pattern.length];
  };
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const sleepWithSignal = (ms: number, signal: AbortSignal) =>
  new Promise<void>((resolve, reject) => {
    let timeoutId: number | null = window.setTimeout(() => {
      timeoutId = null;
      signal.removeEventListener('abort', abortListener);
      resolve();
    }, ms);

    signal.addEventListener('abort', abortListener, { once: true });

    function abortListener() {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }

      reject(new AbortError());
    }
  });

export const identity = <T>(value: T) => value;

export const noop: <PArgs extends unknown[]>(...args: PArgs) => void = () => {};

export const isNonNullable = <T>(item: T): item is NonNullable<T> => !!item;

export const interpolateLinear = (value1: number, value2: number, factor: number) => {
  if (factor < 0 || factor > 1) {
    throw new Error('Factor must be between 0 and 1');
  }

  const result = value1 + (value2 - value1) * factor;

  return Math.round(result);
};

type TChain<PArgs extends unknown[]> = (...args: PArgs) => Generator<Promise<unknown>>;
type TResult<PArgs extends unknown[]> = (...args: PArgs) => void;

/**
 * Creates a "debounced" chain of async actions. The actions run sequentially.
 * If called before the previous run's finished - it halts and the chain starts from the beginning.
 *
 * @param chain {Generator} - a generator which yields all async actions
 *
 * @example
 * const chain = asyncChain(function* chainGen() {
 *   console.info('initialization');
 *
 *   yield sleep(1000);
 *   console.info('first action finished');
 *
 *   yield sleep(1000);
 *   console.info('chain is complete');
 * });
 *
 * chain();
 * // => 'initialization';
 *
 * await sleep(1500);
 * // => 'first action finished';
 *
 * chain();
 * // stops previous run;
 * // => 'initialization';
 * // => 'first action finished';
 * // => 'chain is complete';
 */
export const debounceChain = <PArgs extends unknown[]>(chain: TChain<PArgs>): TResult<PArgs> => {
  let run = { cancelled: false };

  return async (...args: PArgs) => {
    const thisRun = { cancelled: false };

    run.cancelled = true;
    run = thisRun;

    for (const step of chain(...args)) {
      await step;

      if (thisRun.cancelled) {
        break;
      }
    }
  };
};

export const throttle = (
  callback: (...args: unknown[]) => void,
  wait: number,
  leadingEdge = false,
) => {
  let timer: number | null = null;
  let nonLeadingEdgeCalled = false;

  return (...args: unknown[]) => {
    if (timer) {
      nonLeadingEdgeCalled = true;

      return;
    }

    if (leadingEdge) {
      callback(...args);
    }

    timer = window.setTimeout(() => {
      if (!leadingEdge || nonLeadingEdgeCalled) {
        callback(...args);
      }

      timer = null;
      nonLeadingEdgeCalled = false;
    }, wait);
  };
};

export const exhaustiveCheck = (value: never) => {
  throw new Error(`Unhandled value: ${value}`);
};
