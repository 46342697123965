import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';

import { AuthProvider } from '@/contexts/AuthProvider';
import { ThemeProvider } from '@/contexts/ThemeContextProvider';

import { ErrorBoundary } from '@/components/errors/ErrorBoundary';
import { NotificationsProvider } from '@/shared/notifications/NotificationsProvider';
import { ScrollToTop } from '@/shared/scroll/ScrollToTop';

import { App } from './App';
import { LOCAL } from './constants';
import { store } from './store';
import './i18n';

if (!LOCAL && 'serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js');
  });
}

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider>
      <>
        <CssBaseline />
        <StoreProvider store={store}>
          <NotificationsProvider>
            <AuthProvider>
              <Router>
                <ErrorBoundary>
                  <ScrollToTop />
                  <App />
                </ErrorBoundary>
              </Router>
            </AuthProvider>
          </NotificationsProvider>
        </StoreProvider>
      </>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root'),
);

window.hash = process.env.GIT_COMMIT_HASH;
