import type { TReleasesStage } from './types';

export type TFeatures =
  | 'poc'
  | 'verboseEventsTracking'
  | 'configurableNewVersionSentinel'
  | 'loops'
  | 'reCaptchaDisabled'
  | 'copyright'
  | 'copyrightBundles'
  | 'waveform'
  | 'walletPayments'
  | 'templateCardNameSlotDisabled'
  | 'rakutenTracking'
  | 'mobileGlobalSearch'
  | 'soundcloudTrackUpload'
  | 'soundcloudAuth'
  | 'soundcloudDisconnect'
  | 'abletonRenderAndDownload'
  | 'text2track'
  | 'SF-9091-downloadFileNameFromUrl';

export const releaseStages: TReleasesStage<TFeatures> = {
  poc: 'development',
  verboseEventsTracking: 'uat',
  configurableNewVersionSentinel: 'uat',
  loops: 'production',
  // Treat reCaptcha as enabled by default on all envs
  reCaptchaDisabled: 'uat',
  copyright: 'off',
  copyrightBundles: 'off',
  waveform: 'production',
  walletPayments: 'production',
  templateCardNameSlotDisabled: 'production',
  rakutenTracking: 'production',
  mobileGlobalSearch: 'production',
  soundcloudTrackUpload: 'production',
  soundcloudAuth: 'production',
  soundcloudDisconnect: 'production',
  abletonRenderAndDownload: 'production',
  text2track: 'uat',
  'SF-9091-downloadFileNameFromUrl': 'production',
};
