import { createActionCreator } from 'deox';

import { asyncTypes } from './constants';
import type { TActionWithPayload, TAsyncAction, TAsyncActionKey, TAsyncActions } from './types';

export const createAsyncActions = <PSuccessPayload = undefined, PRequestPayload = undefined>(
  actionName: string,
) =>
  asyncTypes.reduce(
    (acc, asyncType: TAsyncAction) => {
      const type = `${actionName.toUpperCase()}_${asyncType}`;
      const lowerAsyncType = asyncType.toLowerCase() as TAsyncActionKey;

      acc[asyncType] = type;
      acc[lowerAsyncType] = createActionCreator<string, (payload: unknown) => TActionWithPayload>(
        type,
        (resolve) => (payload: unknown) => resolve(payload),
      );

      return acc;
    },
    {} as TAsyncActions<PSuccessPayload, PRequestPayload>,
  );
