import { useCallback } from 'react';

import { MAX_RECENTS_TRACKS_COUNT } from '@/config/requests';
import { makeMutator } from '@/utils/hookUtils';

import { type ITrack } from '@/types/trackListView';

import { useMatchMutate } from '../useMatchMutate';

export const useRecentsMutator = () => {
  const matchMutate = useMatchMutate();

  const mutateRecents = useCallback(
    (track: ITrack) => {
      const unshiftTrack = (cached: ITrack[]) => {
        const otherRecents = cached.filter(({ id }) => id !== track.id);

        return [track, ...otherRecents].slice(0, MAX_RECENTS_TRACKS_COUNT);
      };

      matchMutate(/tracks\/recents/, makeMutator('data', unshiftTrack), false);
    },
    [matchMutate],
  );

  return mutateRecents;
};
