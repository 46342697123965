import { type AxiosInstance, type AxiosResponse } from 'axios';

import { studioService } from '@/api/private';

export type TFetcher = <T>(url: string) => Promise<T>;

export const createFetcher =
  (service: AxiosInstance, { toastError }: { toastError: boolean }): TFetcher =>
  <T>(url: string) =>
    service.get(url, { toastError }).then((res: AxiosResponse<T>) => res.data);

export const studioLoudFetcher = createFetcher(studioService, { toastError: true });
export const studioSilentFetcher = createFetcher(studioService, { toastError: false });
