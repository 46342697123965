export const PRIVATE_ROUTES = {
  home: '/',
  homeRedirects: {
    signin: '/signin',
    signup: '/signup',
  },
  shared: '/shared',
  library: '/library',
  libraryRoutes: {
    favorites: '/library/favorites',
    downloads: '/library/downloads',
    creations: '/library/creations',
    purchasedTemplates: '/library/purchased-styles',
  },
  voiceRecorder: '/voice-recorder',
  profile: '/profile',
  profileRoutes: {
    userInformation: '/profile/user-information',
    planDetails: '/profile/plan-details',
    notifications: '/profile/notifications',
    social: '/profile/social',
  },
  search: '/search',
  searchRoutes: {
    all: '/search',
    userTracks: '/search/user-tracks',
    globalTracks: '/search/global-tracks',
    templates: '/search/styles',
  },
  templatesByGenre: '/genres/:genre',
  templateExperience: '/style-experience/:templateId',
  artistCustomTemplateExperience: '/:artistSlug/:templateSlug',
  collections: '/collections/:category',
  tracks: '/tracks',
  loops: '/loops-&-sfx/:category',
  collabs: '/soundful-collabs',
  sounds: '/sounds',
  verifyEmail: '/verify-email',
  contactUs: '/contact-us',
} as const;

export const ARTIST_CUSTOM_SLUG_POSTFIX = '-ai-generated-music';
