import { useDispatch } from 'react-redux';

import { useAtomValue } from 'jotai';

import { SHARED_TRACK_MATCHER } from '@/hooks/sharedTrack/useSharedTrack';
import { updateTrackEventAtom } from '@/hooks/tracks/useUpdateTrack';
import { useMatchMutate } from '@/hooks/useMatchMutate';

import { makeMutator } from '@/utils/hookUtils';

import type { ITrack } from '@/types/trackListView';

import { updateTrack } from '@/store/player/player.actions';

import type { IUseSharedTrackData } from '../sharedTrack/types';

const CACHE_MATCHERS = {
  favorites: /\/tracks\/favorites/,
  favoritesGenreFilter: /useFilterGenres-favoriteTrack/,
  trackMetrics: /\/tracks\/metrics/,
  shared: SHARED_TRACK_MATCHER,
  search: /\/search\/tracks/,
  // include /global-tracks and tracks?; exclude track-table
  other: /(?!.*track-table)(\/global-tracks|tracks\?)/,
  tables: /track-table/,
};

export const useToggleFavoriteTrackMutator = () => {
  const matchMutate = useMatchMutate();
  const dispatch = useDispatch();

  const updateTrackEvent = useAtomValue(updateTrackEventAtom);

  const mutateFavoriteTrack = async (track: ITrack, favorite: boolean) => {
    const updated = { ...track, favorite };

    const deleteFav = (cache: ITrack[]) => cache.filter(({ id }) => id !== track.id);

    const toggleFav = (cache: ITrack | ITrack[]) => {
      if (Array.isArray(cache)) {
        return cache.map((element) =>
          element.id === track.id ? { ...element, favorite } : element,
        );
      }

      if (cache.id === track.id) {
        return { ...cache, favorite };
      }

      return cache;
    };

    const updateLikes = (cache?: IUseSharedTrackData) => {
      if (!cache?.track || cache.track.id !== track.id || cache.track.favorite === favorite) {
        return cache;
      }

      const likesCount = cache.metrics.likesCount + (favorite ? 1 : -1);

      return { ...cache, metrics: { ...cache.metrics, likesCount } };
    };

    dispatch(updateTrack({ track: updated }));
    updateTrackEvent.raise({ track: updated });

    await Promise.all([
      matchMutate(CACHE_MATCHERS.shared, updateLikes, false), // must be first
      matchMutate(CACHE_MATCHERS.favorites, makeMutator('data', deleteFav), false),
      matchMutate(CACHE_MATCHERS.shared, makeMutator('track', toggleFav), false),
      matchMutate(CACHE_MATCHERS.shared, makeMutator('recommendedTracks', toggleFav), false),
      matchMutate(CACHE_MATCHERS.search, makeMutator('data', toggleFav), false),
      matchMutate(CACHE_MATCHERS.other, makeMutator('data', toggleFav), false),
      matchMutate(CACHE_MATCHERS.tables, makeMutator('data', toggleFav), false),
    ]);
  };

  const invalidateFavoriteTracks = () => {
    matchMutate(CACHE_MATCHERS.favorites);
    matchMutate(CACHE_MATCHERS.favoritesGenreFilter);
    matchMutate(CACHE_MATCHERS.trackMetrics);
  };

  return { mutateFavoriteTrack, invalidateFavoriteTracks };
};
