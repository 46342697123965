import jwt_decode from 'jwt-decode';

import type { TGetTokenResponse, TTokenPayload } from '@/types/profile';

const registeredByEmail = ({ registrationSource }: TTokenPayload) =>
  registrationSource === 'APP' || registrationSource === 'INVITATION';

export const getLocallyStoredToken = () => localStorage.getItem('access_token') || '';
export const getLocallyStoredRefreshToken = () => localStorage.getItem('refresh_token') || '';

export const setTokens = (data: TGetTokenResponse) => {
  localStorage.setItem('access_token', data.access_token);
  localStorage.setItem('refresh_token', data.refresh_token);
  localStorage.setItem('userId', String(data.id));
};

export const cleanTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('userId');
};

type TTokenMeta = {
  userId: string;
  termsAccepted: boolean;
};

export const getMetaFromToken = (): TTokenMeta => {
  const token = getLocallyStoredToken();

  if (token) {
    try {
      const payload = jwt_decode<TTokenPayload>(token);

      if (payload.user_name) {
        return {
          userId: payload.user_name,
          termsAccepted: payload.termsAndConditionsAccepted ?? registeredByEmail(payload),
        };
      }
    } catch (error) {
      cleanTokens();
    }
  }

  return {
    userId: '',
    termsAccepted: false,
  };
};
