import {
  type TGetTokenResponse,
  type TTokenPayload,
  type TSoundCloudTokenPayload,
} from '@/types/profile';

type TSignUpTokens = TGetTokenResponse & TTokenPayload;

// if `id` is `null` then email is not provided and user is not created
export const isEmailProvidedForSoundCloud = (
  tokens: TSignUpTokens,
): tokens is TGetTokenResponse & TSoundCloudTokenPayload =>
  tokens.registrationSource === 'SOUNDCLOUD' && !tokens.id;
