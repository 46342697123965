export enum ActionEvents {
  openSite = 'Site Opened',
  downloadTrack = 'Track Download',
  clickDropdownBuyMoreButton = 'Profile Dropdown Buy More',
  changeUITheme = 'UI Theme Change',
  addTrackToFavorite = 'Favorite Track',
  addTemplateToFavorite = 'Favorite Template',
  loginStarted = 'Login Started',
  signupStarted = 'Signup Started',
  openLibraryView = 'Library View',
  upgradeSubscriptionPlan = 'Upgrade subscription',
  downgradeSubscriptionPlan = 'Downgrade subscription',
  planReactivation = 'Plan Reactivation',
  openPurchaseView = 'Purchase View',
  clickContactUsPurchasePlan = 'Exclusive Deal, Contact US',
  clickContactUsPurchaseLargerStemPack = 'Larger STEM pack option, Contact US',
  clickContactUsCopyrightBundle = 'Copyright Bundle, Contact US',
  loginCompleted = 'Login Completed',
  signUpCompleted = 'Signup Completed',
  addReferral = 'Referral submission',
  changeInPage = 'Change in Page',
  saveTrack = 'Save Track',
  getTrack = 'Get Track',
  renderSuccess = 'Render Track: success',
  renderCancel = 'Render Track: cancel',
  renderError = 'Render Track: error',
  globalSearch = 'Global Search',
  newVersionDialogAction = 'New Version Dialog Action',
  shareTrack = 'Share track',
  sharedTrackOpened = 'Shared track opened',
  sharedTrackView = 'Shared track view',
  personaDetails = 'Persona Details',
  usageLimited = 'Usage Limited',
  directLinkDownloadDialogShown = 'Direct Link Download Dialog Shown',
  directLinkDownloadDialogCopy = 'Direct Link Download Dialog Copy',
  directLinkDownloadDialogLinkClick = 'Direct Link Download Dialog Link Click',
  upsellModalOpened = 'Upsell Modal Opened',
  upgradeSubscriptionInUpsellModal = 'Upgrade Subscription In Upsell Modal',
  subscriptionPurchase = 'Subscription Purchase',
  stemPackPurchase = 'Stem Pack Purchase',
  premiumPlusTemplatePurchase = 'Premium Plus Template Purchase',
  leftNavClick = 'Left Nav Click',
  leftNavNestedClick = 'Left Nav Nested Click',
  connectToSoundCloud = 'Connect to SoundCloud',
  disconnectFromSoundCloud = 'Disconnect from SoundCloud',
  signInWithSoundCloudClick = 'Sign In with SoundCloud Click',
  signUpWithSoundCloudClick = 'Sign Up with SoundCloud Click',
  linkAccountToSoundCloud = 'Account is linked to SoundCloud account',
  uploadTrackToSoundCloud = 'Upload track to SoundCloud click',
  bannerCtaClick = 'Banner CTA Click',
}

export enum GTMEvents {
  signIn = 'login',
  signUp = 'registration',
  error = 'error',
}

export enum PlaybackEvents {
  playback = 'Track Playback',
  progress = 'Track Playback Progress',
  templateRadio = 'Play Template Radio',
  genreRadio = 'Play Genre Radio',
}

export enum CreateTrackEvents {
  create = 'Create New Track',
  created = 'New Track Created',
  createNextPreview = 'Create New Preview for New Track',
  applyChanges = 'Apply Change to New Track',
}

export enum CreateLoopEvents {
  create = 'Create New Loop',
  created = 'New Loop Created',
  createNextPreview = 'Create New Preview for New Loop',
  applyChanges = 'Apply Change to New Loop',
}

export enum Sources {
  topTemplatesCard = 'Top Templates Card',
  youMightAlsoLike = 'You Might Also Like',
  staffPicks = 'Staff Picks',
  allTracksMostPopular = 'All Tracks Most Popular',
  allTracksNewReleases = 'All Tracks New Releases',
  newReleasesCard = 'New Releases Card',
  soundfulCollabs = 'Soundful Collabs Card',
  featuredTemplatesCard = 'Featured Templates Card',
  templateByGenre = 'Template By Genre Card',
  recentTracks = 'Recent Tracks',
  genreCard = 'Genre Card',
  latestTracks = 'Latest tracks',
  editor = 'Editor',
  downloadedTracks = 'Downloaded Tracks',
  favoriteTracks = 'Favorite tracks',
  favoriteTracksCard = 'Favorite Tracks Card',
  userTracks = 'User Tracks',
  allTemplates = 'All Templates',
  allTracks = 'All Tracks',
  createContainer = 'Track player',
  searchPlatform = 'SearchPlatform',
  searchedTracks = 'Searched Tracks',
  mobilePlayer = 'Mobile Player',
  emptyState = 'Empty State',
  player = 'Player',
  trackDetails = 'Track Details',
  sharedTrackRecommendations = 'Shared Track Recommendations',
  sharedTrack = 'Shared Track',
  templatesEducation = 'Templates Education',
  templateExperience = 'Template Experience',
  upsellModal = 'Upsell Modal',
  purchasedTemplates = 'Purchased Templates',
  distributeToSoundCloud = 'Distribute to SoundCloud',
  collectionPage = 'Collection Page',
}

export enum CreationSources {
  fromGenre = 'Genre',
  fromTemplateByGenre = 'Template By Genre',
  fromTopTemplates = 'Top Templates',
  fromNewReleases = 'New Releases',
  fromFeaturedTemplates = 'Featured Templates',
  fromFavoriteTemplates = 'Favorite Templates',
  fromTemplate = 'Template',
  fromTrack = 'Track',
  fromSliderBanner = 'Slider Banner',
  fromPinwheel = 'Pinwheel',
  searchPlatform = 'SearchPlatform',
  soundfulCollabs = 'Soundful Collabs',
  templateExperience = 'Template Experience',
  collectionPage = 'Collection page',
}

export enum GlobalSearchSources {
  homePage = 'Home Page',
  templates = 'Templates Page',
  globalTracks = 'Global Tracks',
  myLibrary = 'My Library',
  other = 'Other',
}

export enum GlobalSearchCategories {
  all = 'All',
  moods = 'Moods',
  artists = 'Artists',
  tracks = 'Tracks',
  templates = 'Templates',
  genres = 'Genres',
  tags = 'Tags',
}

export enum ShareTrackTo {
  facebook = 'Facebook',
  twitter = 'Twitter',
  whatsApp = 'WhatsApp',
  reddit = 'Reddit',
  email = 'Email',
  copy = 'Copy',
}

export enum ShareTrackSources {
  myLibrary = 'My Library',
  player = 'Player',
  publicTrackPlayer = 'Public Track Player',
  publicTrack = 'Public Track',
  globalTracks = 'Global Tracks',
  searchTracks = 'Search Tracks',
  trackDetails = 'Track Details',
}

export enum PersonaCategories {
  kindOfCreative = 'WHAT KIND OF CREATIVE ARE YOU',
  typeInterestedMusic = 'WHAT TYPE OF MUSIC ARE YOU INTERESTED IN',
}
