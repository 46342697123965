import type { AxiosResponse } from 'axios';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';

import {
  deletePaymentMethod,
  getDefaultPaymentMethod,
  postPaymentMethod,
} from '@/services/paymentMethod';
import { getUserInfo, getRakutenUserInfoWithRetry } from '@/services/profile';
import {
  cancelFutureSubscription,
  cancelSubscription,
  getPurchaseHistory,
} from '@/services/purchase';
import { ProfileSection } from '@/services/types';

import type { TPaymentExecutorResult } from '@/hooks/payment/executePayment';
import { sync } from '@/hooks/synchronizer';

import { type PlanKeys, ProductType, planLevel } from '@/enums/profile';
import { getFriendlyDate } from '@/utils/dateUtils';
import { CommonError } from '@/utils/errors';
import { normalizeError } from '@/utils/errorUtils';
import { hasFeature } from '@/utils/featureFlag';
import { sleep, noop } from '@/utils/functionalUtils';

import type { Nullable, TAction } from '@/types/common';
import type { TPaymentMethod } from '@/types/paymentMethod';
import type { TPurchase, TPurchaseWithModel, TSubscription } from '@/types/product';
import { type TUserDetails, type TUserSubscriptionInfo } from '@/types/profile';
import type { TPurchaseHistory } from '@/types/purchase';

import { trackRakutenConversion } from '@/scripts/rakuten';
import { createEnqueueSnackbarAction } from '@/store/notistack/notistack.actions';
import {
  selectFuturePlanTitle,
  selectSubscriptionInfo,
} from '@/store/subscription/subscription.selectors';
import { ActionEvents, trackEvent } from '@/tracking';

import { selectUserData } from '../profile/profile.selectors';

import {
  cancelFutureSubscriptionAction,
  cancelSubscriptionAction,
  createPaymentMethodAction,
  deletePaymentMethodAction,
  fetchPurchaseHistoryAction,
  getPaymentMethodsAction,
  updateBundleSubscriptionAction,
} from './payment.actions';
import type {
  TCreatePaymentMethod,
  TDeletePaymentMethod,
  TFetchPurchaseHistory,
  TFetchUpdatePurchase,
} from './types';

type TPaymentMethodRes = AxiosResponse<Nullable<TPaymentMethod>>;

function* getDefaultPaymentMethodSaga() {
  try {
    const { data: paymentMethod }: TPaymentMethodRes = yield call(getDefaultPaymentMethod);
    const paymentMethods = paymentMethod ? [paymentMethod] : [];
    sync('updatePaymentMethods', { paymentMethods });
  } catch (err) {
    console.error('error', err);
    yield put(getPaymentMethodsAction.failure(err));
  }
}

function* createPaymentMethodSaga({
  payload: { tokenId, fullName, type, isDefault, onComplete, onError },
}: TCreatePaymentMethod) {
  try {
    const attributes = { tokenId, metadata: { fullName }, type, isDefault };
    const { data: paymentMethod }: TPaymentMethodRes = yield call(postPaymentMethod, attributes);

    if (!paymentMethod?.id) {
      throw new Error('PaymentMethod is not created');
    }

    if (isDefault) {
      const paymentMethods = [paymentMethod];
      sync('updatePaymentMethods', { paymentMethods });
    }

    yield call(onComplete, paymentMethod);
    yield put(createPaymentMethodAction.success());
  } catch (err) {
    yield put(createPaymentMethodAction.failure());
    yield call(onError, err);
  }
}

function* deletePaymentMethodSaga({ payload: { id, onComplete, onError } }: TDeletePaymentMethod) {
  try {
    yield call(deletePaymentMethod, id);
    yield put(deletePaymentMethodAction.success());
    yield call(getDefaultPaymentMethodSaga);
    yield call(onComplete);
  } catch (err) {
    const error = normalizeError(err);
    yield put(deletePaymentMethodAction.failure(error.message));
    yield call(onError, err);
  }
}

function* fetchPurchaseHistory({ payload: { pageSize } }: TFetchPurchaseHistory) {
  try {
    const { data } = yield call(() => getPurchaseHistory(pageSize));
    yield put(fetchPurchaseHistoryAction.success(data));
  } catch (err) {
    yield put(fetchPurchaseHistoryAction.failure(err));
  }
}

let unsubscribeRakutenMopHandler: TAction = noop;
let unsubscribeTimeoutId: number | null = null;

const resetRakutenMopHandler = () => {
  if (unsubscribeTimeoutId) {
    window.clearTimeout(unsubscribeTimeoutId);
    unsubscribeTimeoutId = null;
  }

  unsubscribeRakutenMopHandler();
  unsubscribeRakutenMopHandler = noop;
};

const setRakutenMopHandler = (action: TAction) => {
  resetRakutenMopHandler();
  unsubscribeRakutenMopHandler = action;

  unsubscribeTimeoutId = window.setTimeout(resetRakutenMopHandler, 2 * 60 * 1000);
};

function* purchaseSaga({
  payload: { execute, purchases, instrumentationData, onPurchaseConfirmed, webSocketSubscribe },
}: TFetchUpdatePurchase) {
  const userData: TUserDetails = yield select(selectUserData);

  const trackPurchaseEvent = async () => {
    try {
      const includedSubscription = purchases.find(
        (purchase) => purchase.category === ProductType.subscription,
      ) as TPurchaseWithModel<TSubscription> | undefined;

      if (includedSubscription) {
        const result = await getRakutenUserInfoWithRetry(
          'PREMIUM_SUBSCRIPTION',
          includedSubscription.model.title,
        );

        if (result) {
          trackRakutenConversion({
            mid: result.mid,
            ord: result.orderId,
            tr: result.siteId,
            land: result.land,
            skulist: result.sku,
            qlist: '1',
            amtlist: '0',
            cur: 'USD',
            namelist: result.productName,
          });
        }
      }
    } catch (error) {
      //
    }
  };

  try {
    const { currentPlan }: TUserSubscriptionInfo = yield select(selectSubscriptionInfo);

    const isPlanUpgrade = getIsPlanUpgrade(currentPlan?.title, purchases);
    const transactionsHistoryPromise = isPlanUpgrade ? getPurchaseHistory() : null;

    const result: TPaymentExecutorResult = yield call(execute);

    if (!result) {
      return;
    }

    const isRakutenTrackingEnabled = hasFeature('rakutenTracking') && userData?.isRakuten;

    if (isRakutenTrackingEnabled) {
      setRakutenMopHandler(
        webSocketSubscribe('rakutenMop', async (event) => {
          if (event.messageKey !== 'rakuten') {
            return;
          }

          await trackPurchaseEvent();

          resetRakutenMopHandler();
        }),
      );
    }

    const isStale = (info: TUserSubscriptionInfo) =>
      isPlanUpgrade && info.currentPlan?.planId === currentPlan?.planId;

    const {
      data: { subscriptionInfo },
    } = yield call(() =>
      executeWhile(
        () => getUserInfo([ProfileSection.subscriptionInfo]),
        (response) => isStale(response.data.subscriptionInfo),
      ),
    );

    if (isStale(subscriptionInfo)) {
      throw new CommonError('errors.payment.unknown');
    }

    yield call(() => sync('updateSubscriptionInfo', { subscriptionInfo }));

    yield call(sync, 'purchase', {
      products: purchases.map(({ category }) => category),
    });

    const isResultObject = typeof result === 'object';

    yield call(
      onPurchaseConfirmed,
      isResultObject
        ? {
            serverMessage: result.successMessage,
            showCongratsModal: result.showCongratsModal,
          }
        : { serverMessage: null },
    );
    yield put(updateBundleSubscriptionAction.success());

    if (isPlanUpgrade && transactionsHistoryPromise) {
      const { data: transactionsData } = yield call(() => transactionsHistoryPromise);

      const previousPlan = transactionsData?.find(
        (transaction: TPurchaseHistory) => transaction.planCategory === ProductType.subscription,
      );

      if (transactionsData?.length) {
        yield call(() =>
          trackEvent(ActionEvents.planReactivation, {
            previousCancelledPlan: previousPlan?.planDescription,
            planReactivatedTo: subscriptionInfo?.currentPlan?.title,
            planReactivationDate: subscriptionInfo?.currentPlan?.date,
          }),
        );
      }
    }

    for (const { mixpanelEventName, data } of instrumentationData) {
      yield call(() => trackEvent(mixpanelEventName, data));
    }
  } catch (err) {
    resetRakutenMopHandler();
    yield put(updateBundleSubscriptionAction.failure());
    yield put(
      createEnqueueSnackbarAction({
        message: normalizeError(err).message,
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

function getIsPlanUpgrade(currentPlanTitle: PlanKeys | undefined, purchases: TPurchase[]) {
  if (!currentPlanTitle) {
    return false;
  }

  const planPurchase = purchases.find((purchase) => purchase.category === ProductType.subscription);

  const nextPlanTitle = planPurchase?.originalTitle as PlanKeys | undefined;

  if (!nextPlanTitle) {
    return false;
  }

  return planLevel[currentPlanTitle] < planLevel[nextPlanTitle];
}

function executeWhile<T>(execute: () => Promise<T>, isStale: (data: T) => boolean): Promise<T> {
  let attempts = 10;

  const run = async (): Promise<T> => {
    attempts -= 1;

    const result = await execute();

    if (isStale(result) && attempts > 0) {
      await sleep(500);

      const rerunResult = await run();

      return rerunResult;
    }

    return result;
  };

  return run();
}

function* fetchCancelPurchase() {
  try {
    const { data } = yield call(() => cancelSubscription());
    const {
      data: { subscriptionInfo },
    } = yield call(() => getUserInfo([ProfileSection.subscriptionInfo]));

    yield put(
      createEnqueueSnackbarAction({
        message: data.result,
        options: {
          variant: 'success',
        },
      }),
    );
    yield call(() => sync('updateSubscriptionInfo', { subscriptionInfo }));
    yield put(cancelSubscriptionAction.success(data));
  } catch (err) {
    yield put(cancelSubscriptionAction.failure());
  }
}

function* fetchCancelFuturePurchase() {
  try {
    const futurePlan: string = yield select(selectFuturePlanTitle);
    const { data } = yield call(() => cancelFutureSubscription());
    const {
      data: { subscriptionInfo },
    } = yield call(() => getUserInfo([ProfileSection.subscriptionInfo]));

    yield put(
      createEnqueueSnackbarAction({
        message: data.result,
        options: {
          variant: 'success',
        },
      }),
    );
    yield call(() => sync('updateSubscriptionInfo', { subscriptionInfo }));
    yield call(() =>
      trackEvent(ActionEvents.planReactivation, {
        existingSubscription: subscriptionInfo?.currentPlan?.title,
        expireSubscriptionDate:
          subscriptionInfo?.currentPlan && getFriendlyDate(subscriptionInfo?.currentPlan?.date),
        previousCancelledPlan: futurePlan,
      }),
    );

    yield put(cancelFutureSubscriptionAction.success(data));
  } catch (err) {
    yield put(cancelFutureSubscriptionAction.failure());
  }
}

export function* watchPaymentSaga() {
  yield takeLeading(getPaymentMethodsAction.request, getDefaultPaymentMethodSaga);
  yield takeLatest(createPaymentMethodAction.request, createPaymentMethodSaga);
  yield takeLatest(deletePaymentMethodAction.request, deletePaymentMethodSaga);

  yield takeLatest(fetchPurchaseHistoryAction.request, fetchPurchaseHistory);
  yield takeLatest(updateBundleSubscriptionAction.request, purchaseSaga);
  yield takeLatest(cancelSubscriptionAction.request, fetchCancelPurchase);
  yield takeLatest(cancelFutureSubscriptionAction.request, fetchCancelFuturePurchase);
}
