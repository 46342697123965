import { initialAsyncState } from './initialState';
import type { TAsyncActions, IInitialAsyncState, TActionWithPayload } from './types';

export const handleAsyncReducers = <PData, PRequestPayload = undefined>(
  state: IInitialAsyncState<PData>,
  initialState: PData,
  action: TActionWithPayload,
  actions: TAsyncActions<PData, PRequestPayload>,
) => {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SUCCESS:
      return {
        ...state,
        isLoading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case actions.FAILURE:
      return {
        ...state,
        isLoading: false,
        loaded: true,
        error: action.payload,
      };
    case actions.CLEAN:
      return {
        ...initialAsyncState(initialState),
      };
    default:
      return state;
  }
};
