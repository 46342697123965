import axios from 'axios';

import { identity } from '@/utils/functionalUtils';

import { correlationIdInterceptor, handleErrorInterceptor } from '../common/interceptors';
import {
  notificationTemplateUrl,
  personaServer,
  plansServer,
  profileServer,
  studioURL,
  rakutenURL,
} from '../urls';

import { createPrivateApiInterceptors } from './factories';

const apiConfig = {
  rakutenService: {
    baseURL: rakutenURL,
    headers: { 'Content-Type': 'application/json' },
    request: [correlationIdInterceptor],
    response: [handleErrorInterceptor],
  },
  profileService: {
    baseURL: profileServer,
    headers: { 'Content-Type': 'application/json' },
    request: [correlationIdInterceptor],
    response: [handleErrorInterceptor],
  },
  studioService: {
    baseURL: studioURL,
    headers: { 'Content-Type': 'application/json' },
    request: [correlationIdInterceptor],
    response: [handleErrorInterceptor],
  },
  plansService: {
    baseURL: plansServer,
    headers: { 'Content-Type': 'application/json' },
    request: [correlationIdInterceptor],
    response: [handleErrorInterceptor],
  },
  notificationTemplateService: {
    baseURL: notificationTemplateUrl,
    headers: { 'Content-Type': 'application/json' },
    request: [correlationIdInterceptor],
    response: [handleErrorInterceptor],
  },
  personaService: {
    baseURL: personaServer,
    headers: { 'Content-Type': 'application/json' },
    request: [correlationIdInterceptor],
    response: [handleErrorInterceptor],
  },
};

// request: gatewayInterceptor; response: refreshTokenInterceptor
const privateApiInterceptors = createPrivateApiInterceptors();

const createPrivateService = (service: keyof typeof apiConfig) => {
  const { baseURL, headers, request, response } = apiConfig[service];
  const instance = axios.create({ baseURL, headers });

  instance.interceptors.request.use(...request);
  privateApiInterceptors.apply(instance);
  response.forEach((interceptor) => instance.interceptors.response.use(identity, interceptor));

  return instance;
};

export const profileService = createPrivateService('profileService');
export const rakutenService = createPrivateService('rakutenService');
export const studioService = createPrivateService('studioService');
export const plansService = createPrivateService('plansService');
export const notificationTemplateService = createPrivateService('notificationTemplateService');
export const personaService = createPrivateService('personaService');
