import { createEventEmitter } from '@/utils/createEventEmitter';

const prevEventEmitter = createEventEmitter();
const nextEventEmitter = createEventEmitter();
const playEventEmitter = createEventEmitter();
const pauseEventEmitter = createEventEmitter();

export const bindMediaHandlers = () => {
  if ('mediaSession' in navigator) {
    navigator.mediaSession.setActionHandler('previoustrack', () => {
      prevEventEmitter.raise();
    });
    navigator.mediaSession.setActionHandler('nexttrack', () => {
      nextEventEmitter.raise();
    });
    navigator.mediaSession.setActionHandler('play', () => {
      playEventEmitter.raise();
    });
    navigator.mediaSession.setActionHandler('pause', () => {
      pauseEventEmitter.raise();
    });
  }
};

export const onMediaSessionPrevEvent = prevEventEmitter.subscribe;

export const onMediaSessionPextEvent = nextEventEmitter.subscribe;

export const onMediaSessionPlayEvent = playEventEmitter.subscribe;

export const onMediaSessionPauseEvent = pauseEventEmitter.subscribe;
