import { useTranslation } from 'react-i18next';

import { Grid, Typography, Button } from '@mui/material';

import type { TAction } from '@/types/common';

import { isBrowserSupported } from '@/isBrowserSupported';

type TFallbackErrorProps = {
  isResettable?: boolean;
  reset?: TAction;
};

export const FallbackError = ({ isResettable, reset }: TFallbackErrorProps) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" alignItems="center">
      <Typography variant="h4">😢</Typography>
      <Typography variant="h4">{t('errors.boundary.mainLine')}</Typography>
      {isBrowserSupported() ? (
        isResettable && !!reset ? (
          <Button variant="contained" color="primary" onClick={reset} sx={{ m: 2 }}>
            {t('errors.boundary.tryAgain')}
          </Button>
        ) : (
          <Typography variant="subtitle1">{t('errors.boundary.subLine')}</Typography>
        )
      ) : (
        <Typography variant="subtitle1" sx={{ padding: '16px', textAlign: 'center' }}>
          {t('errors.boundary.browserIsNotSupported')}
        </Typography>
      )}
    </Grid>
  );
};
