import type { SVGProps } from 'react';

export const InfoFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
    <circle cx="12" cy="12" r="10.6" fill="#218EDB" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12 16v-5"
    />
    <circle cx="12" cy="8" r="1" fill="#fff" />
  </svg>
);
