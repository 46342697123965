export const RETRY_COUNT = 3;
export const RETRY_DELAY = 5000;

export const swrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  errorRetryInterval: RETRY_DELAY,
  errorRetryCount: RETRY_COUNT,
};

export const LATEST_TRACKS_COUNT = 14;
export const MOST_POPULAR_TRACKS_COUNT = 24;
export const STAFF_PICKS_TRACKS_COUNT = 12;
export const NEW_RELEASES_TRACKS_COUNT = 10;
export const MAX_RECENTS_TRACKS_COUNT = 24;

export const SAVE_FILE_THROTTLE_TIME = 45000;
