import { majorScaleMap, mapOfKeysCorrespondence, type TMainScales } from '@/config/scales';
import { SOUNDFUL_CREATOR } from '@/config/tracks';
import { ContentType, TrackType } from '@/enums/track';
import { handleError } from '@/utils/errorUtils';
import { hasFeature } from '@/utils/featureFlag';

import type { TDownloadFormat } from '@/components/download/types';

import type { TUserDetails } from '@/types/profile';
import type {
  ITrack,
  TRenderStatus,
  TCopyrightStatus,
  SoundCloudAutoUploadStatus,
} from '@/types/trackListView';

import { isEqual } from './objectUtils';
import { getImagePath } from './pathUtils';

export const isRendered = (track: ITrack): boolean => track.renderInfo?.status === 'SUCCESS';

export const isPreview = (track: ITrack): boolean => !track.packPath?.mp3Path;

export const isUserTrack = (track: ITrack) => track.trackType === TrackType.user;

export const isEditable = (track: ITrack, user: Pick<TUserDetails, 'userId'>) =>
  !isRendered(track) &&
  (track.renderInfo?.status === 'ERROR' || !track.renderInfo?.status) &&
  isUserTrack(track) &&
  (!track.creator || user.userId === track.creator.id);

export const isGlobalTrack = (track: ITrack) => track.trackType === TrackType.global;

export const isLoop = (track: ITrack) => track.contentType === ContentType.loop;

export const isShareable = (track: ITrack) => !isLoop(track);

export const isDownloadable = (track: ITrack) => track.downloadable !== false;

export const isSuccessfullyRendered = (track: ITrack) => track?.renderInfo?.status === 'SUCCESS';

export const isPurchased = (track: ITrack) => track.purchased;

export const isCopyrightOwned = (track: ITrack) => track.copyright?.status === 'COMPLETED';
export const isCopyrightInProgress = (track: ITrack) => track.copyright?.status === 'IN_PROGRESS';

export const isValid = (track: ITrack) => {
  if (!track.name) {
    return false;
  }

  if (isGlobalTrack(track)) {
    return true;
  }

  if (isPreview(track)) {
    return Boolean(track.prerenderTrack?.path?.renderedKeysBaseUrl);
  }

  return true;
};

export const getCreatorName = (track: ITrack) => track.creator?.name || SOUNDFUL_CREATOR;

export const getClosestKeyAndPitch = (requestedKey: string, availableKeys: TMainScales[]) => {
  const requestedMajor = mapOfKeysCorrespondence.get(requestedKey);

  if (!requestedMajor) {
    throw new Error(`Key ${requestedKey} is not supported`);
  }

  if (availableKeys.includes(requestedMajor)) {
    return { key: requestedMajor, pitch: 0 };
  }

  const KEYS_NUMBER = 12;
  const MAX_DISTANCE = KEYS_NUMBER / 2;

  const requestedMajorIndex = majorScaleMap[requestedMajor];

  const getOffset = (majorKey: TMainScales) => {
    const offset = majorScaleMap[majorKey] - requestedMajorIndex;
    const distance = Math.abs(offset);

    if (distance > MAX_DISTANCE) {
      return offset < 0 ? KEYS_NUMBER - distance : distance - KEYS_NUMBER;
    }

    return offset;
  };

  const closestKey = availableKeys.reduce((prev, curr) => {
    const offsetPrev = getOffset(prev);
    const offsetCurr = getOffset(curr);
    const distPrev = Math.abs(offsetPrev);
    const distCurr = Math.abs(offsetCurr);

    if (distPrev === distCurr) {
      return offsetPrev > offsetCurr ? prev : curr;
    }

    return distPrev < distCurr ? prev : curr;
  }, availableKeys[0]);

  const pitch = getOffset(closestKey);

  return { key: closestKey, pitch };
};

export const isEqualTrack = (track1: ITrack, track2: ITrack) => {
  if (track1.id !== track2.id) {
    return false;
  }

  const { lastDownloaded: lastDownloaded1, ...t1 } = track1;
  const { lastDownloaded: lastDownloaded2, ...t2 } = track2;

  return isEqual(t1, t2);
};

export const logPlaylist = (trackList: ITrack[], track: ITrack) => {
  console.groupCollapsed(`Active Track: ${track.name || 'no'}`);
  trackList.forEach(({ name }) => {
    if (name === track.name) {
      console.info(`%c${name}`, 'background: #222; color: #bada55');
    } else {
      console.info(name);
    }
  });
  console.groupEnd();
};

export const getTrackCardId = (track: ITrack) => `track-${track.id}`;

export const replaceRenderStatus = (
  track: ITrack,
  status: TRenderStatus,
  prop: 'renderInfo' | 'renderAbletonInfo' = 'renderInfo',
): ITrack => {
  const updatedTrack = { ...track, [prop]: { ...track[prop], status } };

  return updatedTrack;
};

export const replaceScAutoUploadStatus = (
  track: ITrack,
  status: SoundCloudAutoUploadStatus,
): ITrack => {
  const updatedTrack = {
    ...track,
    additionalInfo: {
      ...track.additionalInfo,
      soundcloud_auto_upload_status: status,
    },
  };

  return updatedTrack;
};

export const replaceCopyrightStatus = (track: ITrack, status: TCopyrightStatus): ITrack => {
  const copyright = { status, purchaseReqTime: new Date().toISOString() };

  return { ...track, copyright };
};

export const wrapAction =
  (
    action: (tracks: ITrack[], track: ITrack, source: string) => () => void,
    tracks: ITrack[] | undefined,
    source: string,
  ) =>
  (track: ITrack) =>
    action(tracks || [], track, source)();

export const updateMediaMetadata = (track: ITrack) => {
  if ('mediaSession' in navigator && 'MediaMetadata' in window) {
    const imageSrc = getImagePath(track.imageUrl) || '';

    window.navigator.mediaSession.metadata = new MediaMetadata({
      title: track.name,
      artist: 'Soundful',
      album: track.template.name,
      artwork: [{ src: imageSrc, sizes: '184x184', type: 'image/png' }],
    });
  }
};

export const handleDownloadPathMissingError = (track: ITrack, format: TDownloadFormat) => {
  handleError(
    `DownloadTrack: no file path found for format ${format}`,
    { toast: true, remoteLog: true },
    {
      category: 'DOWNLOAD_TRACK_PATH_MISSING',
    },
    { trackId: track.id, trackName: track.name, format, pathsMap: track.packPath },
  );
};

const soundcloudTrackUploadEnabled = hasFeature('soundcloudTrackUpload');

export const isTrackUploadedToSoundCloud = (track: ITrack) => {
  return soundcloudTrackUploadEnabled && !!track.additionalInfo?.soundcloud_trackId;
};

export const willTrackAutoUploadToSoundCloud = (track: ITrack) => {
  return (
    soundcloudTrackUploadEnabled &&
    track.additionalInfo?.soundcloud_auto_upload_status === 'IN_PROGRESS'
  );
};
