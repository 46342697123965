import { handleError } from '../errorUtils';

import { SESSION_ITEMS } from './config';
import type { IStorage, TStorageRecord, TStorageRecordOptions } from './types';

const getStorageForItem = (key: keyof IStorage) => {
  if (SESSION_ITEMS.includes(key)) {
    return sessionStorage;
  }

  return localStorage;
};

export const getRecord = <PKey extends keyof IStorage>(key: PKey) => {
  const storage = getStorageForItem(key);
  const serialized = storage.getItem(key);

  if (serialized) {
    try {
      const { value, options }: TStorageRecord<PKey> = JSON.parse(serialized);

      if (options?.useOnce) {
        storage.removeItem(key);
      }

      return value;
    } catch (error) {
      handleError(error, undefined, { category: 'STORAGE_UTILS' });
      storage.removeItem(key);
    }
  }

  return null;
};

export const setRecord = <PKey extends keyof IStorage>(
  key: PKey,
  value: IStorage[PKey],
  options: TStorageRecordOptions = {},
) => {
  const storage = getStorageForItem(key);
  const serialized = JSON.stringify({ value, options });

  storage.setItem(key, serialized);
};

export const removeRecord = (key: keyof IStorage) => getStorageForItem(key).removeItem(key);
