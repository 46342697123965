import { type SvgIconProps, SvgIcon } from '@mui/material';

export const CheckFilled = (props: SvgIconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    sx={{ fill: 'none' }}
    {...props}
  >
    <circle cx="12" cy="12" r="10.598" fill="#26A81A" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m9 12 2 2 5-5"
    />
  </SvgIcon>
);
