import { normalizeError } from '@/utils/errorUtils';
import { remoteLogger } from '@/utils/logging';

import supportedBrowsers from '@/supportedBrowsers';

let memoizedValue: boolean | null = null;

export const isBrowserSupported = (): boolean => {
  if (memoizedValue !== null) {
    return memoizedValue;
  }

  try {
    memoizedValue = supportedBrowsers.test(navigator.userAgent);
  } catch (e) {
    remoteLogger.error(normalizeError(e), { category: 'BROWSER_SUPPORT_CHECK' });

    memoizedValue = false;
  }

  return memoizedValue;
};
