import { useEffect, type ReactElement } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useAtomValue } from 'jotai';

import { useAuth } from '@/contexts/AuthProvider';

import { LANDING_ROUTES } from '@/config/routes';
import { setRecord } from '@/utils/storageUtils';

import { emailIsNotProvidedAtom } from '@/components/soundCloud/signUpAtoms';

export const PrivateRoute = ({ children }: { children: ReactElement }): ReactElement => {
  const { isAuthenticated, termsAccepted } = useAuth();
  const location = useLocation();
  const emailIsNotProvided = useAtomValue(emailIsNotProvidedAtom);

  useEffect(() => {
    if (!isAuthenticated || !termsAccepted || emailIsNotProvided) {
      const intendedPath = `${location.pathname}${location.search}${location.hash}`;

      setRecord('redirectedFromPrivate', intendedPath, { useOnce: true });
    }
  }, [isAuthenticated, termsAccepted, location, emailIsNotProvided]);

  if (!isAuthenticated) {
    return <Redirect to={LANDING_ROUTES.signin} />;
  }

  if (!termsAccepted) {
    return <Redirect to={LANDING_ROUTES.acceptTerms} />;
  }

  return children;
};
