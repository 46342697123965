import { autocompleteClasses } from '@mui/material';
import type { ThemeOptions } from '@mui/material/styles';

import { breakpoints } from './breakpoints';
import { scrollbarStylesOverrides } from './scrollbarStyles';
import { typography } from './typography';

export const toAutofillStyles = (bgColor: string, color: string) => ({
  '&, &:active, &:focus': {
    WebkitTextFillColor: color,
    WebkitBoxShadow: `0px 0px 0px 1000px ${bgColor} inset`,
    transition: 'background-color 5000s 0s',
    boxSizing: 'border-box',
    height: '100%',
  },
});

const spacing = 8;

const shape = {
  borderRadius: 4,
};

export const commonSettings: Partial<ThemeOptions> = {
  shape,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ':root': {
          '--screenHeight': `${window.innerHeight}px`,
        },
        body: {
          margin: 0,
          fontFamily: 'monospace', // to show where Typography is not used
        },

        '@supports not selector(::-webkit-scrollbar)': {
          'div,main': {
            scrollbarWidth: 'thin',
          },
        },

        ...scrollbarStylesOverrides,

        'input[type="number"]::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
        },
        'input[type="number"]': {
          appearance: 'textfield',
        },

        'input[type="password"]::-ms-reveal': {
          display: 'none',
        },

        '.grecaptcha-badge': {
          visibility: 'hidden',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '40px',
        },
        input: {
          padding: '13px 16px',
          ...typography.body2,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginTop: '-7px',
          paddingLeft: '2px',
        },
        outlined: {
          ...typography.body2,
        },
        shrink: {
          marginTop: '-2px',
          paddingLeft: '2px',
          transform: 'translate(14px, -6px) scale(0.85) !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '13px 16px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '0 4px 0 6px',
          },
          '.MuiChip-deleteIcon.MuiChip-deleteIconSmall': {
            width: 16,
            height: 16,
            flexShrink: 0,
          },
        },
        inputRoot: {
          minHeight: '41px',
          height: 'auto',

          [`& .${autocompleteClasses.input}`]: {
            minWidth: 100,
          },
        },
        input: {
          boxSizing: 'border-box',
        },
        option: {
          padding: '17px 16px',
        },
        tag: {
          border: '1px solid transparent',
          borderRadius: shape.borderRadius,
          margin: 4,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          margin: `${spacing}px 0 0`,

          [`@media all and (max-width: ${breakpoints.values.mobile}px)`]: {
            margin: `${spacing * 0.5}px 0 0`,
          },
        },

        marginNormal: {
          margin: `${spacing * 2.25}px 0 0`,

          [`@media all and (max-width: ${breakpoints.values.laptop}px)`]: {
            margin: `${spacing * 1.25}px 0 0`,
          },

          [`@media all and (max-width: ${breakpoints.values.mobile}px)`]: {
            margin: `${spacing * 0.5}px 0 0`,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: 'inherit',
          },
        },
      },
    },
  },
};
