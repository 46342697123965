import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

import { Checkbox } from '@/shared/checkbox/Checkbox';

import { Button } from '../button/Button';

import { useStyles } from './ConfirmationDialog.styles';
import type { IConfirmationDialogOptions } from './useConfirmationDialog';

const ConfirmationDialogComponent = ({
  isOpen,
  title,
  body,
  cancel,
  confirm,
  onCancel,
  onConfirm,
  checkbox,
  showCancel,
  showCheckbox,
}: IConfirmationDialogOptions) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleCheckboxChange = () => setIsCheckboxChecked(!isCheckboxChecked);

  const handleConfirm = () => {
    if (showCheckbox) {
      onConfirm(isCheckboxChecked);
    } else {
      onConfirm();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onCancel} classes={{ paper: classes.container }}>
      <DialogTitle className={classes.title}>
        <Typography align="center" variant="h5" component="p">
          {t(title)}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2">{t(body)}</Typography>

        {showCheckbox && (
          <Checkbox
            labelProps={{ sx: { marginTop: 4 } }}
            label={<Typography variant="body2">{t(checkbox)}</Typography>}
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
          />
        )}
      </DialogContent>

      <DialogActions className={classes.buttons}>
        {showCancel && (
          <Button variant="text" onClick={onCancel} color="inherit">
            {t(cancel)}
          </Button>
        )}

        <Button data-testid="confirmDialog" onClick={handleConfirm} disableRipple>
          {t(confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ConfirmationDialog = memo(ConfirmationDialogComponent);
