import { type TRequest } from '@/types/common';
import { type TPaymentMethod } from '@/types/paymentMethod';

import { profileService } from '@/api/private';

export const getDefaultPaymentMethod: TRequest<TPaymentMethod> = () =>
  profileService.get('/payment-method/default');

export const getAllPaymentMethods: TRequest<TPaymentMethod[]> = () =>
  profileService.get('/payment-method/all');

export const postPaymentMethod: TRequest<
  TPaymentMethod,
  { tokenId: string; metadata: { fullName: string }; type: string; isDefault: boolean }
> = ({ tokenId, metadata, type, isDefault }) =>
  profileService.post(`/payment-method?type=${type}&tokenId=${tokenId}&isDefault=${isDefault}`, {
    metadata,
  });

export const deletePaymentMethod: TRequest<TPaymentMethod, string> = (paymentMethodId) =>
  profileService.delete(`/payment-method/${paymentMethodId}?type=CARD`);
