import type { AxiosResponse } from 'axios';
import i18n from 'i18next';
import { mutate } from 'swr';

import { type IUseSharedTrackData } from '@/hooks/sharedTrack/types';

import { noop } from '@/utils/functionalUtils';
import { toastSuccess } from '@/utils/notificationUtils';
import { encodeUri } from '@/utils/stringUtils';
import { isGlobalTrack } from '@/utils/trackUtils';

import type { TDownloadFormat } from '@/components/download/types';

import type { TRequest, Dict } from '@/types/common';
import { type RenderType } from '@/types/renderType';
import {
  type ITrack,
  type ITracks,
  type ICreateTrackBody,
  type ISaveTrackBody,
  type IDownloadPackPaths,
} from '@/types/trackListView';

import { studioService } from '@/api/private';
import { publicStudioService } from '@/api/public';

import { studioLoudFetcher } from './fetchers';

export const getUserTrack = (trackId: string) =>
  studioService
    .get(`/tracks/${trackId}`, { toastError: true })
    .then((res: AxiosResponse<ITrack>) => res.data);

export const getGlobalTrack = (trackId: string) =>
  studioService
    .get(`/global-tracks/${trackId}`, { toastError: true })
    .then((res: AxiosResponse<ITrack>) => res.data);

export const getSharedTrack = (trackId: string) =>
  studioService
    .get(`/tracks/${trackId}/sections?card=sharing`, { toastError: true })
    .then((res: AxiosResponse<IUseSharedTrackData>) => res.data.track);

export const getPlaylistTracks = (playList: ITrack[]) => {
  const { globalTrackIds, myTrackIds } = playList.reduce(
    (acc, track) => {
      if (isGlobalTrack(track)) {
        acc.globalTrackIds.push(track.id);
      } else {
        acc.myTrackIds.push(track.id);
      }

      return acc;
    },
    { globalTrackIds: [] as string[], myTrackIds: [] as string[] },
  );

  const globalTracks = studioService
    .get(
      `/global-tracks?card=summary&pageSize=500&start=0&criteria=${encodeURIComponent(
        JSON.stringify([{ key: 'id', function: 'in', value: globalTrackIds }]),
      )}`,
      { toastError: false },
    )
    .then((res: AxiosResponse<ITracks>) => res.data);

  const userTracks = studioService
    .get(
      `/tracks?card=summary&pageSize=500&start=0&criteria=${encodeURIComponent(
        JSON.stringify([{ key: 'id', function: 'in', value: myTrackIds }]),
      )}`,
      { toastError: false },
    )
    .then((res: AxiosResponse<ITracks>) => res.data);

  return Promise.all([globalTracks, userTracks]).then(([globals, user]) => {
    const fetchedTracks = [...(globals.data || []), ...(user.data || [])];

    return playList.map((track) => fetchedTracks.find(({ id }) => id === track.id) || track);
  });
};

export const createTrack = (body: ICreateTrackBody) =>
  studioService
    .post('/tracks/preview', body, {
      remoteLogProps: { details: body as unknown as Dict<unknown> },
      toastError: true,
    })
    .then((res: AxiosResponse<ITrack>) => res.data);

const SKIP_THRESHOLD_CHECK_PARAM = 'stc';

export enum SaveTrackErrorKeys {
  ThrottleLimitReached = 'saveLimitThresholdExceeded',
  LimitReached = 'saveLimitExceeded',
}

export const saveTrack = async ({
  trackId,
  body,
  signal,
  skipLimitCheck,
  isEditMode,
}: {
  trackId: string;
  body: ISaveTrackBody;
  signal: AbortSignal;
  skipLimitCheck: boolean;
  isEditMode: boolean;
}): Promise<ITrack> => {
  const query = new URLSearchParams({ mode: isEditMode ? 'edit' : 'create' });

  if (skipLimitCheck) {
    query.set(SKIP_THRESHOLD_CHECK_PARAM, String(true));
  }

  const result = await studioService.patch<ITrack>(`/tracks/${trackId}?${query.toString()}`, body, {
    toastError: false,
    skipRemoteLog: true,
    signal,
  });

  return result.data;
};

export const deleteTrack = (trackId: string) =>
  studioService
    .delete(`/tracks/${trackId}`, { toastError: true })
    .then((res: AxiosResponse<ITrack>) => {
      toastSuccess(i18n.t('creations.deleteSuccessful'));

      return res.data;
    });

export const postRecentTrack = (trackId: string) =>
  studioService.post('/tracks/recents', { trackId });

export const addToFavorites = (trackId: string) =>
  studioService
    .post('/tracks/favorites', { trackId }, { toastError: false })
    .then((res: AxiosResponse<ITrack>) => res.data);

export const removeFromFavorites = (trackId: string) =>
  studioService
    .delete(`/tracks/favorites/${trackId}`, { toastError: false })
    .then((res: AxiosResponse<ITrack>) => res.data);

export const fetchTemplateTracks = (templateId: number): Promise<ITracks> => {
  const endpoint = '/global-tracks';
  const query = '?pageSize=10&start=0&card=summary&sortDirection=ASC&criteria=';
  const criteria = [{ key: 'preview.template.id', function: 'eq', value: templateId }];
  const url = `${endpoint}${query}${encodeUri(criteria)}`;

  return mutate(url, (cached?: ITracks) => {
    if (cached) {
      return cached;
    }

    return studioLoudFetcher(url).catch(noop);
  });
};

export const fetchGenreTracks = (genreId: number): Promise<ITracks> => {
  const endpoint = '/global-tracks';
  const query = '?pageSize=10&start=0&card=summary&sortDirection=ASC&criteria=';
  const criteria = [{ key: 'preview.genre.id', function: 'eq', value: genreId }];
  const url = `${endpoint}${query}${encodeUri(criteria)}`;

  return mutate(url, (cached?: ITracks) => {
    if (cached) {
      return cached;
    }

    return studioLoudFetcher(url).catch(noop);
  });
};

export const getTrackLogProps = (track: ITrack, args?: Dict<unknown>) => ({
  details: {
    trackId: track.id,
    trackName: track.name,
    ...args,
  },
});

export enum RenderTrackErrorKeys {
  RenderLimitExceeded = 'renderLimitExceeded',
}

type TRenderTrackProps = {
  track: ITrack;
  type: RenderType;
  soundCloudAutoUpload?: boolean;
  renderAbleton?: boolean;
};

type TRenderTrackBody = {
  type: RenderType;
  soundcloudAutoUpload?: true;
  renderAbleton?: true;
};

export const renderTrack = ({
  track,
  type,
  soundCloudAutoUpload,
  renderAbleton,
}: TRenderTrackProps) =>
  studioService.post(
    `/tracks/${track.id}/render`,
    {
      type,
      soundcloudAutoUpload: soundCloudAutoUpload || undefined,
      renderAbleton: renderAbleton || undefined,
    } satisfies TRenderTrackBody,
    { skipRemoteLog: true, toastError: false },
  );

export const renderAbletonTrack = (trackId: string) =>
  studioService.post(`/tracks/${trackId}/render-ableton`, undefined, {
    remoteLogProps: { category: 'RENDER_ABLETON' },
  });

export const requestTrackDownloadPaths = (
  track: ITrack,
  formats: TDownloadFormat[],
): Promise<AxiosResponse<IDownloadPackPaths>> =>
  studioService.post(
    `/tracks/${track.id}/download`,
    { formats },
    { remoteLogProps: getTrackLogProps(track, { formats }), toastError: true },
  );

export const shareTrack: TRequest<ITrack & { publicUrl: string }, string> = (trackId: string) =>
  studioService.patch(`/tracks/${trackId}`, { published: true }, { toastError: true });

export const sendTrackByEmail = (trackId: string, emails: string[], authenticated: boolean) => {
  const service = authenticated ? studioService : publicStudioService;

  return service.post(`/tracks/${trackId}/share`, { recipients: emails });
};
