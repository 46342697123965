import { createContext, useState, useMemo, useContext, type ReactNode } from 'react';

import { getMetaFromToken } from '@/utils/tokenUtils';

import { Loader } from '@/shared/loader/Loader';

import type { TAction } from '@/types/common';

import { allSettled } from '@/compatibility/promise';

import type { IAuthContext } from './types';

export const AuthContext = createContext({} as IAuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [, forceUpdate] = useState({});

  const auth = useMemo(() => {
    let userMeta = getMetaFromToken();

    let isAuthenticated = Boolean(userMeta.userId);
    let isAuthRequested = false;

    const hooks = {
      before: [] as TAction[],
      after: [] as TAction[],
    };

    const getState = () => ({
      inProgress: userMeta.userId && !isAuthenticated,
      isAuthenticated,
      isAuthRequested,
      ...userMeta,
    });

    const updateToken = () => {
      userMeta = getMetaFromToken();
      forceUpdate({});
    };

    const cancelAuth = () => {
      hooks.before = [];
      hooks.after = [];

      isAuthRequested = false;
      forceUpdate({});
    };

    const requestAuth = (hook: TAction, schedule: 'before' | 'after' = 'before') => {
      hooks[schedule].push(hook);

      isAuthRequested = true;
      forceUpdate({});

      return cancelAuth;
    };

    const confirmAuth = async () => {
      updateToken();

      await allSettled(hooks.before.map((hook) => hook()));
      isAuthenticated = true;

      hooks.after.forEach((hook) => hook());
      cancelAuth();
    };

    return {
      inProgress: !!userMeta.userId && !isAuthenticated,
      requestAuth,
      cancelAuth,
      confirmAuth,
      updateToken,
      getState,
    };
  }, []);

  return (
    // If value doesn't change (the purpose of destructuring) the children won't re-render
    <AuthContext.Provider value={{ ...auth }}>
      {children}
      {auth.getState().inProgress && <Loader />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const { getState, ...auth } = useContext(AuthContext);

  return {
    ...auth,
    ...getState(),
  };
};
