import { useEffect, useState } from 'react';

import { noop } from '@/utils/functionalUtils';

import { DEACTIVATE_IF_USER_CANCELS } from '@/components/versionUpdater/config';
import { useConfigurablePollingInterval } from '@/components/versionUpdater/useConfigurablePollingInterval';
import { useConfirmationDialog } from '@/shared/confirmationDialog/useConfirmationDialog';

import type { TAction } from '@/types/common';

import { trackEvent, ActionEvents } from '@/tracking';

import { startWatching } from './startWatching';

export const NewVersionSentinel = () => {
  const [isActive, setIsActive] = useState(true);
  const { ConfirmationDialog, showConfirmationDialog } = useConfirmationDialog();

  const interval = useConfigurablePollingInterval();

  useEffect(() => {
    if (!isActive) {
      return noop;
    }

    return startWatching(interval, () => {
      const minutesSincePageOpen = Math.round(performance.now() / 60) / 1000;
      const track = (action: 'Later' | 'Refresh', callback?: TAction) =>
        trackEvent(
          ActionEvents.newVersionDialogAction,
          {
            'Minutes Since Page Open': minutesSincePageOpen,
            Action: action,
          },
          { send_immediately: action === 'Refresh' },
          callback,
        );

      setIsActive(false);
      showConfirmationDialog({
        title: 'newVersionSentinel.dialog.title',
        body: 'newVersionSentinel.dialog.body',
        cancel: 'newVersionSentinel.dialog.cancel',
        confirm: 'newVersionSentinel.dialog.confirm',
        onConfirm: () => {
          const refresh = () => window.location.reload();

          // Make sure we do refresh even if `track` fails for some reason
          track('Refresh', refresh);
          setTimeout(refresh, 300);
        },
        onCancel: () => {
          track('Later');
          setIsActive(!DEACTIVATE_IF_USER_CANCELS);
        },
      });
    });
  }, [interval, isActive, showConfirmationDialog]);

  return <ConfirmationDialog />;
};
