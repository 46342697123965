export enum TrackType {
  global = 'GLOBAL',
  user = 'USER',
}

export enum ContentType {
  track = 'TRACK',
  loop = 'LOOP',
}

export enum TrackActivity {
  play = 'TRACK_PLAYED',
  share = 'TRACK_SHARED',
}

export enum TrackSharingType {
  copy = 'COPY',
  email = 'EMAIL',
  whatsApp = 'WHATSAPP',
  facebook = 'FACEBOOK',
  twitter = 'TWITTER',
  reddit = 'REDDIT',
}
