import { debounce, noop } from '@/utils/functionalUtils';

import { trackEvent } from '@/tracking';
import { type GlobalSearchSources, GlobalSearchCategories, ActionEvents } from '@/tracking/config';

import { TRACK_EVENT_DELAY_TIME } from './constants';

const trackSearch = (
  search: string | undefined,
  source: GlobalSearchSources,
  category: GlobalSearchCategories,
  subCategory: string,
  isFound?: boolean,
): void => {
  trackEvent(ActionEvents.globalSearch, {
    'Searched Text': search || '',
    'From Page': source,
    Category: category,
    SubCategory: subCategory,
    Found: isFound,
  });
};

const trackSearchDebounced = debounce(trackSearch, TRACK_EVENT_DELAY_TIME);

// As call of `debounce`-d function returns promise,
// make sure tracker function returns `void`
// in order to avoid blocking API call from executing.
// Tracker code should be synchronous and should not affect normal flow.
const trackSearchDebouncedSync: typeof trackSearch = (
  search,
  source,
  category,
  subCategory,
  isFound?,
) => {
  trackSearchDebounced(
    search,
    source,
    category ?? GlobalSearchCategories.all,
    subCategory,
    isFound,
  ).then(noop);
};

export { trackSearchDebouncedSync as trackSearch };
