export const loadGA = () => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return;
  }

  // Global Site Tag (gtag.js) - Google Analytics
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${window.env.GA_MEASUREMENT_ID}`;
  document.body.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };
  window.gtag('js', new Date());
  window.gtag('config', window.env.GA_MEASUREMENT_ID, { debug_mode: window.env.env === 'dev' });
};

type GTagEvent = {
  action: string;
  category: string;
  label?: string;
  value?: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export function trackGA4Event({ action, category, label, value }: GTagEvent) {
  if (typeof window.gtag === 'function') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
}

export const loadTagManager = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  const GTM_CONTAINER_ID = 'GTM-MD5THKF';
  const script = document.createElement('script');
  const noScript = document.createElement('noscript');

  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_CONTAINER_ID}`;

  noScript.innerText = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_CONTAINER_ID}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;

  document.head.insertBefore(script, document.head.childNodes[0]);
  document.body.insertBefore(noScript, document.body.childNodes[0]);
};

export const loadReCaptcha = () => {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.src = `https://www.google.com/recaptcha/api.js?render=${window.env.CAPTCHA_V3_KEY}`;

  document.body.append(script);
};
