import { useEffect, useMemo } from 'react';
import { useDispatch, useStore } from 'react-redux';

import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';

import { useAuth } from '@/contexts/AuthProvider';
import { useToggleFavoriteTemplateMutator } from '@/hooks/favorites/useToggleFavoriteTemplateMutator';
import { useToggleFavoriteTrackMutator } from '@/hooks/favorites/useToggleFavoriteTrackMutator';
import { mutateNotificationPreferences } from '@/hooks/profile/useNotificationPreferences';
import { useMutateWithRevalidation } from '@/hooks/synchronizer/useMutateWithRevalidation';
import { useDeleteTrackMutator } from '@/hooks/tracks/useDeleteTrackMutator';
import { useRecentsMutator } from '@/hooks/tracks/useRecentsMutator';
import { useMatchMutate } from '@/hooks/useMatchMutate';

import { ProductType } from '@/enums/profile';
import { logout } from '@/utils/authUtils';
import { getMetaFromToken } from '@/utils/tokenUtils';

import { stepsCountAtom } from '@/components/onboardingStepsCountAtom';
import { useSetSoundCloudSignUpTokens } from '@/components/soundCloud/signUpAtoms';

import type { TPremiumPlusTemplateMetricsResponse } from '@/types/premiumPlus';

import { getPaymentMethodsAction } from '@/store/payment/payment.actions';
import {
  addReferralEmail,
  changeUserPhoto,
  getUserData,
  sendConfirmVerifyEmail,
  updateUserInfo,
} from '@/store/profile/profile.actions';
import { selectIsEmailVerified } from '@/store/profile/profile.selectors';
import { setSubscriptionInfo } from '@/store/subscription/subscription.actions';

import { useUpdateTrack } from '../tracks/useUpdateTrack';

import { subscribe, unsubscribe } from './synchronizer';
import type { TAction, TActionHandler, TSyncConfig } from './types';

export const useSynchronizer = () => {
  const dispatch = useDispatch();
  const store = useStore();

  const matchMutate = useMatchMutate();
  const matchMutateWithRevalidation = useMutateWithRevalidation();
  const { updateTrack, updateAllTracks } = useUpdateTrack();
  const deleteTrack = useDeleteTrackMutator();

  const { confirmAuth, updateToken } = useAuth();
  const mutateFavoriteTemplate = useToggleFavoriteTemplateMutator();
  const { mutateFavoriteTrack, invalidateFavoriteTracks } = useToggleFavoriteTrackMutator();
  const mutateRecents = useRecentsMutator();
  const setSoundCloudSignUpTokens = useSetSoundCloudSignUpTokens();
  const setOnboardingStepsCount = useSetAtom(stepsCountAtom);

  const syncConfig: TSyncConfig = useMemo(
    () => ({
      login: confirmAuth,
      logout: () => {
        setSoundCloudSignUpTokens(RESET);
        setOnboardingStepsCount(RESET);
        logout();
      },
      updateToken,
      updateUserDetails: ({ details }) => dispatch(updateUserInfo.success(details)),
      updateTrack: ({ track }) => updateTrack(track),
      updateAllTracks,
      deleteTrack: ({ trackId }) => deleteTrack(trackId),
      mutateFavoriteTemplate: ({ template, favorite }) =>
        mutateFavoriteTemplate(template, favorite),
      mutateFavoriteTrack: ({ track, favorite }) => mutateFavoriteTrack(track, favorite),
      invalidateFavoriteTracks,
      invalidateUserMetrics: () => matchMutate(/\?sections=METRICS/),
      invalidateCreations: () => matchMutate(/\/tracks\?card=summary/),
      invalidateTracksMetrics: () => matchMutate(/tracks\/metrics/),
      invalidateAfterEmailVerification: ({ status }) => {
        const userMeta = getMetaFromToken();
        const isAuthenticated = Boolean(userMeta.userId);

        if (!isAuthenticated) {
          return;
        }

        const isEmailVerified = selectIsEmailVerified(store.getState());

        if (status === 'justVerified' || !isEmailVerified) {
          matchMutate(/\?sections=METRICS/);
          dispatch(getUserData.request());
          dispatch(getPaymentMethodsAction.request());
        }
      },
      mutateRecents: ({ track }) => mutateRecents(track),
      updateUserPhoto: ({ userData, imagePath }) =>
        dispatch(changeUserPhoto.success({ ...userData, imagePath })),
      addReferralEmail: ({ referral }) => dispatch(addReferralEmail(referral)),
      updateSocialLinks: () => matchMutate(/social/),
      updateNotificationPreferences: ({ key, enabled }) =>
        mutateNotificationPreferences(key, enabled),
      updateSubscriptionInfo: ({ subscriptionInfo }) =>
        dispatch(setSubscriptionInfo.success(subscriptionInfo)),
      updatePaymentMethods: ({ paymentMethods }) =>
        dispatch(getPaymentMethodsAction.success(paymentMethods)),
      updateEmailVerification: ({ status }) => dispatch(sendConfirmVerifyEmail.success(status)),
      invalidateDownloads: () => matchMutate(/tracks\/purchases/),
      purchase: ({ products }) => {
        const mutations = [];

        if (products.includes(ProductType.collaboration)) {
          mutations.push(
            matchMutateWithRevalidation<TPremiumPlusTemplateMetricsResponse>(
              /pay-to-use-metrics/,
              (arg1, arg2) => arg1.metrics.length === arg2.metrics.length,
            ),
          );
        }

        if (products.includes(ProductType.subscription) || products.includes(ProductType.bundle)) {
          mutations.push(matchMutate(/\?sections=METRICS/));
        }

        if (products.includes(ProductType.subscription)) {
          mutations.push(updateAllTracks());
          dispatch(getUserData.request());
        }

        return Promise.all(mutations);
      },
      invalidatePayToUseMetrics: () => matchMutate(/pay-to-use-metrics/),
    }),
    [
      confirmAuth,
      updateToken,
      updateAllTracks,
      invalidateFavoriteTracks,
      setSoundCloudSignUpTokens,
      setOnboardingStepsCount,
      dispatch,
      updateTrack,
      deleteTrack,
      mutateFavoriteTemplate,
      mutateFavoriteTrack,
      matchMutate,
      store,
      mutateRecents,
      matchMutateWithRevalidation,
    ],
  );

  useEffect(() => {
    Object.entries(syncConfig).forEach(([action, handler]) => {
      subscribe(action as TAction, handler as TActionHandler);
    });

    return () => {
      Object.keys(syncConfig).forEach((key) => {
        unsubscribe(key as keyof TSyncConfig);
      });
    };
  }, [syncConfig]);
};
