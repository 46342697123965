export const makeMutator = <PData>(path: string, update: (data: PData) => PData) => {
  return (cache?: Record<string, PData>) => {
    if (!cache?.[path]) {
      return cache;
    }

    const updatedCache = update(cache?.[path]);

    return { ...cache, [path]: updatedCache };
  };
};
