import { getCaptchaParams } from '@/utils/captchaUtils/captchaUtils';
import { getLocallyStoredRefreshToken } from '@/utils/tokenUtils';

import { type TRequest } from '@/types/common';
import type { TGetTokenResponse, TSignInPayload } from '@/types/profile';

import { authService } from '@/api/common';

const REQUEST_TOKEN_OPTIONS = {
  client_id: 'web',
  client_secret: 'secret',
};

export const refreshAccessToken = () =>
  authService.post(
    '/token',
    new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: getLocallyStoredRefreshToken(),
      ...REQUEST_TOKEN_OPTIONS,
    }),
  );

export const signInUser: TRequest<TGetTokenResponse, TSignInPayload> = async ({
  captcha,
  ...payload
}) =>
  authService.post(
    '/token',
    new URLSearchParams({
      grant_type: 'password',
      ...REQUEST_TOKEN_OPTIONS,
      ...payload,
    }),
    {
      params: await getCaptchaParams(captcha),
    },
  );
