import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import { sleep, noop } from '@/utils/functionalUtils';

import type { TRequestConfigured } from '@/types/common';

import type { TWithRetryOptions, TGetDelay } from './types';

const defaultGetDelay: TGetDelay = (attempt, baseDelay) => baseDelay * attempt;

export const withRetry = <PRes, PReq>(
  request: TRequestConfigured<PRes, PReq, AxiosRequestConfig>,
  {
    maxAttemptsCount,
    baseDelay,
    onRetrying = noop,
    onRetrySucceeded = noop,
    getDelay = defaultGetDelay,
    getRequestConfig = () => undefined,
  }: TWithRetryOptions<PRes>,
): TRequestConfigured<PRes, PReq, AxiosRequestConfig> => {
  // Just to avoid guessing was it 0 or 1
  const FIRST_ATTEMPT = 1;

  const executeWithRetry = async (
    attempt: number,
    args: PReq,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<PRes>> => {
    try {
      const result = await request(args, {
        skipRemoteLog: attempt < maxAttemptsCount,
        ...getRequestConfig(attempt),
        ...config,
      });

      if (attempt > FIRST_ATTEMPT) {
        onRetrySucceeded(attempt, result);
      }

      return result;
    } catch (error) {
      if (attempt < maxAttemptsCount) {
        onRetrying(attempt, error);

        await sleep(getDelay(attempt, baseDelay));

        return executeWithRetry(attempt + 1, args, config);
      }

      throw error;
    }
  };

  return async (args, config) => {
    const result = await executeWithRetry(FIRST_ATTEMPT, args, config);

    return result;
  };
};
