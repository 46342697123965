import { useSelector } from 'react-redux';

import type { Dict } from '@/types/common';

import { selectCurrentPlanTitle } from '@/store/subscription/subscription.selectors';

import { ActionEvents } from './config';
import { trackEvent } from './trackEvents';

export const useTrackUserEvents = () => {
  const planTitle = useSelector(selectCurrentPlanTitle);

  if (!planTitle) {
    return { trackChangeInPageEvent: null, trackEventWithSubscriptionData: null };
  }

  const trackEventWithSubscriptionData = (eventName: string, properties?: Dict<unknown>) => {
    trackEvent(eventName, {
      existingSubscription: planTitle,
      ...properties,
    });
  };

  const trackChangeInPageEvent = (pageTo: string, properties?: Dict<unknown>) => {
    trackEvent(ActionEvents.changeInPage, {
      pageFrom: window.location.pathname,
      pageTo,
      existingSubscription: planTitle,
      ...properties,
    });
  };

  return { trackChangeInPageEvent, trackEventWithSubscriptionData };
};
