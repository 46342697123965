import { type Nullable } from '@/types/common';

import { initialAsyncState } from '@/store/async/initialState';
import {
  type ISearchedTracks,
  type TGenre,
  type TMoodsOrArtist,
  type TSearchedTemplatesAndTracks,
  type TSearchStore,
  type TTag,
  type TTemplateItem,
} from '@/store/search/types';

export const initialState: TSearchStore = {
  moods: initialAsyncState<Nullable<TMoodsOrArtist[]>>(null),
  artists: initialAsyncState<Nullable<TMoodsOrArtist[]>>(null),
  genres: initialAsyncState<Nullable<TGenre[]>>(null),
  tags: initialAsyncState<Nullable<TTag[]>>(null),
  searchedData: initialAsyncState<Nullable<TSearchedTemplatesAndTracks>>(null),
  templates: initialAsyncState<Nullable<TTemplateItem[]>>(null),
  searchedTracks: initialAsyncState<Nullable<ISearchedTracks>>(null),
  searchedTags: initialAsyncState<Nullable<TTag[]>>(null),
};
