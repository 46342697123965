import { BroadcastChannel } from 'broadcast-channel';

import type { Dict, Nullable } from '@/types/common';

import type { TAction, TSync, TMessage, TActionHandler } from './types';

const synchronizer = new BroadcastChannel('synchronizer');

const subscriptions: Dict<Nullable<TActionHandler>> = {};

export const subscribe = (action: TAction, handler: TActionHandler) => {
  subscriptions[action] = handler;
};

export const unsubscribe = (action: TAction) => {
  subscriptions[action] = null;
};

const handleMessage = ({ action, payload }: TMessage) => {
  const actionHandler = subscriptions[action];

  if (actionHandler) {
    actionHandler(payload);
  }
};

export const sync: TSync = (action, payload) => {
  const message = { action, payload };
  handleMessage(message);
  synchronizer.postMessage(message);
};

synchronizer.addEventListener('message', handleMessage);
