import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider, type SnackbarProviderProps } from 'notistack';

import { useTheme } from '@/contexts/ThemeContextProvider';

import { Alert } from '@/shared/icons/Alert';
import { CheckFilled } from '@/shared/icons/CheckFilled';
import { Info } from '@/shared/icons/Info';
import { InfoFilled } from '@/shared/icons/InfoFilled';
import { NotificationView } from '@/shared/notifications/views/NotificationView';

import type { TNotificationsProviderProps } from './types';

export const useStyles = makeStyles(
  (theme) => ({
    default: {
      top: theme.spacing(10),
    },
  }),
  { name: 'NotificationsProvider' },
);

const notificationsComponents: SnackbarProviderProps['Components'] = {
  error: NotificationView,
  info: NotificationView,
  success: NotificationView,
};

export const NotificationsProvider = ({ children }: TNotificationsProviderProps) => {
  const classes = useStyles();
  const { isDarkMode } = useTheme();

  const InfoIcon = isDarkMode ? InfoFilled : Info;

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        containerRoot: classes.default,
      }}
      iconVariant={{
        success: <CheckFilled />,
        error: <Alert />,
        info: <InfoIcon />,
      }}
      maxSnack={4}
      Components={notificationsComponents}
      autoHideDuration={10000}
    >
      {children}
    </SnackbarProvider>
  );
};
