import type { AxiosRequestConfig } from 'axios';
import useSWR, { type SWRConfiguration } from 'swr';

import { swrOptions } from '@/config/requests';

import type { Nullable } from '@/types/common';

import * as PrivateApis from '@/api/private';
import * as PublicApis from '@/api/public';

type TService = keyof typeof Apis;

const Apis = { ...PrivateApis, ...PublicApis };

const fetcher = <PData>(
  url: string,
  service: TService,
  silent: boolean,
  requestConfig?: Partial<AxiosRequestConfig>,
) =>
  Apis[service].get<PData>(url, { toastError: !silent, ...requestConfig }).then((res) => res.data);

// Be careful with `requestConfig` prop. If it's recreated on each render, SWR will enter infinite loop.
export const useFetch = <PData, PError = Error>(
  url: Nullable<string>,
  service: TService,
  swrOverrides: SWRConfiguration<PData, PError> = {},
  silent = false,
  requestConfig?: Partial<AxiosRequestConfig>,
) =>
  useSWR<PData, PError>(url === null ? null : [url, service, silent, requestConfig], fetcher, {
    ...swrOptions,
    ...swrOverrides,
  });
