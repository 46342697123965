import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      minWidth: 'min(416px, 90%)',
      maxWidth: '500px',
      backgroundColor: theme.palette.secondary.dark,
      backgroundImage: 'none',
      borderRadius: theme.spacing(1),
    },

    title: {
      padding: theme.spacing(3, 3, 5),
    },

    buttons: {
      padding: theme.spacing(3),
      justifyContent: 'center',
    },
  }),
  { name: 'ConfirmationDialog' },
);
