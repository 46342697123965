import { LOCAL } from '@/constants';
import { hasFeature } from '@/utils/featureFlag';

import { RemoteLogger } from './RemoteLogger';
import { syncCorrelationId, experimentalNetworkCondition } from './transform';
import { createLogglyTransport, createVerboseTransport, combineTransports } from './transports';
import type { ILogTransport } from './types';

const SERVICE = 'sf-ui';
const ENV = window.env.env;
const ENABLE_REMOTE_LOGGING = !LOCAL;
const { LOGGLY_TOKEN } = window.env;

const transports: ILogTransport[] = [];

if (ENABLE_REMOTE_LOGGING && LOGGLY_TOKEN) {
  transports.push(
    createLogglyTransport({
      key: LOGGLY_TOKEN,
      service: SERVICE,
      environment: ENV,
    }),
  );
}

if (hasFeature('verboseEventsTracking')) {
  transports.push(createVerboseTransport());
}

const transport = combineTransports(...transports);

export const remoteLogger = new RemoteLogger(
  transport,
  {
    trackUnhandledErrors: true,
    trackUnhandledRejections: true,
    ignoreUnhandledError(errorEvent) {
      if (errorEvent.message === 'Script error.') {
        return true;
      }

      const stack = errorEvent.error?.stack as string | undefined;

      if (stack) {
        if (stack.includes('extension://')) {
          return true;
        }

        if (
          stack.includes('js.hscollectedforms.net') ||
          stack.includes('js.hubspot.com') ||
          stack.includes('js.hs-banner.com')
        ) {
          return true;
        }
      }

      if (
        'exception' in errorEvent &&
        errorEvent.exception &&
        typeof errorEvent.exception === 'object'
      ) {
        if (
          'message' in errorEvent.exception &&
          typeof errorEvent.exception.message === 'string' &&
          errorEvent.exception.message.startsWith('ResizeObserver loop')
        ) {
          return true;
        }
      }

      return false;
    },
    ignoreUnhandledRejection(promiseRejectionEvent) {
      // noinspection RedundantIfStatementJS
      if (!promiseRejectionEvent.reason?.message) {
        return true;
      }

      return false;
    },
  },
  [syncCorrelationId, experimentalNetworkCondition],
);
