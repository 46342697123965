import { useCallback, useState } from 'react';

import { ConfirmationDialog } from '@/shared/confirmationDialog/ConfirmationDialog';

import { type TTranslateKey } from '@/types/i18n';

export interface IConfirmationDialogOptions {
  isOpen: boolean;
  title: TTranslateKey;
  body: TTranslateKey;
  checkbox: TTranslateKey;
  confirm: TTranslateKey;
  cancel: TTranslateKey;
  showCancel: boolean;
  showCheckbox: boolean;
  onCancel: () => void;
  onConfirm: (isChecked?: boolean) => void;
}

const defaultOptions: IConfirmationDialogOptions = {
  isOpen: false,
  title: 'dialog.confirmationHeader',
  body: 'dialog.confirmationBody',
  checkbox: 'dialog.dontShowAgain',
  cancel: 'dialog.cancel',
  confirm: 'dialog.confirm',
  showCancel: true,
  showCheckbox: false,
  onCancel: () => {},
  onConfirm: () => {},
};

export const useConfirmationDialog = () => {
  const [options, setOptions] = useState<IConfirmationDialogOptions>(defaultOptions);

  const handleCancel = useCallback(() => {
    setOptions({ ...options, isOpen: false });
    options.onCancel();
  }, [options]);

  const handleConfirm = useCallback(
    (isChecked?: boolean) => {
      setOptions({ ...options, isOpen: false });

      if (options.showCheckbox) {
        options.onConfirm(isChecked);
      } else {
        options.onConfirm();
      }
    },
    [options],
  );

  const showConfirmationDialog = useCallback((newOptions: Partial<IConfirmationDialogOptions>) => {
    setOptions({ ...defaultOptions, ...newOptions, isOpen: true });
  }, []);

  const Dialog = useCallback(
    () => <ConfirmationDialog {...options} onCancel={handleCancel} onConfirm={handleConfirm} />,
    [options, handleCancel, handleConfirm],
  );

  return { ConfirmationDialog: Dialog, showConfirmationDialog };
};
