export const CDN_URL = window.env.CDN_URL || '';

export const CDN_PROXY_URL = '/cdn/';

export const SF_HELP = 'https://help.soundful.com/';

export const SF_PRIVACY_POLICY = 'https://soundful.com/privacy-policy';

export const SF_TERMS_AND_CONDITIONS = 'https://soundful.com/terms-and-conditions';

export const SF_LICENSE = 'https://soundful.com/license/';

export const SF_COPYRIGHT_INFO =
  'https://help.soundful.com/knowledge/what-is-the-process-of-purchasing-copyright';

export const authServer = `${window.env.API_HOST}/auth`;

export const profileServer = `${window.env.API_HOST}/profile-management/users`;

export const rakutenURL = `${window.env.API_HOST}/profile-management/rakuten`;

export const plansServer = `${window.env.API_HOST}/profile-management/plans`;

export const notificationTemplateUrl = `${window.env.API_HOST}/profile-management/users/notification/template`;

export const publicNotificationTemplateUrl = `${window.env.API_HOST}/profile-management/users/public/notification/template`;

export const personaServer = `${window.env.API_HOST}/profile-management/persona`;

export const studioURL = `${window.env.API_HOST}/studio`;

export const publicStudioURL = `${window.env.API_HOST}/studio/public`;

export const editorUrl = `${window.env.API_HOST}/original/editor`;

export const socialAuthApi = `${window.env.API_HOST}/auth/authorize`;

export const notificationsSocketUrl = window.env.WEBSOCKET_SERVER_URL || '';

export const REQUEST_COPYRIGHT_FORM_URL = 'https://share.hsforms.com/1ORH3cTwpSl-02wg0XQ-GTwbsj76';
