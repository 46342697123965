import { call, put, debounce } from 'redux-saga/effects';

import {
  getGenres,
  getTags,
  getSearchedDataByText,
  getSearchedAllTemplatesAndTracks,
  getSearchedTemplates,
  getSearchedTracks,
  getMoodsOrArtists,
} from '@/services/search';

import { GlobalSearchCategories } from '@/tracking/config';

import { DELAY_TIME, PAGE_SIZE } from './constants';
import {
  getGenresAction,
  getSearchedDataAction,
  getSearchedTagsAction,
  getTemplatesAction,
  getSearchedTracksAction,
  getTagsAction,
  getMoodsAction,
  getArtistsAction,
} from './search.actions';
import { trackSearch } from './track';
import type {
  TFetchGenres,
  TFetchSearchedData,
  TFetchSearchedTags,
  TSearchedTemplatesAndTracks,
  TFetchTags,
  TFetchTemplates,
  TFetchTracks,
  TFetchMoods,
  TFetchArtists,
} from './types';

const isSearchResult = (data: TSearchedTemplatesAndTracks) => {
  const { globalTracks, templates, userTracks } = data;

  return (
    Boolean(globalTracks?.content?.length) ||
    Boolean(templates?.content?.length) ||
    Boolean(userTracks?.content?.length)
  );
};

function* fetchMoods({ payload: { search, source, category } }: TFetchMoods) {
  try {
    const { data } = yield call(() => getMoodsOrArtists({ search, category }));
    yield call(
      trackSearch,
      search,
      source,
      GlobalSearchCategories.moods,
      '',
      Boolean(data?.data?.length),
    );

    yield put(getMoodsAction.success(data.data));
  } catch (e) {
    yield put(getMoodsAction.failure());
  }
}

function* fetchArtists({ payload: { search, source, category } }: TFetchArtists) {
  try {
    const { data } = yield call(() => getMoodsOrArtists({ search, category }));
    yield call(
      trackSearch,
      search,
      source,
      GlobalSearchCategories.artists,
      '',
      Boolean(data?.data?.length),
    );

    yield put(getArtistsAction.success(data.data));
  } catch (e) {
    yield put(getArtistsAction.failure());
  }
}

function* fetchGenres({ payload: { search, source } }: TFetchGenres) {
  try {
    const { data } = yield call(() => getGenres({ search }));
    yield call(
      trackSearch,
      search,
      source,
      GlobalSearchCategories.genres,
      '',
      Boolean(data?.data?.length),
    );

    yield put(getGenresAction.success(data.data));
  } catch (e) {
    yield put(getGenresAction.failure());
  }
}

function* fetchTags({ payload: { search, source } }: TFetchTags) {
  try {
    const { data } = yield call(() => getTags({ search }));
    yield call(
      trackSearch,
      search,
      source,
      GlobalSearchCategories.tags,
      '',
      Boolean(data?.data?.length),
    );

    yield put(getTagsAction.success(data.data));
  } catch (e) {
    yield put(getTagsAction.failure());
  }
}

function* fetchSearchedTags({
  payload: { id, keyId, searchText, source, subCategory },
}: TFetchSearchedTags) {
  try {
    if (keyId && id) {
      const { data } = yield call(() => getSearchedDataByText({ keyId, id, searchText }));
      yield call(
        trackSearch,
        searchText,
        source,
        GlobalSearchCategories.tags,
        subCategory,
        isSearchResult(data),
      );

      yield put(getSearchedDataAction.success(data));
      yield put(getSearchedTagsAction.success());
    }

    if (searchText && !keyId && !id) {
      const { data } = yield call(() => getSearchedAllTemplatesAndTracks({ search: searchText }));
      yield call(
        trackSearch,
        searchText,
        source,
        GlobalSearchCategories.tags,
        '',
        isSearchResult(data),
      );

      yield put(getSearchedDataAction.success(data));
      yield put(getSearchedTagsAction.success());
    }
  } catch (e) {
    yield put(getSearchedTagsAction.failure());
  }
}

function* fetchSearchedData({
  payload: { keyId, searchText, id, source, category, subCategory },
}: TFetchSearchedData) {
  try {
    if (keyId && id) {
      const { data } = yield call(() => getSearchedDataByText({ keyId, searchText, id }));
      yield call(trackSearch, searchText, source, category, subCategory, isSearchResult(data));

      yield put(getSearchedDataAction.success(data));
    }

    if (searchText && !keyId && !id) {
      const { data } = yield call(() => getSearchedAllTemplatesAndTracks({ search: searchText }));
      yield call(trackSearch, searchText, source, category, subCategory, isSearchResult(data));

      yield put(getSearchedDataAction.success(data));
    }
  } catch (e) {
    yield put(getSearchedDataAction.failure());
  }
}

function* fetchSearchedTemplates({ payload: { search, source } }: TFetchTemplates) {
  try {
    const { data } = yield call(() =>
      getSearchedTemplates({ searchText: search, pageSize: PAGE_SIZE }),
    );
    yield call(
      trackSearch,
      search,
      source,
      GlobalSearchCategories.templates,
      '',
      Boolean(data?.length),
    );

    yield put(getTemplatesAction.success(data));
  } catch (e) {
    yield put(getTemplatesAction.failure());
  }
}

function* fetchSearchedTracks({ payload: { search, source, trackType } }: TFetchTracks) {
  try {
    const { data } = yield call(() => getSearchedTracks({ searchText: search, trackType }));
    yield call(
      trackSearch,
      search,
      source,
      GlobalSearchCategories.tracks,
      '',
      Boolean(data?.data?.length),
    );
    yield put(getSearchedTracksAction.success(data));
  } catch (e) {
    yield put(getSearchedTracksAction.failure());
  }
}

export function* watchSearchFetchData() {
  yield debounce(DELAY_TIME, getMoodsAction.request, fetchMoods);
  yield debounce(DELAY_TIME, getArtistsAction.request, fetchArtists);
  yield debounce(DELAY_TIME, getGenresAction.request, fetchGenres);
  yield debounce(DELAY_TIME, getTagsAction.request, fetchTags);
  yield debounce(DELAY_TIME, getSearchedDataAction.request, fetchSearchedData);
  yield debounce(DELAY_TIME, getTemplatesAction.request, fetchSearchedTemplates);
  yield debounce(DELAY_TIME, getSearchedTracksAction.request, fetchSearchedTracks);
  yield debounce(DELAY_TIME, getSearchedTagsAction.request, fetchSearchedTags);
}
