import { createReducer } from 'deox';

import * as actions from './player.actions';
import { initialState, noTrack } from './player.initialState';
import { getCardName, getNextTrack } from './utils';

export const reducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.setTrack, (state, { payload }) => {
    const { trackList, track, source, activeCard } = payload;
    const index = trackList.length ? trackList.findIndex(({ id }) => track.id === id) : 0;

    return {
      ...state,
      activeTrack: track,
      trackList,
      trackIndex: index,
      source: source || state.source,
      activeCard: activeCard || state.activeCard,
    };
  }),

  handleAction(actions.setIndex, (state, { payload }) => {
    const activeTrack = state.trackList[payload.index] || noTrack;
    const activeCard = getCardName(state.activeCard, activeTrack);

    return { ...state, activeTrack, trackIndex: payload.index, activeCard };
  }),

  handleAction(actions.saveState, (state) => {
    const { activeTrack, trackList, source, trackIndex } = state;

    return {
      ...state,
      savedTrack: activeTrack,
      savedTrackList: trackList,
      savedSource: source,
      savedTrackIndex: trackIndex,
    };
  }),

  handleAction(actions.restoreState, (state) => {
    const { savedTrack, savedTrackList, savedSource, savedTrackIndex } = state;

    return {
      ...state,
      activeTrack: savedTrack,
      trackList: savedTrackList,
      source: savedSource,
      trackIndex: savedTrackIndex,
    };
  }),

  handleAction(actions.removeTrack, (state, { payload }) => {
    const { activeTrack, trackList, trackIndex } = state;
    const isInPlaylist = trackList.some((track) => track.id === payload.id);

    if (!isInPlaylist) {
      // removed track is not in the current playlist
      return state;
    }

    const newTrackList = trackList.filter((track) => track.id !== payload.id);
    const newIndex = newTrackList.findIndex((track) => track.id === activeTrack.id);

    if (newIndex === -1) {
      // current track is deleted
      const nextTrack = trackIndex !== null && getNextTrack(trackIndex, trackList);

      if (nextTrack) {
        // next track exists: set next track
        return { ...state, activeTrack: nextTrack, trackList: newTrackList, trackIndex: newIndex };
      }

      // no next track: set noTrack
      return {
        ...state,
        activeTrack: noTrack,
        trackList: [],
        activeCard: '',
        trackIndex: null,
      };
    }

    // non-active track from playlist is deleted
    return { ...state, trackList: newTrackList, trackIndex: newIndex };
  }),

  handleAction(actions.updateTrack, (state, { payload }) => {
    const updatedTrack = payload.track;
    const { activeTrack, trackList } = state;

    const isCurrentTrackUpdated = activeTrack.id === updatedTrack.id;
    const updatedTrackList = trackList.map((track) =>
      track.id === updatedTrack.id ? updatedTrack : track,
    );

    return {
      ...state,
      trackList: updatedTrackList,
      activeTrack: isCurrentTrackUpdated ? updatedTrack : activeTrack,
    };
  }),

  handleAction(actions.shuffle, (state, { payload }) => {
    const { shuffleSeed } = payload;

    return { ...state, shuffle: true, shuffleSeed };
  }),

  handleAction(actions.unshuffle, (state) => {
    return { ...state, shuffle: false, shuffleSeed: null };
  }),
]);
