import type { TBrowserFeature } from '../deriveBrowserVersion';

export const safariFeatures: TBrowserFeature[] = [
  {
    // https://caniuse.com/?search=Array.flat
    test: () => 'flat' in Array.prototype,
    minMobileVersion: '12',
    minDesktopVersion: '12',
  },
  {
    // https://caniuse.com/?search=IntersectionObserver
    // https://caniuse.com/?search=datalist
    // http://diveintohtml5.info/everything.html#datalist
    test: () => 'IntersectionObserver' in window && 'options' in document.createElement('datalist'),
    minMobileVersion: '12.2',
    minDesktopVersion: '12.1',
  },
  {
    // https://caniuse.com/?search=allSettled
    test: () => 'allSettled' in Promise,
    minMobileVersion: '13',
    minDesktopVersion: '13',
  },
  {
    // https://caniuse.com/?search=ResizeObserver
    test: () => 'ResizeObserver' in window,
    minMobileVersion: '13.4',
    minDesktopVersion: '13.1',
  },
  {
    // https://caniuse.com/?search=Intl.Locale
    test: () => 'Locale' in Intl,
    minMobileVersion: '14',
    minDesktopVersion: '14',
  },
  {
    // https://caniuse.com/?search=MediaRecorder
    // https://caniuse.com/?search=Intl.Segmenter
    test: () => 'MediaRecorder' in window && 'Segmenter' in Intl,
    minMobileVersion: '14.5',
    minDesktopVersion: '14.1',
  },
  {
    // https://caniuse.com/?search=MediaSession
    // https://caniuse.com/?search=WebGL2RenderingContext
    test: () => 'MediaSession' in window && 'WebGL2RenderingContext' in window,
    minMobileVersion: '15',
    minDesktopVersion: '15',
  },
];
