import axios, { type AxiosResponse, type AxiosRequestConfig } from 'axios';
import i18n from 'i18next';

import { type ProfileSection } from '@/services/types';

import { getCaptchaParams } from '@/utils/captchaUtils/captchaUtils';
import {
  remoteLogger,
  getPropsFromError,
  getDetailsFromAxiosError,
  getDetailsFromAxiosRequestConfig,
} from '@/utils/logging';
import { toastSuccess } from '@/utils/notificationUtils';
import { withRetry } from '@/utils/retry';

import type { TSoundCloudSignUpTokens } from '@/components/soundCloud/signUpAtoms';

import { type TRequest, type TRequestConfigured } from '@/types/common';
import type {
  TChangePasswordPayload,
  TSignUpPayload,
  TSignUpResponse,
  TInitPasswordResetResponse,
  TUserDetails,
  TValidatePasswordResetLinkResponse,
  TFinishPasswordResetPayload,
  TFinishReferralSignUpPayload,
  TUserData,
  TChangeUserPhotoPayload,
  TSocialLink,
  TUserNotification,
  TUserResponse,
  TUserConsentInfo,
} from '@/types/profile';

import { profileService, rakutenService } from '@/api/private';
import {
  type TUserPhotoResponseSuccess,
  type TPasswordResponseSuccess,
  type TSendReferralResponse,
  type TGetReferralsResponse,
  type TRakutenUserResponse,
} from '@/store/profile/types';
import { trackEvent, ActionEvents } from '@/tracking';

export const profileSilentFetcher = <T>(url: string) =>
  profileService.get(url).then((res: AxiosResponse<T>) => res.data);

const getUserInfoOriginal: TRequestConfigured<
  TUserResponse,
  ProfileSection[] | undefined,
  AxiosRequestConfig
> = (sections, config) => {
  const query = new URLSearchParams();

  if (sections && sections.length > 0) {
    query.set('sections', sections.join(','));
  }

  const queryString = query.toString();

  return profileService.get(queryString ? `?${queryString}` : '', config).then((response) => {
    // TODO: remove this after the backend is fixed
    if (response.data.details?.education) {
      response.data.details.displayPersonalizedTemplates = true;
    }

    return response;
  });
};

const USER_PROFILE = 'USER_PROFILE';

export const getUserInfo = withRetry(getUserInfoOriginal, {
  maxAttemptsCount: 5,
  baseDelay: 300,
  getRequestConfig: (attempt) => ({
    remoteLogProps: {
      category: USER_PROFILE,
      details: {
        attempt,
        hint: 'Failed to retry',
      },
    },
  }),
  onRetrySucceeded(attempt, result) {
    remoteLogger.info(`Retrying to get user info, attempt ${attempt} was successful!`, {
      category: USER_PROFILE,
      details: {
        request: getDetailsFromAxiosRequestConfig(result.config),
      },
    });
  },
});

const userProfileConfig = { remoteLogProps: { category: USER_PROFILE } };
const signupConfig = { remoteLogProps: { category: 'SIGNUP' } };
const referralConfig = { remoteLogProps: { category: 'PROFILE_REFERRAL' } };
const socialConfig = { remoteLogProps: { category: 'USER_SOCIAL' } };
const emailVerificationConfig = { remoteLogProps: { category: 'EMAIL_VERIFICATION' } };

export const updateUserInfo: TRequest<TSignUpResponse, Partial<TUserData>> = (data) =>
  profileService.patch('', data, userProfileConfig);

export const updateConsent: TRequest<TUserResponse, Partial<TUserConsentInfo>> = ({
  acceptTermsAndConditions,
  ...consent
}) => {
  const details = { termsAndConditionsAccepted: acceptTermsAndConditions };

  return profileService.patch('', { details, ...consent }, userProfileConfig);
};

export const updateNotificationPreferences = (notification: TUserNotification) =>
  profileService.patch('', { notification }, { toastError: true });

export const signUpUser: TRequest<TSignUpResponse, TSignUpPayload> = async ({
  captcha,
  ...payload
}) => {
  const params = await getCaptchaParams(captcha);

  const url =
    payload.registrationSource === 'SOUNDCLOUD'
      ? '/public/signup/soundcloud-signup'
      : '/public/signup';

  return profileService.post(url, payload, {
    params,
    ...signupConfig,
  });
};

export const initPasswordReset: TRequest<TInitPasswordResetResponse, string> = (email) =>
  profileService.post('/public/reset-password/init', { email }, { skipRemoteLog: true });

export const postRakutenUserInfo = async (payload: string): Promise<void> => {
  try {
    const body = { value: payload };

    await rakutenService.post('/details', body, {
      toastError: false,
      remoteLogProps: { category: 'RAKUTEN' },
    });
  } catch {
    // ignore
  }
};

type TRakutenEventName = 'USER_SIGNUP' | 'PREMIUM_SUBSCRIPTION';
type TRakutenEventProps<PEvent extends TRakutenEventName> = PEvent extends 'PREMIUM_SUBSCRIPTION'
  ? [productName: string]
  : [];

const getRakutenUserInfoUrl = <PEvent extends TRakutenEventName>(
  eventName: PEvent,
  ...props: TRakutenEventProps<PEvent>
) => {
  const searchParams = new URLSearchParams();

  searchParams.set('eventName', eventName);

  const productName = props[0];

  if (eventName === 'PREMIUM_SUBSCRIPTION' && productName) {
    searchParams.set('productName', productName);
  }

  return `/events/detail?${searchParams.toString()}`;
};

const executeGetRakutenUserInfo = async (
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<TRakutenUserResponse>> => {
  const response = await rakutenService.get<TRakutenUserResponse>(url, {
    toastError: false,
    remoteLogProps: { category: 'RAKUTEN' },
    ...config,
  });

  return response;
};

export const getRakutenUserInfo = async <PEvent extends TRakutenEventName>(
  eventName: PEvent,
  ...props: TRakutenEventProps<PEvent>
): Promise<TRakutenUserResponse | null> => {
  const url = getRakutenUserInfoUrl(eventName, ...props);

  try {
    const response = await executeGetRakutenUserInfo(url);

    return response.data;
  } catch {
    return null;
  }
};

export const getRakutenUserInfoWithRetry = async <PEvent extends TRakutenEventName>(
  eventName: PEvent,
  ...props: TRakutenEventProps<PEvent>
): Promise<TRakutenUserResponse | null> => {
  const url = getRakutenUserInfoUrl(eventName, ...props);

  const executeWithRetry = withRetry(executeGetRakutenUserInfo, {
    maxAttemptsCount: 10,
    getRequestConfig: (attempt) => ({
      toastError: false,
      remoteLogProps: {
        category: 'RAKUTEN',
        details: {
          attempt,
          hint: 'Failed to retry: get Rakuten user info',
        },
      },
    }),
    onRetrying(attempt, reason) {
      remoteLogger.warn(`Retrying to get Rakuten user info, attempt: ${attempt}`, {
        exception: reason instanceof Error ? getPropsFromError(reason) : reason,
        category: 'RAKUTEN',
        details:
          axios.isAxiosError(reason) && !axios.isCancel(reason)
            ? getDetailsFromAxiosError(reason)
            : undefined,
      });
    },
    onRetrySucceeded(attempt, result) {
      remoteLogger.info(`Retrying to get Rakuten user info, attempt ${attempt} was successful!`, {
        category: 'RAKUTEN',
        details: {
          request: getDetailsFromAxiosRequestConfig(result.config),
        },
      });
    },
    getDelay: (attempt, baseDelay) => baseDelay,
    baseDelay: 3000,
  });

  try {
    const response = await executeWithRetry(url);

    return response.data;
  } catch {
    return null;
  }
};

export const validatePasswordResetLink: TRequest<TValidatePasswordResetLinkResponse, string> = (
  encryptedPayload,
) => profileService.get(`/public/reset-password/${encryptedPayload}`, { skipRemoteLog: true });

export const finishPasswordReset: TRequest<
  TInitPasswordResetResponse,
  TFinishPasswordResetPayload
> = ({ encryptedPayload, body }) =>
  profileService.post(`/public/reset-password/finish/${encryptedPayload}`, body, {
    skipRemoteLog: true,
  });

export const changeUserPhotoService: TRequest<
  TUserPhotoResponseSuccess,
  TChangeUserPhotoPayload
> = ({ image }) => {
  return profileService.post('/image/avatar?imageType=avatar', image, {
    ...userProfileConfig,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const changePasswordService: TRequest<TPasswordResponseSuccess, TChangePasswordPayload> = (
  payload,
) => profileService.post('/change-password', payload, { skipRemoteLog: true });

export const getReferralEmails: TRequest<TGetReferralsResponse> = () =>
  profileService.get('/referral', referralConfig);

export const sendReferralEmail: TRequest<TSendReferralResponse, string> = (name, email) =>
  profileService.post('/referral', { name, email }, referralConfig);

export const sendEmailVerificationLink: TRequest<{ message: string }, Partial<TUserDetails>> = (
  data,
) => profileService.post('/resend-email-verification-link', data, emailVerificationConfig);

export const validateEmailVerificationLink: TRequest<string, string> = (data) =>
  profileService.get(`/public/confirm-email-verification-link/${data}`, emailVerificationConfig);

export const initReferralSignUp: TRequest<string, string> = (data) =>
  profileService.get(`/public/referral/${data}`, referralConfig);

export const finishReferralSignUp: TRequest<string, TFinishReferralSignUpPayload> = ({
  encryptedPayload,
  body,
}) => profileService.post(`/public/signup/${encryptedPayload}`, body, signupConfig);

export const addSocialLinks: TRequest<TSocialLink[], TSocialLink[]> = (data: TSocialLink[]) =>
  profileService.post('/social', data, socialConfig);

export const fetchSocialLinks: TRequest<TSocialLink[]> = () =>
  profileService.get('/social', socialConfig);

type TLinkSoundCloudBody = {
  soundcloudToken: string;
  soundcloudUserId: string;
  soundcloudRefreshToken: string;
};

export const linkSoundCloudAccount: TRequest<unknown, TSoundCloudSignUpTokens> = async (tokens) => {
  const body: TLinkSoundCloudBody = {
    soundcloudToken: tokens.sc_info.soundcloudToken,
    soundcloudUserId: tokens.sc_info.soundcloudUserId,
    soundcloudRefreshToken: tokens.sc_info.soundcloudRefreshToken,
  };

  const result = await profileService.post('/soundcloud/link', body, {
    remoteLogProps: { category: 'SOUNDCLOUD' },
    toastError: true,
  });

  toastSuccess(i18n.t('provideEmail.successMessage'));

  trackEvent(ActionEvents.linkAccountToSoundCloud);

  return result;
};
