import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import axios from 'axios';
import useSWR from 'swr';

import { swrOptions } from '@/config/requests';
import { isNonNullable } from '@/utils/functionalUtils';

const axiosInstance = axios.create({ baseURL: window.env.API_HOST });

const getMetaTagName = (element: Element) => {
  return element.getAttribute('property') || element.getAttribute('name') || '';
};

const fetcher = async (url: string) => {
  const result = await axiosInstance.get(url);
  const resultData = result.data;

  const domParser = new DOMParser();

  const doc = domParser.parseFromString(resultData, 'text/html');
  const metaTags = doc.querySelectorAll('meta');
  const metaTagsMap = new Map<string, string>();

  metaTags.forEach((element) => {
    metaTagsMap.set(getMetaTagName(element), element.getAttribute('content') || '');
  });

  return metaTagsMap;
};

// TODO: Decide whether it's worth to use this hook or not.
export const useSyncOpenGraphMetaFromServerTags = () => {
  const { pathname } = useLocation();

  const { data: metaTagsMap } = useSWR(
    `/profile-management/public/open-graph${pathname}`,
    fetcher,
    swrOptions,
  );

  const propertiesSetRef = useRef<Set<string> | null>(null);
  const defaultPropertiesMapRef = useRef<Map<string, string> | null>(null);

  useEffect(() => {
    if (!metaTagsMap) {
      return;
    }

    if (!propertiesSetRef.current) {
      propertiesSetRef.current = new Set();

      for (const key of metaTagsMap.keys()) {
        propertiesSetRef.current.add(key);
      }
    }

    if (!defaultPropertiesMapRef.current) {
      defaultPropertiesMapRef.current = new Map<string, string>();
    }

    const defaultPropertiesMap = defaultPropertiesMapRef.current;

    const head = document.querySelector('head');

    if (!head) {
      return;
    }

    const existingMetaTagsMap = new Map();

    head.querySelectorAll('meta').forEach((element) => {
      existingMetaTagsMap.set(getMetaTagName(element), element);
    });

    propertiesSetRef.current.forEach((key) => {
      const existingMetaTag = existingMetaTagsMap.get(key);
      const content = metaTagsMap.get(key) || defaultPropertiesMap.get(key) || '';

      if (!defaultPropertiesMap.has(key)) {
        defaultPropertiesMap.set(key, content);
      }

      if (existingMetaTag) {
        if (existingMetaTag.getAttribute('content') !== content) {
          existingMetaTag.setAttribute('content', content);
        }
      } else {
        const metaTag = document.createElement('meta');
        metaTag.setAttribute('property', key);
        metaTag.setAttribute('content', content);

        head.appendChild(metaTag);
      }
    });
  }, [metaTagsMap]);
};

export const useSyncOpenGraphMetaTags = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const url = `${window.location.origin}${pathname === '/' ? '' : pathname}`;

    ['og:url', 'twitter:url']
      .map((name) => document.head.querySelector(`meta[property="${name}"]`))
      .filter(isNonNullable)
      .forEach((element) => {
        element.setAttribute('content', url);
      });
  }, [pathname]);
};
