import { styled } from '@mui/material';

export const Svg = styled('svg')(
  ({ theme }) => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '56px',
    width: '56px',
    zIndex: 3000,

    '& > line': {
      strokeWidth: 5,
      strokeLinecap: 'round',
      stroke: theme.palette.primary.main,
      transformOrigin: 'center',
      transformBox: 'fill-box',

      '&:nth-child(1)': { animation: 'animate1 1.3s linear infinite' },
      '&:nth-child(2)': { animation: 'animate2 1.3s linear infinite' },
      '&:nth-child(3)': { animation: 'animate3 1.3s linear infinite' },
      '&:nth-child(4)': { animation: 'animate2 1.3s linear infinite' },
      '&:nth-child(5)': { animation: 'animate1 1.3s linear infinite' },
    },

    '@keyframes animate1': {
      '0%': {
        transform: 'scaleY(0.5)',
      },
      '25%': {
        transform: 'scaleY(0.5)',
      },
      '50%': {
        transform: 'scaleY(1)',
      },
      '75%': {
        transform: 'scaleY(1)',
      },
      '100%': {
        transform: 'scaleY(0.5)',
      },
    },

    '@keyframes animate2': {
      '0%': {
        transform: 'scaleY(0.6)',
      },
      '25%': {
        transform: 'scaleY(1)',
      },
      '50%': {
        transform: 'scaleY(1)',
      },
      '75%': {
        transform: 'scaleY(0.5)',
      },
      '100%': {
        transform: 'scaleY(0.6)',
      },
    },

    '@keyframes animate3': {
      '0%': {
        transform: 'scaleY(1)',
      },
      '25%': {
        transform: 'scaleY(1)',
      },
      '50%': {
        transform: 'scaleY(0.5)',
      },
      '75%': {
        transform: 'scaleY(0.5)',
      },
      '100%': {
        transform: 'scaleY(1)',
      },
    },
  }),
  { name: 'Loader' },
);

const autoPositionSx = {
  top: 'auto',
  left: 'auto',
  transform: 'none',
};

export const Loader = ({ autoPosition = false }) => (
  <Svg
    sx={autoPosition ? autoPositionSx : null}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    data-testid="loader"
  >
    <line x1="10" x2="10" y1="3" y2="34" />
    <line x1="20" x2="20" y1="3" y2="34" />
    <line x1="30" x2="30" y1="3" y2="34" />
    <line x1="40" x2="40" y1="3" y2="34" />
    <line x1="50" x2="50" y1="3" y2="34" />
  </Svg>
);
