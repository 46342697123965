import axios from 'axios';

import { identity } from '@/utils/functionalUtils';

import { authServer } from '../urls';

import { handleErrorInterceptor } from './interceptors';

export const authService = axios.create({
  baseURL: authServer,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  remoteLogProps: {
    category: 'AUTH',
  },
});

// transform body to the correct content type
authService.interceptors.response.use(identity, handleErrorInterceptor);
