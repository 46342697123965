import type { Dict } from '@/types/common';

export const loadSmartlook = () => {
  const key = window.env.SMARTLOOK_KEY;

  if (!key) {
    return;
  }

  if (!window.smartlook) {
    const smartlook: ISmartlook = function smartlook(...args) {
      smartlook.api.push(args);
    };
    smartlook.api = [];
    window.smartlook = smartlook;

    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.charset = 'utf-8';
    script.src = 'https://web-sdk.smartlook.com/recorder.js';
    document.head.appendChild(script);
  }

  window.smartlook('init', key, { region: 'eu' });

  // Override defaults for Smartlook recorder as requested by business:
  // We need to track user email and numbers (at least number of downloads left)
  window.smartlook('record', { emails: true, numbers: true });
};

export const identifySmartlook = (id: string, props?: Dict<string>) => {
  if (!window.smartlook) {
    return;
  }

  window.smartlook('identify', id, props);
};

export const resetSmartlookIdentity = () => {
  if (!window.smartlook) {
    return;
  }

  window.smartlook('anonymize');
};

declare global {
  interface Window {
    smartlook?: ISmartlook;
  }
}

interface ISmartlook {
  (...args: unknown[]): void;
  api: unknown[];
}
