import { type IFilters } from '@/shared/filters/types';

import { type Dict } from '@/types/common';
import { type ITracks } from '@/types/trackListView';

export const shouldFilter = (filters: IFilters) =>
  Object.values(filters).some((filter) => filter.length);

export const composeFilters = ({ filters }: { filters: IFilters }) => {
  const { contentType, genre, template, tag, bpm, key, creator } = filters;
  const criteria = [];

  if (filters.trackType) {
    criteria.push({ key: 'trackType', function: 'eq', value: filters.trackType });
  }

  if (tag && tag.length) {
    criteria.push({ key: 'tags.id', function: 'in', value: tag });
  }

  if (contentType.length) {
    criteria.push({ key: 'contentType', function: 'in', value: contentType });
  }

  if (genre.length) {
    if (filters.trackType) {
      criteria.push({ key: 'genre.rootId', function: 'in', value: genre });
    } else {
      criteria.push({ key: 'genre.id', function: 'in', value: genre });
    }
  }

  if (template.length) {
    criteria.push({ key: 'template.id', function: 'in', value: template });
  }

  if (bpm.length === 1) {
    criteria.push({ key: 'bpm', function: 'eq', value: bpm[0] });
  } else if (bpm.length === 2) {
    criteria.push({ key: 'bpm', function: 'gte', value: bpm[0] });
    criteria.push({ key: 'bpm', function: 'lte', value: bpm[1] });
  }

  if (key.length) {
    criteria.push({ key: 'key', function: 'in', value: key });
  }

  if (creator?.length) {
    criteria.push({ key: 'userId', function: 'in', value: creator });
  }

  return JSON.stringify(criteria);
};

export const mapSortBy = (sortBy: string) => {
  if (sortBy === 'genre') {
    return 'genre.name';
  }

  if (sortBy === 'template') {
    return 'template.name';
  }

  return sortBy;
};

export const globalSearchSortMap: Dict<string> = {
  genre: 'genre.name.keyword',
  template: 'template.name.keyword',
  key: 'key.keyword',
  name: 'name.keyword',
};

export const isTrackList = (tracks?: ITracks): tracks is ITracks =>
  tracks !== undefined &&
  (tracks as ITracks).data !== undefined &&
  (tracks as ITracks).pagination !== undefined;
