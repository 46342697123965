import { remoteLogger } from '@/utils/logging';

export const initRemoteLogger = () => {
  if (window.localStorage === null) {
    // Sometimes `localStorage` is not available
    remoteLogger.warn('localStorage is not available', {
      userAgent: navigator?.userAgent,
    });
  }

  const userId = window.localStorage?.getItem('userId');

  if (userId) {
    remoteLogger.identifyUser(userId);
  }
};
