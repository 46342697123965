import { type TReferral, type TUserDetails } from '@/types/profile';

import { initialAsyncState } from '../async/initialState';

import { type TLinkValidityData, type TProfileStore, type TSignInData } from './types';

export const initialUserState: TUserDetails = {
  customerId: '',
  userId: -1,
  fullName: '',
  displayName: '',
  email: '',
  userName: '',
  personalizedUrl: '',
  bio: '',
  imagePath: '',
  isEmailVerified: false,
  createdOn: '',
  allowChangePassword: false,
  education: {
    displayPersonalizedTemplates: false,
  },
  isRakuten: false,
  isSoundcloud: false,
};

export const initialState: TProfileStore = {
  user: initialAsyncState<TUserDetails>(initialUserState),
  signin: initialAsyncState<TSignInData>({}),
  referrals: initialAsyncState<TReferral[]>([]),
  addReferral: initialAsyncState<null>(null),
  passwordReset: {
    resetInit: initialAsyncState<string>(''),
    linkValidity: {
      ...initialAsyncState<TLinkValidityData>(null),
      isLoading: true,
    },
    resetFinish: initialAsyncState<string>(''),
  },
  emailVerification: initialAsyncState<null>(null),
  referralSignUp: {
    init: initialAsyncState<string>(''),
    finish: initialAsyncState<string>(''),
  },
};
