import type { TErrorDetails } from '@/utils/errors';

import type { Dict, Nullable } from '@/types/common';

export enum LogLevel {
  TRACE = 'TRACE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

export type TOptions = {
  stack?: boolean;
};

export interface IRemoteLoggerProps {
  trackUnhandledErrors: true;
  trackUnhandledRejections: true;
  ignoreUnhandledError?: (errorEvent: ErrorEvent) => boolean;
  ignoreUnhandledRejection?: (promiseRejectionEvent: PromiseRejectionEvent) => boolean;
}

export type TLogDetails = Dict<unknown>;

export interface ILogEvent extends TLogDetails {
  timestampMs: number;
  timestamp: string;
  level: LogLevel;
  userId: Nullable<string>;
  mpDistinctId: Nullable<string>;
  correlation_id: string;
  hostname: string;
  pathname: string;
  uiCommitHash?: string;
  minutesSinceOpen: number;
}

export type TErrorProps = {
  message: string;
  name: string;
  stack?: string;
  details?: Nullable<TErrorDetails>;
  innerError?: TErrorProps;
};

export interface IRemoteLogger {
  setDistinctId(id: string): void;
  identifyUser(userId: string): void;
  resetUser(): void;
  debug(message: string, data?: TLogDetails): void;
  info(message: string, data?: TLogDetails): void;
  warn(message: string, data?: TLogDetails): void;
  error(error: Error, data?: TLogDetails, message?: string): void;
}

export interface ILogTransport {
  push(event: ILogEvent): void;
}

export interface ILogTransform {
  transformBeforeSend?: (event: ILogEvent) => ILogEvent;
}
