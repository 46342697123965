import { CommonError } from '@/utils/errors';
import { handleError } from '@/utils/errorUtils';

import { MAX_RETRIES } from '@/components/versionUpdater/config';

import type { TAction, Nullable } from '@/types/common';

const fetchLatestVersion = async (): Promise<string> => {
  const response = await fetch('/hash', { cache: 'no-cache' });

  const latestVersion = await response.text();

  if (!latestVersion) {
    throw new CommonError('Failed to get latest version');
  }

  if (latestVersion.includes('<')) {
    throw new CommonError(
      `/hash responded with non-hash string. First line is ${latestVersion.split('\n')[0]}`,
      { response: latestVersion },
    );
  }

  return latestVersion;
};

const checkIfAppUpdated = async (onNewVersion: TAction): Promise<void> => {
  const latestVersion = await fetchLatestVersion();

  const currentVersion = window.hash;

  if (latestVersion !== currentVersion) {
    onNewVersion();
  }
};

export const startWatching = (pollingIntervalMinutes: number, onNewVersion: TAction): TAction => {
  let intervalId: Nullable<number> = null;

  const stopInterval = () => {
    if (intervalId) {
      window.clearInterval(intervalId);
    }
  };

  let failuresCount = 0;

  intervalId = window.setInterval(
    async () => {
      const onUpdateAvailable = () => {
        onNewVersion();
        stopInterval();
      };

      try {
        await checkIfAppUpdated(onUpdateAvailable);

        failuresCount = 0;
      } catch (e) {
        const createDetails = (hint?: string) => ({
          details: { failuresCount, hint },
          category: 'NEW_VERSION',
        });

        if (e instanceof TypeError) {
          // There's nothing we can do about network error here.
          // Once we understand this is not critical issue, we can lower log level.
          handleError(e, { remoteLog: true }, createDetails('Network error'));

          return;
        }

        failuresCount += 1;

        if (failuresCount >= MAX_RETRIES) {
          stopInterval();
        }

        handleError(e, { remoteLog: true }, createDetails());
      }
    },
    1000 * 60 * pollingIntervalMinutes,
  );

  return stopInterval;
};
