import { createReducer } from 'deox';

import type { TUserSubscriptionInfo } from '@/types/profile';

import { handleAsyncReducers } from '@/store/async/async.reducers';
import { getAsyncActionList } from '@/store/async/helpers';
import { setSubscriptionInfo } from '@/store/subscription/subscription.actions';

import { initialState } from './subscription.initialState';

export const reducer = createReducer(initialState, (handleAction) => [
  handleAction(getAsyncActionList(setSubscriptionInfo), (state, action) =>
    handleAsyncReducers<TUserSubscriptionInfo>(
      state,
      initialState.data,
      action,
      setSubscriptionInfo,
    ),
  ),
]);
