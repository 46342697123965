import type { ILogTransform } from '../types';

export const experimentalNetworkCondition: ILogTransform = {
  transformBeforeSend(event) {
    return {
      ...event,
      _expOnLine: navigator.onLine,
    };
  },
};
