import { createReducer } from 'deox';

import { type TReferral, type TUserDetails } from '@/types/profile';

import { handleAsyncReducers } from '../async/async.reducers';
import { getAsyncActionList } from '../async/helpers';

import {
  addReferralEmail,
  getReferralEmails,
  getUserData,
  sendReferralEmail,
  signin,
  signup,
  initPasswordResetAction,
  validatePasswordResetLink,
  passwordResetFinishAction,
  updateUserInfo,
  changeUserPhoto,
  changePassword,
  sendConfirmVerifyEmail,
  initReferralSignUp,
  finishReferralSignUp,
} from './profile.actions';
import { initialState } from './profile.initialState';
import { type TLinkValidityData } from './types';

export const reducer = createReducer(initialState, (handleAction) => [
  handleAction(getAsyncActionList(signin), (state, action) => ({
    ...state,
    signin: handleAsyncReducers(state.signin, {}, action, signin),
  })),

  handleAction(getAsyncActionList(signup), (state, action) => ({
    ...state,
    user: handleAsyncReducers(state.user, initialState.user.data, action, signup),
  })),

  handleAction(getAsyncActionList(getUserData), (state, action) => ({
    ...state,
    user: handleAsyncReducers(state.user, initialState.user.data, action, getUserData),
  })),

  handleAction(getAsyncActionList(updateUserInfo), (state, action) => ({
    ...state,
    user: handleAsyncReducers<TUserDetails>(
      state.user,
      initialState.user.data,
      action,
      updateUserInfo,
    ),
  })),

  handleAction(getAsyncActionList(getReferralEmails), (state, action) => ({
    ...state,
    referrals: handleAsyncReducers<TReferral[]>(
      state.referrals,
      initialState.referrals.data,
      action,
      getReferralEmails,
    ),
  })),

  handleAction(getAsyncActionList(sendReferralEmail), (state, action) => ({
    ...state,
    addReferral: handleAsyncReducers<null>(state.addReferral, null, action, sendReferralEmail),
  })),

  handleAction(addReferralEmail, (state, action) => ({
    ...state,
    referrals: {
      ...state.referrals,
      data: [...state.referrals.data, action.payload],
    },
  })),

  handleAction(getAsyncActionList(initPasswordResetAction), (state, action) => ({
    ...state,
    passwordReset: {
      ...state.passwordReset,
      resetInit: handleAsyncReducers<string>(
        state.passwordReset.resetInit,
        initialState.passwordReset.resetInit.data,
        action,
        initPasswordResetAction,
      ),
    },
  })),

  handleAction(getAsyncActionList(validatePasswordResetLink), (state, action) => ({
    ...state,
    passwordReset: {
      ...state.passwordReset,
      linkValidity: handleAsyncReducers<TLinkValidityData>(
        state.passwordReset.linkValidity,
        initialState.passwordReset.linkValidity.data,
        action,
        validatePasswordResetLink,
      ),
    },
  })),

  handleAction(getAsyncActionList(passwordResetFinishAction), (state, action) => ({
    ...state,
    passwordReset: {
      ...state.passwordReset,
      resetFinish: handleAsyncReducers<string>(
        state.passwordReset.resetFinish,
        initialState.passwordReset.resetFinish.data,
        action,
        passwordResetFinishAction,
      ),
    },
  })),

  handleAction(getAsyncActionList(changeUserPhoto), (state, action) => ({
    ...state,
    user: handleAsyncReducers<TUserDetails>(state.user, state.user.data, action, changeUserPhoto),
  })),

  handleAction(getAsyncActionList(changePassword), (state, action) => ({
    ...state,
    user: handleAsyncReducers<TUserDetails>(state.user, state.user.data, action, changePassword),
  })),

  handleAction(getAsyncActionList(sendConfirmVerifyEmail), (state, action) => {
    const isSuccess = action.type === sendConfirmVerifyEmail.SUCCESS;

    return {
      ...state,
      emailVerification: handleAsyncReducers<null>(
        state.emailVerification,
        null,
        action,
        sendConfirmVerifyEmail,
      ),
      user: {
        ...state.user,
        data: {
          ...state.user.data,
          isEmailVerified: isSuccess,
        },
      },
    };
  }),

  handleAction(getAsyncActionList(initReferralSignUp), (state, action) => ({
    ...state,
    referralSignUp: {
      ...state.referralSignUp,
      init: handleAsyncReducers<string>(
        state.referralSignUp.init,
        state.referralSignUp.init.data,
        action,
        initReferralSignUp,
      ),
    },
  })),

  handleAction(getAsyncActionList(finishReferralSignUp), (state, action) => ({
    ...state,
    referralSignUp: {
      ...state.referralSignUp,
      finish: handleAsyncReducers<string>(
        state.referralSignUp.finish,
        state.referralSignUp.finish.data,
        action,
        finishReferralSignUp,
      ),
    },
  })),
]);
