import { createActionCreator } from 'deox';

import { createAsyncActions } from '../async/async.actions';

export const getPaymentMethodsAction = createAsyncActions('get_payment_methods');
export const createPaymentMethodAction = createAsyncActions('create_payment_method');
export const deletePaymentMethodAction = createAsyncActions('delete_payment_method');

export const fetchPurchaseHistoryAction = createAsyncActions('fetch_purchase_history');
export const updateBundleSubscriptionAction = createAsyncActions('update_bundle_subscription');
export const cancelSubscriptionAction = createAsyncActions('cancel_subscription');
export const cancelFutureSubscriptionAction = createAsyncActions('cancel_future_subscription');

export const cleanPayment = createActionCreator('CLEAN_PAYMENT', (resolve) => () => resolve());
