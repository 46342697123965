import { v4 as uuidv4 } from 'uuid';

import type { Nullable } from '@/types/common';

import { getStackTrace } from './getStackTrace';
import {
  type IRemoteLogger,
  LogLevel,
  type IRemoteLoggerProps,
  type TLogDetails,
  type TOptions,
  type ILogTransport,
  type ILogEvent,
  type ILogTransform,
} from './types';
import { getPropsFromError } from './utils';

export class RemoteLogger implements IRemoteLogger {
  private distinctId: Nullable<string> = null;
  private currentUserId: Nullable<string> = null;

  constructor(
    private readonly transport: ILogTransport,
    {
      trackUnhandledErrors = true,
      trackUnhandledRejections = true,
      ignoreUnhandledError = () => false,
      ignoreUnhandledRejection = () => false,
    }: IRemoteLoggerProps,
    private readonly transforms: ILogTransform[] = [],
  ) {
    if (trackUnhandledErrors) {
      window.addEventListener('error', (errorEvent) => {
        if (ignoreUnhandledError(errorEvent)) {
          return;
        }

        this.track(
          LogLevel.WARN,
          {
            category: 'UNHANDLED_ERROR',
            exception: {
              message: errorEvent.message,
              lineno: errorEvent.lineno,
              colno: errorEvent.colno,
              stack: errorEvent.error?.stack || 'n/a',
            },
          },
          { stack: true },
        );
      });
    }

    if (trackUnhandledRejections) {
      window.addEventListener('unhandledrejection', (promiseRejectionEvent) => {
        if (ignoreUnhandledRejection(promiseRejectionEvent)) {
          return;
        }

        this.track(
          LogLevel.WARN,
          {
            category: 'UNHANDLED_REJECTION',
            reason:
              promiseRejectionEvent.reason instanceof Error
                ? getPropsFromError(promiseRejectionEvent.reason)
                : {
                    message: promiseRejectionEvent.reason?.message,
                  },
          },
          { stack: true },
        );
      });
    }
  }

  setDistinctId(id: string) {
    this.distinctId = id;
  }

  identifyUser(userId: string) {
    this.currentUserId = userId;
  }

  resetUser() {
    this.currentUserId = null;
  }

  debug(message: string, data?: TLogDetails) {
    this.track(LogLevel.DEBUG, { message, ...data }, { stack: true });
  }

  info(message: string, data?: TLogDetails) {
    this.track(LogLevel.INFO, { message, ...data }, { stack: true });
  }

  warn(message: string, data?: TLogDetails) {
    this.track(LogLevel.WARN, { message, ...data }, { stack: true });
  }

  error(error: Error, data?: TLogDetails) {
    this.track(LogLevel.ERROR, {
      exception: getPropsFromError(error),
      message: error.message,
      ...data,
    });
  }

  private track(level: LogLevel, data: TLogDetails, options: TOptions = {}) {
    const timestampMs = new Date();

    const event: ILogEvent = {
      correlation_id: uuidv4(),
      hostname: window.location.hostname,
      pathname: window.location.pathname,
      timestampMs: timestampMs.valueOf(),
      timestamp: timestampMs.toISOString(),
      minutesSinceOpen: Math.round(performance.now() / 60) / 1000,
      uiCommitHash: window.hash,
      userId: this.currentUserId,
      mpDistinctId: this.distinctId,
      level,
      ...data,
    };

    if (options.stack) {
      event.stack = getStackTrace();
    }

    const transformedEvent = this.transforms.reduce((memo, transform) => {
      try {
        return transform.transformBeforeSend?.(memo) ?? memo;
      } catch (error) {
        return memo;
      }
    }, event);

    this.transport.push(transformedEvent);
  }
}
