import { releaseStages } from './config';
import { type IEnvironmentsByStage, type TReleasesStage, type TEnvLabel } from './types';

const environmentsByStage: IEnvironmentsByStage = {
  development: ['local', 'ci'],
  test: ['local', 'ci', 'dev', 'qa'],
  uat: ['local', 'ci', 'dev', 'stage', 'qa'],
  production: ['local', 'ci', 'dev', 'stage', 'qa', 'prod'],
  off: [],
};

const inStage = (releaseStage: keyof IEnvironmentsByStage, getEnv: () => TEnvLabel) => {
  const currentEnv = getEnv();
  const allowedEnvironments = environmentsByStage[releaseStage];

  if (!allowedEnvironments) {
    return false;
  }

  return allowedEnvironments.includes(currentEnv);
};

/*
 Creates strongly typed `hasFeature` with provided features set and current `env` source
 */
export const createHasFeature = <TKeys extends string>(
  stages: TReleasesStage<TKeys>,
  getEnv: () => TEnvLabel,
) => {
  return (feature: TKeys) => {
    const releaseStage = stages[feature];

    return inStage(releaseStage, getEnv);
  };
};

/*
 Used to check whether Soundful features are enabled or disabled
 */
export const hasFeature = createHasFeature(releaseStages, () => window.env.env);
