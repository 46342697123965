import mixpanel from 'mixpanel-browser';
// eslint-disable-next-line no-duplicate-imports
import type { Dict, Config, Callback, RequestOptions } from 'mixpanel-browser';

import type { Nullable } from '@/types/common';

import { deriveSafariBrowserVersion, isSafari } from '@/compatibility/safari';

export type { Config, Callback, RequestOptions };

let additionalProps: Nullable<Record<string, string>> = null;

export const trackMixpanelEvent = (
  eventName: string,
  properties?: Dict,
  optionsOrCallback?: RequestOptions | Callback | undefined,
  callback?: Callback | undefined,
) => {
  mixpanel.track(eventName, { ...additionalProps, ...properties }, optionsOrCallback, callback);
};

export const initMixpanel = (token: string, config?: Partial<Config>) => {
  mixpanel.init(token, config);

  const safariVersions = isSafari ? deriveSafariBrowserVersion() : null;

  additionalProps = {
    'Derived iOS Safari Version': safariVersions?.mobile || '-',
    'Derived Safari Version': safariVersions?.desktop || '-',
  };
};

export const identifyMixpanelUser = (userId: string) => {
  mixpanel.identify(String(userId));
};

export const mixpanelPeopleSet = (props: Dict) => {
  mixpanel.people.set(props);
};

export const resetMixpanelIdentity = () => {
  mixpanel.reset();
};

export const getDistinctId = () => mixpanel.get_distinct_id();
