import type { TMediaOptimizationPixelFinalOptions } from './types';

const MOP_URL = 'https://track.linksynergy.com/eventnvppixel';

export const addRakutenMediaOptimizationPixel = (options: TMediaOptimizationPixelFinalOptions) => {
  const queryString = new URLSearchParams(options);

  const url = `${MOP_URL}?${queryString.toString()}`;

  const img = document.createElement('img');
  img.width = 1;
  img.height = 1;
  img.style.border = 'none';
  img.src = url;

  document.body.appendChild(img);
};
