import { createActionCreator } from 'deox';

import { type ITrack } from '@/types/trackListView';

import { type TPlayTrackPayload } from './types';

export const setTrack = createActionCreator(
  'SET_TRACK',
  (resolve) => (payload: TPlayTrackPayload) => resolve(payload),
);

export const setIndex = createActionCreator(
  'SET_INDEX',
  (resolve) => (payload: { index: number }) => resolve(payload),
);

export const saveState = createActionCreator('SAVE_STATE');

export const restoreState = createActionCreator('RESTORE_STATE');

export const removeTrack = createActionCreator(
  'REMOVE_TRACK',
  (resolve) => (payload: { id: string }) => resolve(payload),
);

export const updateTrack = createActionCreator(
  'UPDATE_TRACK',
  (resolve) => (payload: { track: ITrack }) => resolve(payload),
);

export const shuffle = createActionCreator(
  'SHUFFLE',
  (resolve) => (payload: { shuffleSeed: string }) => resolve(payload),
);

export const unshuffle = createActionCreator('UNSHUFFLE');
