import { LOAD_EVENT_TRACKING_SCRIPTS } from '@/config/marketing';
import { noop } from '@/utils/functionalUtils';

import {
  initMixpanel,
  identifyMixpanelUser,
  mixpanelPeopleSet,
  resetMixpanelIdentity,
  getDistinctId,
} from './providers/mixpanel';

export const initTracker = LOAD_EVENT_TRACKING_SCRIPTS ? initMixpanel : noop;
export const identifyTrackerUser = LOAD_EVENT_TRACKING_SCRIPTS ? identifyMixpanelUser : noop;
export const trackerPeopleSet = LOAD_EVENT_TRACKING_SCRIPTS ? mixpanelPeopleSet : noop;
export const resetTrackerUser = LOAD_EVENT_TRACKING_SCRIPTS ? resetMixpanelIdentity : noop;
export const getTrackerDistinctId = LOAD_EVENT_TRACKING_SCRIPTS ? getDistinctId : () => 'untracked';
