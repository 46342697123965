import type { AxiosInstance } from 'axios';

import { identity } from '@/utils/functionalUtils';
import {
  createSynchronizedRefreshAccessToken,
  tryRefreshAndPersistToken,
} from '@/utils/refreshTokenUtils';

import { gatewayInterceptor, createRefreshTokenInterceptor } from './interceptors';

export const createPrivateApiInterceptors = () => {
  const refreshToken = createSynchronizedRefreshAccessToken(tryRefreshAndPersistToken);

  return {
    apply(axiosInstance: AxiosInstance) {
      const refreshTokenInterceptor = createRefreshTokenInterceptor(axiosInstance, refreshToken);

      axiosInstance.interceptors.request.use(gatewayInterceptor);
      axiosInstance.interceptors.response.use(identity, refreshTokenInterceptor);
    },
  };
};
