import { getFutureDate } from './dateUtils';

interface ISetCookieProps {
  name: string;
  value: string;
  domain?: string;
  expires?: string;
  path?: string;
  secure?: boolean;
  samesite?: 'Strict' | 'Lax' | 'None';
}

const getBaseDomain = () => {
  const { hostname } = window.location;
  const domain = hostname.split('.').slice(-2).join('.');

  return domain;
};

export const setCookie = ({
  name,
  value,
  expires = getFutureDate(1, 'month'),
  path = '/',
  domain = getBaseDomain(),
  secure = true,
  samesite = 'Strict',
}: ISetCookieProps) => {
  document.cookie = `${name}=${value}; expires=${expires}; path=${path}; domain=${domain}; secure=${secure}; samesite=${samesite}`;
};

export const getCookie = (name: string) =>
  new RegExp(`(?:^|; )${name}=([^;]+)`).exec(document.cookie)?.[1] ?? null;
