import { createActionCreator } from 'deox';
import { type SnackbarKey } from 'notistack';

import type { TNotification } from '@/utils/notificationUtils';

export const createEnqueueSnackbarAction = createActionCreator(
  'ENQUEUE_SNACKBAR',
  (resolve) => (payload: TNotification) => resolve(payload),
);

export const createCloseSnackbarAction = createActionCreator(
  'CLOSE_SNACKBAR',
  (resolve) => (payload: { key: SnackbarKey }) => resolve(payload),
);

export const createRemoveSnackbarAction = createActionCreator(
  'REMOVE_SNACKBAR',
  (resolve) => (payload: { key: SnackbarKey }) => resolve(payload),
);
