import jwt_decode, { type JwtPayload } from 'jwt-decode';

import { refreshAccessToken } from '@/services/auth';

import { sync } from '@/hooks/synchronizer';

import {
  RECENT_REFRESHES_THRESHOLD_MS,
  MAX_SUCCESSFUL_REFRESHES_DURING_INTERVAL,
} from '@/config/refreshTokenRateLimiter';
import { setCookie } from '@/utils/cookieUtils';
import { getDiffInHours } from '@/utils/dateUtils';
import { setTokens } from '@/utils/tokenUtils';

import { type Nullable } from '@/types/common';

export const tryRefreshAndPersistToken = async (): Promise<Nullable<string>> => {
  try {
    const { data } = await refreshAccessToken();
    setTokens(data);
    setCookie({ name: 'logged', value: 'true' });

    return data.access_token;
  } catch (error) {
    sync('logout', {});

    return null;
  }
};

export const getValidAccessToken = async (token: string) => {
  const decodedToken: JwtPayload = jwt_decode(token);
  const tokenExpirationTime = (decodedToken.exp as number) * 1000;
  const diffInHours = getDiffInHours(tokenExpirationTime);

  if (diffInHours <= 1) {
    const result = await tryRefreshAndPersistToken();

    if (result) {
      return result;
    }
  }

  return token;
};

/*
 Creates a limiter which prevents endless loop in the next case.
 This is unlikely to happen, but it's better to log out in such a case:
 - Client makes a request
 - API responds with 401
 - Client refreshes access token
 - API responds with 200 but with invalid access token
 - Client retries failed request
 - API responds with 401
 - Client refreshes token
 etc.
 */
const createFaultyRefreshRateLimiter = () => {
  let lastSuccessfulRefreshes: number[] = [];
  const removeIrrelevantTimestamps = () => {
    const now = Date.now();
    const lowerEdge = now - RECENT_REFRESHES_THRESHOLD_MS;

    lastSuccessfulRefreshes = lastSuccessfulRefreshes.filter((timestamp) => timestamp > lowerEdge);
  };

  return {
    onSuccess: () => {
      removeIrrelevantTimestamps();
      lastSuccessfulRefreshes.push(Date.now());
    },
    onFail: () => {
      lastSuccessfulRefreshes = [];
    },
    isLimitReached: () => {
      removeIrrelevantTimestamps();

      return lastSuccessfulRefreshes.length + 1 >= MAX_SUCCESSFUL_REFRESHES_DURING_INTERVAL;
    },
  };
};

/**
 * Create synchronized refreshToken function.
 * If several simultaneous requests fail with 401 response,
 * then refresh token once and make other failed request to wait for it to complete before retrying.
 */
export const createSynchronizedRefreshAccessToken = (refresh: () => Promise<unknown>) => {
  let refreshPromise: Nullable<Promise<unknown>> = null;
  const rateLimiter = createFaultyRefreshRateLimiter();

  return async () => {
    if (!refreshPromise) {
      if (rateLimiter.isLimitReached()) {
        throw new Error('Max refreshes count is limited. Aborting');
      }

      refreshPromise = refresh()
        .then(rateLimiter.onSuccess)
        .catch(rateLimiter.onFail)
        .finally(() => {
          refreshPromise = null;
        });
    }

    await refreshPromise;
  };
};
