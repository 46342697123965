const VIRTUAL_WIDTH = 10;
const VISUAL_WIDTH = 4;
const SMALLER_MARGIN = 1;
const LARGER_MARGIN = VIRTUAL_WIDTH - VISUAL_WIDTH - SMALLER_MARGIN;
const TRAILING_MARGIN = 0;

const LARGER_RADIUS = 8;
const SMALLER_RADIUS = 3;

const scrollbarCommonStyles = {
  borderColor: 'transparent',
  borderStyle: 'solid',
  backgroundClip: 'content-box',
};

const combine = (...values: number[]) => {
  return values.map((v) => `${v}px`).join(' ');
};

const verticalStyles = {
  borderWidth: combine(TRAILING_MARGIN, SMALLER_MARGIN, TRAILING_MARGIN, LARGER_MARGIN),
  borderRadius: combine(LARGER_RADIUS, SMALLER_RADIUS, SMALLER_RADIUS, LARGER_RADIUS),
};

const horizontalStyles = {
  borderWidth: combine(LARGER_MARGIN, TRAILING_MARGIN, SMALLER_MARGIN, TRAILING_MARGIN),
  borderRadius: combine(LARGER_RADIUS, LARGER_RADIUS, SMALLER_RADIUS, SMALLER_RADIUS),
};

// noinspection JSSuspiciousNameCombination
export const scrollbarStylesOverrides = {
  '*::-webkit-scrollbar': {
    width: VIRTUAL_WIDTH,
    height: VIRTUAL_WIDTH,
    ...scrollbarCommonStyles,
  },

  '*::-webkit-scrollbar:vertical': { ...verticalStyles, ...scrollbarCommonStyles },
  '*::-webkit-scrollbar:horizontal': horizontalStyles,

  '*::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb:hover': {
    ...verticalStyles,
    ...scrollbarCommonStyles,
  },
  '*::-webkit-scrollbar-thumb:vertical': verticalStyles,
  '*::-webkit-scrollbar-thumb:horizontal': horizontalStyles,
};
