import { LOAD_SMARTLOOK_SCRIPTS } from '@/config/marketing';
import { setCookie } from '@/utils/cookieUtils';
import { remoteLogger } from '@/utils/logging';
import { setRecord } from '@/utils/storageUtils';
import { cleanTokens } from '@/utils/tokenUtils';

import { resetSmartlookIdentity } from '@/scripts/smartlook';
import { resetTrackerUser } from '@/tracking/initTracking';

type TGetUserEmail = () => string | undefined;

let getUserEmail: TGetUserEmail = () => undefined;

export const initializeLogout = (newGetUserEmail: TGetUserEmail) => {
  getUserEmail = newGetUserEmail;
};

export const logout = () => {
  cleanTokens();
  setCookie({ name: 'logged', value: 'false' });
  setCookie({ name: 'rakuten', value: '', expires: 'Thu, 01 Jan 1970 00:00:01 GMT' });

  // TODO: create generic onLogout/onLogin event emitters
  if (LOAD_SMARTLOOK_SCRIPTS) {
    resetSmartlookIdentity();
  }
  remoteLogger.resetUser();
  resetTrackerUser();

  const email = getUserEmail();

  if (email) {
    setRecord('justLoggedOutEmail', email);
  }

  window.location.reload();
};
